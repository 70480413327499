import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { FaRegEdit } from 'react-icons/fa';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@material-ui/core/Button';
import CommonDialog from './CommonDialog';

// Firebase v9 imports
import { getFirestore, doc, setDoc } from 'firebase/firestore';

const styles = {
  // Cards
  card: {
    width: '90%',
    'margin-left': 'auto',
    'margin-right': 'auto',
    'margin-top': '10px',
    'margin-bottom':'10px',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    'text-align': 'center',
    'white-space': 'nowrap',
    '-webkit-text-overflow': 'ellipsis',
    '-o-text-overflow': 'ellipsis',
  },
  empty: {
    height: 0,
    margin: 0,
    padding: 0,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 12,
  },
  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  editicon: {
    width: '20px',
    height: '20px',
    margin: '0 auto',
  },
  iconrapper: {
    display: 'block',
    width: '100%',
  }
};

function Item(props) {
  const { editmode, empty, title, imgUrl, price, href, classes, uid, itemid, getMylist, deletable, status } = props;
  const [commDlg, setCommDlg] = useState(false);
  
  // Firestore instance
  const db = getFirestore();
  
  const delMydata = async () => {
    try {
      // Create a reference to the document
      const itemRef = doc(db, 'users', uid, 'items', itemid);
      
      // Update the document
      await setDoc(itemRef, { status: "delete" }, { merge: true });
      
      // Wait a bit then call getMylist and close dialog
      setTimeout(() => {
        getMylist();
        setCommDlg(false);
      }, 1000);
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const handleOnclick = () => {
    console.log("commDlg:", commDlg);
    setCommDlg(true);
  };

  const exchangeUrl = (s1) => {
    if (s1) {
      if (s1.indexOf("firebasestorage.googleapis.com") == -1) {
        return s1.replace(/https:\/\/(\w+)/g, "https://qrparking.jp/api/$1");
      }
      return s1;
    }
    return null;
  };

  if (empty === "true") {
    return (
      <Card className={classes.empty}>
      </Card>
    );
  } else {
    return (
      <Card className={classes.card}>
        <CommonDialog
          msg={"削除しますか？"}
          isOpen={commDlg}
          doYes={delMydata}
          doNo={() => {setCommDlg(false)}}
        />
        <CardContent>
          <a href={href} target="_blank">
            <Typography variant="headline" className={classes.title}>
              {title}
            </Typography>
          </a>
        </CardContent>
        <CardActions>
          <div className={classes.row}>
            {deletable ? 
              <p className={classes.iconrapper}>
                <Button className={classes.editicon} onClick={() => { handleOnclick(); }}>
                  <DeleteIcon onClick={() => { handleOnclick(); }} className={classes.editicon} />
                </Button>
              </p> 
            : <></>} 
            <Typography component="p" className={classes.iconrapper}>
              {price}
            </Typography>
            {status ? 
              <>
                <a href={href} target="_blank">
                  <Typography component="p" className={classes.iconrapper}>
                    {status}
                  </Typography>
                </a>
              </> 
            : <></>
            }
          </div>
        </CardActions>
      </Card>
    );
  }
}

Item.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Item);