import React from "react";
import { auth } from '../Firebase';
import { User } from 'firebase/auth';

import StripeConnectButton from "../components/StripeConnectButton";

import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import Button from "@material-ui/core/Button";
import { Alert } from "@material-ui/lab";

import { FiLogOut } from "react-icons/fi";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      margin: 10,
    },
    textContent: {
      // 新しいスタイル
      textAlign: "left",
      flex: 1,
      display: "inline-block", // テキストコンテンツをインラインブロックにします
      //width: "calc(100% - 135px)", // 画像の幅とマージンを差し引いた幅
      width: "90%",
      verticalAlign: "top", // テキストを上揃えにします
    },
    textLeft: {
      textAlign: "left",
      flex: 1,
    },
    textCenter: {
      textAlign: "center",
      flex: 1,
    },
    img100: {
      width: "100%",
    },
    imgCenter: {
      // 新規追加
      width: "90%",
      //margin: "15px", // テキストとの間隔
    },
    customTypography: {
      fontSize: "1.25rem", // ここで望むフォントサイズに調整します。h4とh5の中間のサイズに設定
      fontWeight: "bold", // ここで文字を太くします
    },
    paragraph: {
      marginTop: 10,
      marginBottom: 10,
    },
    clearBoth: {
      clear: "both",
    },
  });

interface TroubleshootingProps extends WithStyles<typeof styles> {}

interface TroubleshootingState {
  error: string;
  warning: string;
  info: string;
  success: string;
  urlcopy: boolean;
  user?: User | null;
}

class Troubleshooting extends React.Component<
  TroubleshootingProps,
  TroubleshootingState
> {
  constructor(props: TroubleshootingProps) {
    super(props);
    this.state = {
      error: "",
      warning: "",
      info: "",
      success: "",
      urlcopy: false,
    };
  }

  componentDidMount = () => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          user: user,
        });
        console.log("log in");
      } else {
        console.log("not log in");
      }
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={`${classes.root} ${classes.textLeft}`}>
        <Typography
          variant="h3"
          className={`${classes.textContent} ${classes.customTypography}`}
        >
          トラブル対策
        </Typography>
        <br />
        {this.state.error && <Alert severity="error">{this.state.error}</Alert>}
        {this.state.warning && (
          <Alert severity="warning">{this.state.warning}</Alert>
        )}
        {this.state.info && <Alert severity="info">{this.state.info}</Alert>}
        {this.state.success && (
          <Alert severity="success">{this.state.success}</Alert>
        )}

        <Paper className={`${classes.root} ${classes.textLeft}`} elevation={1}>
          <Typography
            variant="h3"
            className={`${classes.textContent} ${classes.customTypography}`}
          >
            料金未払い:
          </Typography>
          <Typography
            className={`${classes.root} ${classes.textLeft}`}
            variant="body1"
            component="p"
          >
            料金を未払で駐車場を利用されるケースは機械式のコインパーキングよりも増えてきます。
            <br />
            以下に有効な対策を示します。
            <br />
            <br />
            ・監視カメラを設置します
            <br />
            　　ダミーの監視カメラでもある程度効果があります
            <br />
            　　太陽光発電付きで電源不要の１万円程度の監視カメラもあります
            <br />
            <br />
            ・料金未払に対する警告の看板を設置する
            <br />
            　　ナンバーから所有者に料金を請求することができます
            <br />
            　　太陽光発電で看板に明かりをつけることができます
            <br />
            <br />
            ・警告チラシを貼る
            <br />
            　　QRパーキング名で作成した警告チラシは通販コーナーで購入できます
            <br />
            　　なお、警告チラシはワイパーに挟むなど、車体に傷をつけない方法で行うことが大切です
            <br />
            <br />
            ・警告ハガキを送る（所有者開示請求が必要）
            <br />
            　　QRパーキング名で作成した警告ハガキは通販コーナーで購入できます
            <br />
            <br />
            ・内容証明を送付する（所有者開示請求が必要）
            <br />
            　　
            <a href="https://www.post.japanpost.jp/service/enaiyo/download.html">
              内容証明郵便の書式はここからダウンロードできます
            </a>
            <br />
            　　５０００円の手数料でQRパーキング名で内容証明を送付することができます。
            <br />
            <br />
            ・少額訴訟手続きを行う（所有者開示請求が必要）
            <br />
            　　わずかな印紙代で本人が低額訴訟手続きを行うことができます。
            <br />
            <a href="https://www.courts.go.jp/saiban/syurui/syurui_minzi/minzi_04_02_02/index.html">
            　　詳しくはこちらを参照の上、簡易裁判所でお尋ねください。
            </a>
          </Typography>
          <br />
            <br />
          <Typography
            variant="h3"
            className={`${classes.textContent} ${classes.customTypography}`}
          >
            あまりに頻繁に発生して機械式のコインパーキングを設置してもコストをペイできるならば、機械式のコインパーキングの導入を考えてもいいでしょう。
          </Typography>
        </Paper>
        <Paper className={`${classes.root} ${classes.textLeft}`} elevation={1}>
          <Typography
            variant="h3"
            className={`${classes.textContent} ${classes.customTypography}`}
          >
            開示請求手続き:
          </Typography>
          <Typography
            className={`${classes.root} ${classes.textLeft}`}
            variant="body1"
            component="p"
          >
            開示請求を行うのにクルマのナンバーが写った写真と駐車場全体でクルマが違法に駐車された状況がわかる写真が必要です。
            <br />
            顧客が登録したクルマの写真だけでは足りない場合があるので違法車両を発見したら写真を撮ってください。
            <br />
            地域の陸運局で「登録証明取得申請手続き」を行ってください。
            <br />
            <Typography
              variant="h4"
              className={`${classes.textContent} ${classes.customTypography}`}
            >
              必要書類
            </Typography>
            <br />
            ・自動車登録番号申立書 第３号様式
            <br />
            　　無断駐車している車のナンバープレート番号
            <br />
            　　無断駐車されている住所
            <br />
            　　無断駐車されている期間
            <br />
            ・私有地放置車両関係位置図
            <br />
            　　無断駐車がされている敷地の簡単な図面
            <br />
            ・私有地放置車両写真
            <br />
            　　無断駐車の状況がわかる写真
            <br />
            　　無断駐車のナンバーが確認できる写真
            <br />
            ・手数料納付書
            <br />
            　　300円分の自動車検査登録印紙
            <br />
            ・申請人の身分証明書
            <br />
            　　運転免許証
            <br />
            　　マイナンバーカード
            <br />
            　　健康保険証
            <br />
            　　在留カード
            <br />
            　　特別永住者証明書
            <br />
            <br />
            用紙は陸運局にありますがダウンロードも可能です。
            <br />
            はじめての場合は陸運局で聞きながら書いたほうが簡単です。
            <br />
            <a href="https://www.mlit.go.jp/common/001381478.pdf">
              ・自動車登録番号申立書 第３号様式
            </a>
            <br />
            <br />
            <a href="https://wwwtb.mlit.go.jp/chubu/seibi/tourokusyoumei.pdf">
              ・私有地放置車両関係位置図/私有地放置車両写真
            </a>
            <br />
            <br />
            <a href="https://wwwtb.mlit.go.jp/kanto/content/000109126.pdf">
              ・手数料納付書
            </a>
          </Typography>
          <br />
          <Typography
            variant="h3"
            className={`${classes.textContent} ${classes.customTypography}`}
          >
          軽自動車は陸運局で登録をしていません。
          </Typography>
          <Typography
            className={`${classes.root} ${classes.textLeft}`}
            variant="body1"
            component="p"
          >
            軽自動車検査協会が管理しています。
            <br />
            軽自動車検査協会は、原則として所有者情報を開示していません。しかし、長期間の車両放置で警察等が対応してくれない場合にだけ、土地所有者の救済措置として照会に応じることがあるようです。
            <br />
            陸運局のような決まった手続がありませんので、写真やメモなどの証拠を揃えてから軽自動車検査協会に問い合わせてみてください。
          </Typography>
          <br />
          <br />
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Troubleshooting);
