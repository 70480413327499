import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

// Firebase imports
import { doc, getDoc } from "firebase/firestore";
import { db } from "../Firebase"; // Make sure you have this file with Firebase configuration

// スタイル
const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: 10,
  },
  titleImage: {
    width: "100%",
    maxWidth: 700,
  },
  textLeft: {
    textAlign: "left",
  },
  paragraph: {
    marginTop: 10,
    marginBottom: 10,
  },
  section: {
    marginTop: 20,
    marginBottom: 20,
  },
  subsection: {
    marginTop: 15,
    marginBottom: 15,
    paddingLeft: 20,
  },
  listItem: {
    marginTop: 5,
    marginBottom: 5,
    paddingLeft: 40,
  },
  sectionTitle: {
    fontWeight: "bold",
    marginTop: 25,
    marginBottom: 15,
  },
  subsectionTitle: {
    fontWeight: "bold",
    marginTop: 15,
    marginBottom: 10,
  },
});

class ParkingTermsOfUse extends React.Component {
  state = {
    companyName: "鵜飼不動産株式会社QRパーキング事業部", // デフォルト値
    isLoading: true,
  };

  componentDidMount() {
    // URL から userId と itemId を取得
    const { match } = this.props;
    if (match && match.params && match.params.userId && match.params.itemId) {
      this.fetchData(match.params.userId, match.params.itemId);
    } else {
      this.setState({ isLoading: false });
    }
  }

  fetchData = async (userId, itemId) => {
    try {
      // アンバサダー情報を取得
      const ambassadorRef = doc(db, "users", userId);
      const ambassadorDoc = await getDoc(ambassadorRef);

      // アイテム情報を取得
      const itemRef = doc(db, "users", userId, "items", itemId);
      const itemDoc = await getDoc(itemRef);

      let companyName = this.state.companyName; // デフォルト値を使用

      if (ambassadorDoc.exists() && itemDoc.exists()) {
        const ambassadorData = ambassadorDoc.data();
        const itemData = itemDoc.data();

        // ambassador.displayName と pageModule.title を取得
        const ambassadorDisplayName =
          ambassadorData.ambassadordisplayName || "";
        const pageTitle = itemData.pageModule?.title || "";

        // 会社名を構成
        if (ambassadorDisplayName || pageTitle) {
          companyName = ambassadorDisplayName
            ? pageTitle
              ? `${ambassadorDisplayName} ${pageTitle}`
              : ambassadorDisplayName
            : pageTitle;
        }
      }

      this.setState({
        companyName,
        isLoading: false,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      this.setState({ isLoading: false });
    }
  };

  render() {
    // Material-ui関連
    const { classes } = this.props;
    const { companyName, isLoading } = this.state;

    // ローディング中の場合はローディングインジケータを表示
    if (isLoading) {
      return (
        <div>
          <Paper className={classes.root} elevation={1}>
            <Typography component="p">読み込み中...</Typography>
          </Paper>
        </div>
      );
    }

    return (
      <div>
        <img
          src="/images/QRparkingLOGOIMG.jpg"
          alt="QRパーキングロゴ"
          className={classes.titleImage}
        />

        <Paper className={classes.root} elevation={2}>
          <Typography variant="h4" component="h1" align="center" gutterBottom>
            駐車場利用規約
          </Typography>

          <div className={classes.textLeft}>
            <Typography variant="h5" className={classes.sectionTitle}>
              第1章 総則
            </Typography>

            <div className={classes.subsection}>
              <Typography variant="h6" className={classes.subsectionTitle}>
                第1条（目的）
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                本規約は、{companyName}
                （以下「管理者」という。）が管理運営する駐車場の利用に関する事項を定めるものである。
              </Typography>
            </div>

            <div className={classes.subsection}>
              <Typography variant="h6" className={classes.subsectionTitle}>
                第2条（契約の成立）
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                1.
                駐車場の利用者（以下「利用者」という。）は、本規約を承認のうえ、駐車場を利用するものとし、本規約は、本駐車場施設への進入から退出までの間、利用者に適用されるものとする。
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                2.
                駐車場は、駐車スペース（車室）を有償で提供することを目的とするもので、車両を預かるためのものではない。
              </Typography>
            </div>

            <div className={classes.subsection}>
              <Typography variant="h6" className={classes.subsectionTitle}>
                第3条（駐車場の利用形態）
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                1. 駐車場の利用形態は、次の3種類とする。
              </Typography>
              <div className={classes.listItem}>
                <Typography component="p" className={classes.paragraph}>
                  (1) 時間貸し利用：1時間単位で駐車場を利用する形態
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  (2) 日貸し利用：24時間単位で駐車場を利用する形態
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  (3) 月極利用：1ヶ月単位で駐車場を利用する形態
                </Typography>
              </div>
              <Typography component="p" className={classes.paragraph}>
                2.
                利用者は、契約締結時に、所定の場所に必要事項を記入し、以下の情報を管理者に提出するものとする。
              </Typography>
              <div className={classes.listItem}>
                <Typography component="p" className={classes.paragraph}>
                  (1) 利用者の駐車する位置に車両とナンバープレートとが映った写真
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  (2) 利用者のメールアドレス
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  (3) 利用者の電話番号
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  (4)
                  決済に必要なカード名義、カード番号、有効期限、セキュリティコード
                </Typography>
              </div>
            </div>

            <div className={classes.subsection}>
              <Typography variant="h6" className={classes.subsectionTitle}>
                第4条（利用料金）
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                1.
                時間貸し利用の場合、利用者は、駐車場に掲出した料金額および料金体系により、駐車時間に応じた駐車料金を前もって支払うものとする。
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                2. 駐車時間は、駐車スペースへの入庫から出庫までの時間とする。
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                3.
                すべての駐車料金は前払いとし、駐車場内に備え付けのQRコードに示される決済システムにより支払うものとする。このとき、精算手順に従った精算行為を完了まで行うこと。
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                4.
                利用完了時には必ず所定の出庫処理を行うこと。出庫処理を行うまで利用料金の超過料金が発生することを了承する。
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                5.
                前払い料金分の時間を経過した場合は、指定の料金で精算すること。超過料金は通常の利用料を基に計算し割増料金や追徴金は課されない。
              </Typography>
            </div>

            <div className={classes.subsection}>
              <Typography variant="h6" className={classes.subsectionTitle}>
                第5条（利用時間）
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                1.
                駐車場の1回の利用は、前払い分を超えて駐車できないものとする。ただし、出庫時に超過料金の精算を行えば超過利用の許諾は解除される。
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                2.
                出庫処理をしない場合、前払いで定めた時間内において複数回利用できるものとする。
              </Typography>
            </div>

            <Typography variant="h5" className={classes.sectionTitle}>
              第2章 利用条件
            </Typography>

            <div className={classes.subsection}>
              <Typography variant="h6" className={classes.subsectionTitle}>
                第6条（駐車できない車両）
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                本駐車場は、以下の車両は、駐車できないものとする。ただし、個々の駐車場において駐車可能車両の使用につき、特別の指定がある場合にはその指定に従うものとする。
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                1. 車両の大きさによる制限
              </Typography>
              <div className={classes.listItem}>
                <Typography component="p" className={classes.paragraph}>
                  - 車両全長：5.0mを超える車両
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  - 車両全幅：1.9mを超える車両
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  - 最高車両高：2.1mを超える車両
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  - 最高積載重量：2.0tを超える車両
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  -
                  最低地上高：15cm以下の車両（ただし特に最高車両高を制限する場合、掲出した制限数値に従うこととする）
                </Typography>
              </div>
              <Typography component="p" className={classes.paragraph}>
                2. 法令違反等による車両の制限
              </Typography>
              <div className={classes.listItem}>
                <Typography component="p" className={classes.paragraph}>
                  -
                  無登録車、車検切れ車等、一般道路を走行することが禁じられている車両
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  -
                  自動車登録番号に覆いがされ、またはとり外されている車両等、登録番号自動認識装置による読み取りが困難な車両
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  -
                  自動車登録番号の変更があるにもかかわらず変更登録手続きが済んでいない車両
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  - 仮登録中である車両等の車体の特定が困難な車両
                </Typography>
              </div>
              <Typography component="p" className={classes.paragraph}>
                3. 他車加害のおそれのある車両の制限
              </Typography>
              <div className={classes.listItem}>
                <Typography component="p" className={classes.paragraph}>
                  -
                  付着物があり、接触により駐車場施設もしくは機械または他の自動車の損傷を発生させるおそれがある車両
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  -
                  大型特殊、建設用特殊等の特殊な用途の車両等で、駐車場施設または機械に損傷を発生させるおそれがある車両
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  -
                  危険物、有害高圧物質その他安全もしくは衛生を害するおそれがある物または悪臭発生もしくは汚染流出の原因となる物を積載した車両
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  - 他車両との接触、積載物の落下のおそれがあるキャリア搭載車両
                </Typography>
              </div>
              <Typography component="p" className={classes.paragraph}>
                4. 二輪車・三輪車等の制限
              </Typography>
              <div className={classes.listItem}>
                <Typography component="p" className={classes.paragraph}>
                  -
                  自動二輪車、原付自転車、足踏み自転車、小型特殊自動車、サイドカー、三輪車、バギー、トライク等と呼称される車両
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  -
                  ただし、駐車場に特に駐車することができる旨の掲示がされている場合は、掲示された条件に従い駐車することができるものとする
                </Typography>
              </div>
            </div>

            <div className={classes.subsection}>
              <Typography variant="h6" className={classes.subsectionTitle}>
                第7条（駐車場内の通行）
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                利用者は、駐車場内の車両通行に関しては、次の事項を守らねばならないものとする。
              </Typography>
              <div className={classes.listItem}>
                <Typography component="p" className={classes.paragraph}>
                  1.
                  場内は、時速8キロメートル以下で走行し、歩行者等の安全を確保すること
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  2. 追い越しをしないこと
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  3. 出庫する車両の通行を優先すること
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  4. 警笛をみだりに使用することなく静かに運転すること
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  5. 警備員の指示がある場合、その指示に従うこと
                </Typography>
              </div>
            </div>

            <div className={classes.subsection}>
              <Typography variant="h6" className={classes.subsectionTitle}>
                第8条（遵守事項・禁止事項）
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                利用者は、本駐車場の利用に関しては、次の事項を守らなければならないものとする。
              </Typography>
              <div className={classes.listItem}>
                <Typography component="p" className={classes.paragraph}>
                  1.
                  車室番号を確認のうえ、出庫前に料金の精算を行うこと（時間貸し利用の場合）
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  2.
                  車両を出庫する際は、短時間の利用により課金されない場合、又は、割引サービス等を行っている駐車場であっても、必ず精算機において精算行為を行なうこと（時間貸し利用の場合）
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  3.
                  車両内に貴重品をはじめとする遺留品については残置せず、身の回りに所持すること
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  4.
                  駐車中はエンジンを必ず停止し、車両から離れる場合は鍵を閉め扉及びトランクを施錠すること
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  5.
                  指定された駐車スペースに駐車し、それ以外の場所に駐車しないこと
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  6. 駐車中の車内に幼児を独居させないこと
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  7. 駐車中の車両に動物を放置しないこと
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  8. 駐車場内は喫煙及び、火気の使用は厳禁であること
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  9. 爆発性のもの可燃性のものは持入を禁止すること
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  10.
                  大音響でのカーステレオ、乱暴なドアの開閉、夜間の大きな話し声等、近隣の迷惑になる行為を禁止すること
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  11.
                  醜悪になるよう唾め、ビン、缶及び紙屑、ボロ切れ、吸殻、ゴミ、粗大ゴミ等の一切を捨てることを禁止すること
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  12.
                  場内での車両の駐車以外の行為（営業・宣伝・募金・署名活動等）を禁止すること
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  13.
                  非衛生的なものを積載したり、取り付けているとき、または悪臭を出したり、こぼすおそれがあるときの入場を禁止すること
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  14.
                  飲酒・喫煙・宴会・洗車等他人の迷惑になるような行為は禁止すること
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  15.
                  場内に駐車車両以外の可燃物（油脂類等）、車両用品（タイヤ、バッテリー、車両部品、洗車用具等）、ごみ、家庭用品、家電製品、商売用品、建設用資機材等を持ち込み、放置しないこと
                </Typography>
              </div>
            </div>

            <div className={classes.subsection}>
              <Typography variant="h6" className={classes.subsectionTitle}>
                第8条の2（暴力団等の排除）
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                1.
                次の各号に該当する者は、本駐車場を利用することができないものとする。
              </Typography>
              <div className={classes.listItem}>
                <Typography component="p" className={classes.paragraph}>
                  (1)
                  暴力団員による不当な行為の防止等に関する法律第2条第2号に規定する暴力団
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  (2)
                  暴力団員による不当な行為の防止等に関する法律第2条第6号に規定する暴力団員
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  (3) 暴力団員でなくなった日から5年を経過しない者
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  (4)
                  暴力団準構成員または暴力団関係企業・団体およびこれらに準ずる者
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  (5) 総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  (6) その他前各号に準ずる者
                </Typography>
              </div>
              <Typography component="p" className={classes.paragraph}>
                2.
                利用者は、自らまたは第三者を利用して次の各号に該当する行為を行わないことを確約するものとする。
              </Typography>
              <div className={classes.listItem}>
                <Typography component="p" className={classes.paragraph}>
                  (1) 暴力的な要求行為
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  (2) 法的な責任を超えた不当な要求行為
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  (3) 取引に関して、脅迫的な言動をし、または暴力を用いる行為
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  (4)
                  風説を流布し、偽計を用いまたは威力を用いて管理者の信用を毀損し、または管理者の業務を妨害する行為
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  (5) その他前各号に準ずる行為
                </Typography>
              </div>
              <Typography component="p" className={classes.paragraph}>
                3.
                利用者が前二項のいずれかに該当し、または該当すると管理者が判断した場合、管理者は催告することなく直ちに利用契約を解除し、利用者に対して駐車場からの退去を求めることができる。この場合において、利用者は支払済みの駐車料金の返還を求めることができない。
              </Typography>
            </div>

            <div className={classes.subsection}>
              <Typography variant="h6" className={classes.subsectionTitle}>
                第9条（月極利用の特約事項）
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                1.
                月極利用の契約期間は、前払い料金の場合はその期間、サブスクリプション契約の場合はサブスクリプションをキャンセルしてからの残存期間までとする。
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                2.
                利用者は、契約において記載した車両の駐車目的以外に駐車場を利用してはならない。また、利用者が契約において記載した車両を変更しようとする場合は、一旦出庫処理を行い新たに入庫手続きを行うこと。
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                3.
                駐車場使用契約書に記載された住所、連絡先等変更が生じた場合は、速やかに管理者に届けなければならない。
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                4.
                利用者は、他人に駐車場使用契約書の譲渡又は駐車場車室の転貸をしてはならない。ただし、利用者本人の死亡等の場合（相続など）は、利用者の家族に限り契約書の譲渡ができる。この場合において、譲渡された者は速やかに利用者名義の変更の届出をおこなわなければならない。
              </Typography>
            </div>

            <Typography variant="h5" className={classes.sectionTitle}>
              第3章 免責・利用者賠償責任
            </Typography>

            <div className={classes.subsection}>
              <Typography variant="h6" className={classes.subsectionTitle}>
                第10条（免責事由）
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                管理者は以下の事由による当駐車場内における車両またはその積載物の滅失、毀損または損害については原則として責任を負いません。
              </Typography>
              <div className={classes.listItem}>
                <Typography component="p" className={classes.paragraph}>
                  1.
                  管理者は当駐車場の利用者が、駐車場の他の利用者もしくはその他の人の行為または駐車場内に存在する車両またはその付着物もしくは積載物に起因して蒙った損害について原則として責任を負いません。
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  2. 精算時、利用者の未確認に基づく料金間違いによる返金等。
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  3. 入庫・出庫した場合の車両損傷等による利用者の損害。
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  4.
                  車両とその積載物もしくは取付物及び車内の遺留品についての盗品による利用者の損害（自動車盗、部品盗）
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  5.
                  エアロパーツを装着した車両で入場したうえ、エアロパーツが駐車場内の設備に接触等したことによる利用者の損害。
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  6. その他第6条の規定に違反した車両を駐車したことに伴う損害。
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  7. 第7条に違反して走行したことによる損害。
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  8. その他利用者の自己過失による損害。
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  9.
                  台風・水害・地震・火災・落雷等の天災地変による自然災害その他不可抗力による損害。
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  10.
                  無断車両及び他の車両等に、入庫及び出庫を妨げられたことによる待機時間・機会損失等により利用者が蒙った損害及びその他の損害。
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  11.
                  利用者間のトラブルや第三者から受けたトラブルにおける利用者の損害。
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  12.
                  本規約の第14条（営業休止等の措置）、又は本規約の第15条（駐車位置の変更等）による措置による利用者の損害。
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  13.
                  管理者の責めによらない事由による出庫不能により利用者が蒙った損害及びその他の損害。
                </Typography>
              </div>
            </div>

            <div className={classes.subsection}>
              <Typography variant="h6" className={classes.subsectionTitle}>
                第11条（利用者の賠償責任）
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                1.
                当駐車場の利用者が本規約もしくは駐車場内に掲出された規定に違反した場合又は故意もしくは重大な過失により駐車場の設備もしくは機械を破損した場合は、それにより管理者が蒙った損害（その結果駐車場の全部又は一部を休業しなければならない場合は、それにより喪失した営業利益を含む。）に対し利用者は賠償するものとする。
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                2.
                不正行為、または利用方法、利用規約に違反した場合、管理者は車両のチェーン施錠、駐車位置の変更（ロッカー移動）等、必要な処置を講ずることができるものとし、駐車場利用者（所有者及び同乗者を含む）は、賠償金として
              </Typography>
              <div className={classes.listItem}>
                <Typography component="p" className={classes.paragraph}>
                  (1) 正規駐車料金
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  (2)
                  実損経費（チェーン施錠、ロッカー移動費用、車両調査費用、機械点検費用等）
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  (3) 違約金50,000円を管理者に支払わねばならないものとする。
                </Typography>
              </div>
              <Typography component="p" className={classes.paragraph}>
                3.
                駐車場利用者（所有者及び同乗者を含む）は、当駐車場施設ならびに駐車中の他の車両や駐車場利用者等に損害を与えたときは、直ちに相手側にこの損害を支払わねばならず、申告及び支払いを履行しなかった場合は、所轄の警察署へ届けることとする。
              </Typography>
            </div>

            <div className={classes.subsection}>
              <Typography variant="h6" className={classes.subsectionTitle}>
                第12条（不正駐車）
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                1.
                当駐車場の利用者が、駐車料金を支払わないで不正の手段により車両を駐車スペースから入出庫し、または駐車場外へ移動したときは、管理者はその利用者に対し、駐車料金のほか前条に規制する損害金等を申し受ける場合がある。
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                2. 以下の駐車は不正と判断することとする。
              </Typography>
              <div className={classes.listItem}>
                <Typography component="p" className={classes.paragraph}>
                  (1) 駐車枠以外に駐車する行為
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  (2) 車室をまたがる駐車
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  (3)
                  管理者にてカラーコーン及びテープまたはロープ等にて封鎖している車室に許可なく進入もしくは駐車している場合
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  (4) 第6条の規定により駐車できない車両の駐車
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  (5) その他料金を精算せずに出庫する行為
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  (6) 契約なき車両の駐車（月極利用の場合）
                </Typography>
              </div>
            </div>

            <div className={classes.subsection}>
              <Typography variant="h6" className={classes.subsectionTitle}>
                第13条（月極利用の支払遅滞措置）
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                1.
                管理者は、駐車料金滞納者に対して、その都度、料金納付の督促を行うが、その額が3ヶ月の期間を超過した場合は、次の措置を講ずることができる。
              </Typography>
              <div className={classes.listItem}>
                <Typography component="p" className={classes.paragraph}>
                  (1) 駐車場契約の解除
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  (2)
                  利用者の同意なく契約車両の差押え、保管、移動の手段を講ずること
                </Typography>
              </div>
              <Typography component="p" className={classes.paragraph}>
                2.
                管理者は、上記の措置を講ずる前に、利用者に対し、書面又は駐車場内の掲示により、通知するものとする。
              </Typography>
            </div>

            <Typography variant="h5" className={classes.sectionTitle}>
              第4章 駐車場の運営管理
            </Typography>

            <div className={classes.subsection}>
              <Typography variant="h6" className={classes.subsectionTitle}>
                第14条（営業休止等の措置）
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                管理者は、次の場合には駐車場の全部又は一部について、営業休止、駐車場の隔壁、工事の通行止め及び車両の退避（以下「営業休止等」という。）を行なうことができるものとする。
              </Typography>
              <div className={classes.listItem}>
                <Typography component="p" className={classes.paragraph}>
                  1.
                  自然災害、火災、爆発施設又は物の損壊、その他これらに起因する事故が発生し又は発生するおそれがあると認められる場合
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  2. 工事竣功又は消毒を行う必要があると認められる場合
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  3. その他、保安上営業の継続が適当でないと認められる場合
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  4.
                  管理者の変更または施設管理者（関係行政機関等）より駐車場の閉鎖もしくは返還等の指示があった場合
                </Typography>
              </div>
            </div>

            <div className={classes.subsection}>
              <Typography variant="h6" className={classes.subsectionTitle}>
                第15条（駐車位置の変更等）
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                1.
                管理者は、駐車場の管理上必要があるときは、出入り口の一部又は駐車スペースの一部を閉鎖することができる。
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                2.
                時間貸し利用において駐車時間が48時間を超えた場合は、管理者は利用者（所有者を含む）への引取りを要請することができるものとする。利用者はこれに直ちに応じなければならない。
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                3.
                管理者は駐車場の利用と安全を確保する為、その車両に対しチェーン施錠、駐車位置変更（ロッカー移動）等必要な措置を講ずることができるものとする。
              </Typography>
            </div>

            <div className={classes.subsection}>
              <Typography variant="h6" className={classes.subsectionTitle}>
                第16条（事故等に対する措置）
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                管理者は、駐車場について事故が発生し、又は発生するおそれがあるときは、車両の移動その他必要な措置を講ずることができるものとする。
              </Typography>
            </div>

            <div className={classes.subsection}>
              <Typography variant="h6" className={classes.subsectionTitle}>
                第17条（入庫拒否）
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                管理者は、駐車場が満車である場合は入場を停止するほか、本規約第7条及び第8条に定められた遵守事項、または禁止行為等のおそれのある車両には駐車を断り、又は車両を退去させることができるものとする。
              </Typography>
            </div>

            <Typography variant="h5" className={classes.sectionTitle}>
              第5章 引取りのない車両の措置
            </Typography>

            <div className={classes.subsection}>
              <Typography variant="h6" className={classes.subsectionTitle}>
                第18条（放置車両）
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                1.
                時間貸し利用者があらかじめ管理者への届出を行なうことなく7日間を超えて車両を駐車している場合、管理者はこれらの利用者に対する通知または駐車場における掲示の方法により、管理者が指定する日までに当該車両を引取ることを請求することができるものとする。
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                2.
                前項の場合において、利用者が車両の引取りを拒み、若しくは引取ることができないとき又は管理者の過失なくして利用者を確知することができないときは、管理者は車両の所有者等（自動車検査証に記載された所有者及び使用者をいう。以下同じ）に対して通知または駐車場における掲示の方法により、管理者が指定する日までに車両を引取ることを請求することができるものとする。
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                3.
                この場合、利用者は当該車両の引渡し時に一切の権利を放棄したものとみなし、管理者に対して車両の引渡請求または、その他各自の訴権を問わず何らの異議を申し立てないものとする。
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                4.
                前2項の請求を書面により行なう場合は、管理者が指定する日までに車両の引き取りがなされないときは、管理者が指定する日の経過をもって車両の所有権を放棄したものとみなす旨を付記することができるものとする。
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                5.
                管理者は、第1項の規定により指定した日を経過した後は、車両について生じた損害については、管理者の故意又は重大な過失によるものを除き、賠償の責を負わないものとする。
              </Typography>
            </div>

            <div className={classes.subsection}>
              <Typography variant="h6" className={classes.subsectionTitle}>
                第19条（車両の調査）
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                管理者は、第18条第1項の規定において、利用者又は所有者等を確知するために必要な限度において、施錠の解除を、車両（車内を含む。）を調査することができる。
              </Typography>
            </div>

            <div className={classes.subsection}>
              <Typography variant="h6" className={classes.subsectionTitle}>
                第20条（車両の移動）
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                管理者は、第18条第1項の場合において管理上支障があるときは、その旨を利用者もしくは所有者等に通知し又は駐車場において掲示して、車両を他の場所に移動することができる。
              </Typography>
            </div>

            <div className={classes.subsection}>
              <Typography variant="h6" className={classes.subsectionTitle}>
                第21条（車両の処分）
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                1.
                管理者は、利用者及び所有者等が車両を引取ることを拒み、もしくは引き取ることができず、又は管理者の過失なくして利用者及び所有者等を確知することができない場合であって、利用者に対して通知又は駐車場における掲示の方法により期間を定めて車両の引取の催告をしたにもかかわらず、その期間内に引取りがなされないときは、催告を
                した日から2週間を経過した後、利用者に通知又は駐車場において掲示して予告した上で、公正な第三者を立ち会わせて車両の売却、廃棄その他の処分をすることができるものとする。この場合において、車両の時価が売却に要する費用（催告後の車両の保管に要する費用を含む。）に満たないことが明らかである場合は、利用者に通知又は駐車場において掲示して予告した上で、引取の期限後直ちに公正な第三者を立ち会わせて車両の売却、廃棄その他の処分をすることができる。
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                2.
                管理者は、前項の規定により車両を処分した場合は、処分によって生じる収入から、駐車料金並びに車両の保管、移動及び処分のために要した費用があればこれを控除し、なお不足があるときは利用者に対してその支払いを請求し、残額があるときはこれを利用者に返還するものとする。
              </Typography>
            </div>

            <Typography variant="h5" className={classes.sectionTitle}>
              第6章 雑則
            </Typography>

            <div className={classes.subsection}>
              <Typography variant="h6" className={classes.subsectionTitle}>
                第22条（個人情報の取得）
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                本駐車場の利用にあたって、利用者から提供された個人情報については、法令等に従い適正に管理するものとする。
              </Typography>
            </div>

            <div className={classes.subsection}>
              <Typography variant="h6" className={classes.subsectionTitle}>
                第23条（その他重要事項）
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                1. 管理者は、車両に警告書等の文書を貼り付ける場合がある。
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                2.
                ビデオ・カメラ等により駐車場内及びその周辺を撮影している場合があり、管理者は任意にこれを不正駐車の取り締まりに使用し、または防犯・捜査等のための当局に提出する場合があり、利用者はこれを承諾するものとする。
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                3.
                何が一駐車場料金を未払いで出庫、または場内にて未精算で車室の移動等をされた場合、民法、刑法その他の法律の規定に基づく一切の損害の賠償・刑罰の責任を負う。
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                4. 上記の他は、全て管理者の掲示に従うものとする。
              </Typography>
            </div>

            <div className={classes.subsection}>
              <Typography variant="h6" className={classes.subsectionTitle}>
                第24条（規約の変更）
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                1.
                管理者は、この規約の施行状況について検討を加え、必要があると認めるときは、その結果に基づいて、本規約を改訂することができる。この場合、管理者は、1ヶ月以上の周知期間を設け改訂事項を利用者に通知し、又は駐車場内に掲示するものとする。改訂事項は、改訂日から適用するものとし、遡及することはないものとする。
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                2.
                この規約に定めのない事項については、関係法律の規定にしたがって処理する。
              </Typography>
            </div>

            <div className={classes.subsection}>
              <Typography variant="h6" className={classes.subsectionTitle}>
                第25条（施行日）
              </Typography>
              <Typography component="p" className={classes.paragraph}>
                この規約は令和７年１月１日より施行する。
              </Typography>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

// Material-ui関連
ParkingTermsOfUse.propTypes = {
  classes: PropTypes.object.isRequired,
};

// Material-uiのテーマ設定
export default withStyles(styles)(ParkingTermsOfUse);
