import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// モジュラー形式のFirebase AuthとFirestoreの関数をインポート
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  onSnapshot,
  doc,
  setDoc,
  getDoc,
  getDocs,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../Firebase"; // Firebase初期化済みのFirestoreインスタンス
import { getFunctions, httpsCallable } from "firebase/functions";

// コンポーネントの準備
import OrderItem from "../components/OrderItem";

// スタイル
const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    textAlign: "center",
    align: "center",
    width: "90%",
  },
  textcenter: {
    width: "100%",
    align: "center",
    "white-space": "nowrap",
    overflow: "hidden",
    "text-overflow": "ellipsis",
    "-webkit-text-overflow": "ellipsis",
    "-o-text-overflow": "ellipsis",
    textAlign: "center",
    "text-align": "center",
  },
  accordionSummary: {
    height: "15px",
    "min-height": "15px",
    padding: "0",
    margin: "0",
  },
  accordionDetails: {
    padding: "0",
    margin: "0",
    display: "flex",
    "flex-wrap": "wrap",
  },
  expanded: {
    "min-height": "15px",
    padding: "0",
    margin: "0",
  },
  tabRoot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginBottom: "20px",
  },
  tabLabel: {
    fontSize: "0.8rem",
  },
  orderSection: {
    marginTop: "20px",
  },
  loadMoreButton: {
    margin: "20px auto",
    display: "block",
  }
});

// Tabパネルコンポーネント
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

class OrderList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      OrderList: [],
      SubscriptionList: [],
      HistoryList: [],
      FailedOrdersList: [], // 追加
      getting: false,
      loadingSubscriptions: false,
      loadingHistory: false,
      loadingFailedOrders: false, // 追加
      error: "",
      tabValue: 0,
      accordionExpanded: false,
      hasMore: true,
      hasMoreSubscriptions: true,
      hasMoreHistory: true,
      lastVisible: null,
      lastVisibleSubscription: null,
      lastVisibleHistory: null,
    };
  }

  // python.get()
  get = (dic, key) => {
    if (dic && key in dic) return dic[key];
    return null;
  };

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue }, () => {
      // Load appropriate data based on selected tab
      if (newValue === 0 && this.state.OrderList.length === 0) {
        this.getMylist();
      } else if (newValue === 1 && this.state.SubscriptionList.length === 0) {
        this.getSubscriptionList();
      } else if (newValue === 2 && this.state.HistoryList.length === 0) {
        this.getHistoryList();
      } else if (newValue === 3 && this.state.FailedOrdersList.length === 0) {
        this.getFailedOrders();
      }
    });
  };

  itemChange = null;
  subscriptionChange = null;
  historyChange = null;

  getMylist = () => {
    // userdata が未定義または uid が未定義の場合はエラーを設定して処理を中止
    if (!this.props.userdata || !this.props.userdata.uid) {
      this.setState({
        error: "ユーザーデータが読み込めません。再ログインしてください。",
        getting: false
      });
      return;
    }
    
    this.setState({ getting: true });
    
    let q;
    if (this.state.lastVisible) {
      q = query(
        collection(db, "order"),
        where("ambassadorid", "==", this.props.userdata.uid),
        where("status", "==", "prepayment_completed"),
        orderBy("timestamp", "desc"),
        startAfter(this.state.lastVisible),
        limit(10)
      );
    } else {
      q = query(
        collection(db, "order"),
        where("ambassadorid", "==", this.props.userdata.uid),
        where("status", "==", "prepayment_completed"),
        orderBy("timestamp", "desc"),
        limit(10)
      );
    }

    if (this.itemChange) {
      this.itemChange();
    }

    this.itemChange = onSnapshot(q, (querySnapshot) => {
      let bufOrderList = [];
      querySnapshot.forEach((doc) => {
        const docdat = doc.data();
        docdat.id = doc.id; // ドキュメントIDを追加
        bufOrderList.push(docdat);
      });

      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1] || null;

      this.setState((prevState) => ({
        error: "",
        OrderList: this.state.lastVisible
          ? [...prevState.OrderList, ...bufOrderList]
          : bufOrderList,
        getting: false,
        hasMore: querySnapshot.docs.length === 10, // 10件取得できた場合、まだデータがある可能性がある
        lastVisible: lastVisible,
      }));
    }, (error) => {
      console.error("Error getting active orders:", error);
      this.setState({
        error: error.message,
        getting: false
      });
    });
  };

  getSubscriptionList = () => {
    // userdata が未定義または uid が未定義の場合はエラーを設定して処理を中止
    if (!this.props.userdata || !this.props.userdata.uid) {
      this.setState({
        error: "ユーザーデータが読み込めません。再ログインしてください。",
        loadingSubscriptions: false
      });
      return;
    }
    
    this.setState({ loadingSubscriptions: true });
    
    let q;
    if (this.state.lastVisibleSubscription) {
      q = query(
        collection(db, "order"),
        where("ambassadorid", "==", this.props.userdata.uid),
        where("isSubscription", "==", true),
        orderBy("timestamp", "desc"),
        startAfter(this.state.lastVisibleSubscription),
        limit(10)
      );
    } else {
      q = query(
        collection(db, "order"),
        where("ambassadorid", "==", this.props.userdata.uid),
        where("isSubscription", "==", true),
        orderBy("timestamp", "desc"),
        limit(10)
      );
    }

    if (this.subscriptionChange) {
      this.subscriptionChange();
    }

    this.subscriptionChange = onSnapshot(q, (querySnapshot) => {
      let bufSubsList = [];
      querySnapshot.forEach((doc) => {
        const docdat = doc.data();
        docdat.id = doc.id;
        bufSubsList.push(docdat);
      });

      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1] || null;

      this.setState((prevState) => ({
        SubscriptionList: this.state.lastVisibleSubscription
          ? [...prevState.SubscriptionList, ...bufSubsList]
          : bufSubsList,
        loadingSubscriptions: false,
        hasMoreSubscriptions: querySnapshot.docs.length === 10,
        lastVisibleSubscription: lastVisible,
      }));
    }, (error) => {
      console.error("Error getting subscriptions:", error);
      this.setState({
        error: error.message,
        loadingSubscriptions: false
      });
    });
  };

  getHistoryList = () => {
    // userdata が未定義または uid が未定義の場合はエラーを設定して処理を中止
    if (!this.props.userdata || !this.props.userdata.uid) {
      this.setState({
        error: "ユーザーデータが読み込めません。再ログインしてください。",
        loadingHistory: false
      });
      return;
    }
    
    this.setState({ loadingHistory: true });
    
    let q;
    if (this.state.lastVisibleHistory) {
      q = query(
        collection(db, "order"),
        where("ambassadorid", "==", this.props.userdata.uid),
        where("status", "in", ["exit_completed", "exit_terminated", "subscription_canceled"]),
        orderBy("checkoutTime", "desc"),
        startAfter(this.state.lastVisibleHistory),
        limit(10)
      );
    } else {
      q = query(
        collection(db, "order"),
        where("ambassadorid", "==", this.props.userdata.uid),
        where("status", "in", ["exit_completed", "exit_terminated", "subscription_canceled"]),
        orderBy("checkoutTime", "desc"),
        limit(10)
      );
    }

    if (this.historyChange) {
      this.historyChange();
    }

    this.historyChange = onSnapshot(q, (querySnapshot) => {
      let bufHistoryList = [];
      querySnapshot.forEach((doc) => {
        const docdat = doc.data();
        docdat.id = doc.id;
        bufHistoryList.push(docdat);
      });

      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1] || null;

      this.setState((prevState) => ({
        HistoryList: this.state.lastVisibleHistory
          ? [...prevState.HistoryList, ...bufHistoryList]
          : bufHistoryList,
        loadingHistory: false,
        hasMoreHistory: querySnapshot.docs.length === 10,
        lastVisibleHistory: lastVisible,
      }));
    }, (error) => {
      console.error("Error getting history:", error);
      this.setState({
        error: error.message,
        loadingHistory: false
      });
    });
  };

  // 失敗した/中断されたオーダーを取得する関数
  getFailedOrders = () => {
    // userdata が未定義または uid が未定義の場合はエラーを設定して処理を中止
    if (!this.props.userdata || !this.props.userdata.uid) {
      this.setState({
        error: "ユーザーデータが読み込めません。再ログインしてください。",
        loadingFailedOrders: false
      });
      return;
    }
    
    this.setState({ loadingFailedOrders: true });
    
    // 完了していない通常注文クエリ
    const nonCompletedOrdersQuery = query(
      collection(db, "order"),
      where("ambassadorid", "==", this.props.userdata.uid),
      where("status", "not-in", ["exit_completed", "exit_terminated", "subscription_canceled"]),
      where("isSubscription", "==", false),
      orderBy("timestamp", "desc")
    );

    // 失敗したサブスクリプション注文クエリ
    const failedSubscriptionsQuery = query(
      collection(db, "order"),
      where("ambassadorid", "==", this.props.userdata.uid),
      where("status", "in", ["subscription_payment_failed", "prepayment_intent"]),
      where("isSubscription", "==", true),
      orderBy("timestamp", "desc")
    );

    // 2つのクエリを順番に実行して結果を結合
    let bufFailedOrdersList = [];

    // 非サブスクリプションの中断オーダー
    getDocs(nonCompletedOrdersQuery)
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const docdat = doc.data();
          docdat.id = doc.id;
          // ここで現在進行中の注文（prepayment_completedなど）を除外
          if (docdat.status !== "prepayment_completed" && 
              docdat.status !== "subscription_active") {
            bufFailedOrdersList.push(docdat);
          }
        });
        
        // 次に失敗したサブスクリプションを取得
        return getDocs(failedSubscriptionsQuery);
      })
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const docdat = doc.data();
          docdat.id = doc.id;
          bufFailedOrdersList.push(docdat);
        });
        
        // 日付で並び替え
        bufFailedOrdersList.sort((a, b) => {
          const dateA = a.timestamp.toDate();
          const dateB = b.timestamp.toDate();
          return dateB - dateA; // 降順
        });
        
        // 状態を更新
        this.setState({
          FailedOrdersList: bufFailedOrdersList,
          loadingFailedOrders: false
        });
      })
      .catch((error) => {
        console.error("Error getting failed orders:", error);
        this.setState({
          error: error.message,
          loadingFailedOrders: false
        });
      });
  };

  // 次のページを取得するメソッド
  loadMoreOrders = () => {
    if (this.state.hasMore && !this.state.getting) {
      this.getMylist();
    }
  };

  loadMoreSubscriptions = () => {
    if (this.state.hasMoreSubscriptions && !this.state.loadingSubscriptions) {
      this.getSubscriptionList();
    }
  };

  loadMoreHistory = () => {
    if (this.state.hasMoreHistory && !this.state.loadingHistory) {
      this.getHistoryList();
    }
  };

  // 強制出庫処理
  handleForceCheckout = async (orderId) => {
    try {
      const orderRef = doc(db, "order", orderId);
      const now = new Date();
      
      await setDoc(orderRef, {
        status: "exit_completed",
        checkoutTime: serverTimestamp(),
        autoCheckout: true,
        updatedAt: serverTimestamp()
      }, { merge: true });
      
      console.log("Order forced checkout: ", orderId);
      
      // リストを更新
      if (this.state.tabValue === 0) {
        this.setState({ OrderList: this.state.OrderList.filter(order => order.id !== orderId) });
      } else if (this.state.tabValue === 1) {
        this.setState({ SubscriptionList: this.state.SubscriptionList.filter(order => order.id !== orderId) });
      }
      
      return true;
    } catch (error) {
      console.error("Error forcing checkout: ", error);
      return false;
    }
  };

  // サブスクリプション解約処理
  handleCancelSubscription = async (orderId, subscriptionId) => {
    try {
      // Firebase Functionsを呼び出してStripeのサブスクリプションを解約
      const functions = getFunctions();
      const cancelSubscription = httpsCallable(functions, 'cancelSubscription');
      
      const result = await cancelSubscription({
        subscriptionId: subscriptionId,
        orderId: orderId
      });
      
      console.log("Subscription cancellation requested: ", result);
      
      // 状態を更新
      const updatedList = this.state.SubscriptionList.map(item => {
        if (item.id === orderId) {
          return {
            ...item,
            cancelRequested: true,
            cancellationStatus: "will_cancel_at_period_end"
          };
        }
        return item;
      });
      
      this.setState({ SubscriptionList: updatedList });
      
      return result.data.success;
    } catch (error) {
      console.error("Error canceling subscription: ", error);
      return false;
    }
  };

  // サブスクリプション解約キャンセル処理
  handleReactivateSubscription = async (orderId, subscriptionId) => {
    try {
      // Firebase Functionsを呼び出してサブスクリプション解約をキャンセル
      const functions = getFunctions();
      const reactivateSubscription = httpsCallable(functions, 'reactivateSubscription');
      
      const result = await reactivateSubscription({
        subscriptionId: subscriptionId,
        orderId: orderId
      });
      
      console.log("Subscription reactivation requested: ", result);
      
      if (result.data.success) {
        // 状態を更新
        const updatedList = this.state.SubscriptionList.map(item => {
          if (item.id === orderId) {
            return {
              ...item,
              cancelRequested: false,
              cancellationStatus: null
            };
          }
          return item;
        });
        
        this.setState({ SubscriptionList: updatedList });
      }
      
      return result.data.success;
    } catch (error) {
      console.error("Error reactivating subscription: ", error);
      return false;
    }
  };

  // 強制サブスクリプション終了処理
  handleForceTerminateSubscription = async (orderId, subscriptionId) => {
    try {
      // まずはStripeのサブスクリプションを解約
      const functions = getFunctions();
      const cancelSubscription = httpsCallable(functions, 'cancelSubscription');
      
      await cancelSubscription({
        subscriptionId: subscriptionId,
        orderId: orderId
      });
      
      // 次に出庫処理
      const orderRef = doc(db, "order", orderId);
      const now = new Date();
      
      await setDoc(orderRef, {
        status: "exit_terminated",
        checkoutTime: serverTimestamp(),
        subscriptionExpired: true,
        autoCheckout: true,
        updatedAt: serverTimestamp(),
        canceledAt: serverTimestamp()
      }, { merge: true });
      
      console.log("Subscription forcefully terminated: ", orderId);
      
      // リストを更新
      this.setState({ 
        SubscriptionList: this.state.SubscriptionList.filter(order => order.id !== orderId) 
      });
      
      return true;
    } catch (error) {
      console.error("Error terminating subscription: ", error);
      return false;
    }
  };

  // オーダー削除処理関数
  handleDeleteOrder = async (orderId) => {
    try {
      const orderRef = doc(db, "order", orderId);
      
      // まず注文情報を取得
      const orderDoc = await getDoc(orderRef);
      if (!orderDoc.exists()) {
        console.error("Order not found:", orderId);
        return false;
      }
      
      const orderData = orderDoc.data();
      
      // サブスクリプションの場合、Stripeでサブスクリプションを解約
      if (orderData.isSubscription && orderData.subscriptionId) {
        try {
          const functions = getFunctions();
          const cancelSubscription = httpsCallable(functions, 'cancelSubscription');
          
          await cancelSubscription({
            subscriptionId: orderData.subscriptionId,
            orderId: orderId
          });
        } catch (error) {
          console.error("Failed to cancel subscription:", error);
          // サブスクリプション解約に失敗しても処理を継続
        }
      }
      
      // 状態を中断された状態に更新
      await updateDoc(orderRef, {
        status: "exit_terminated",
        checkoutTime: serverTimestamp(),
        adminTerminated: true,
        terminationReason: "管理者による削除",
        updatedAt: serverTimestamp()
      });
      
      console.log("Order marked as terminated:", orderId);
      
      // リストから削除したオーダーを除外
      this.setState({
        FailedOrdersList: this.state.FailedOrdersList.filter(order => order.id !== orderId)
      });
      
      return true;
    } catch (error) {
      console.error("Error terminating order:", error);
      return false;
    }
  };

  componentDidMount = () => {
    // モジュラー形式のAuthを使用
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // ユーザー情報が props から渡されない場合の対策として、
        // user.uid を直接使用する
        if (!this.props.userdata) {
          console.warn("props.userdata is undefined, using auth user instead");
          this.props.userdata = { uid: user.uid }; // 応急処置としてpropsにuidをセット
        }
        this.getMylist();
      } else {
        this.setState({
          error: "not login",
        });
      }
    });
  };

  componentWillUnmount() {
    // リスナーをクリーンアップ
    if (this.itemChange) {
      this.itemChange();
    }
    if (this.subscriptionChange) {
      this.subscriptionChange();
    }
    if (this.historyChange) {
      this.historyChange();
    }
  }
  
  getStatusMessage = (status) => {
    const statusMessages = {
      prepayment_intent: "入庫前払い処理中",
      prepayment_completed: "入庫中",
      additionalFee_intent: "出庫清算処理中",
      exit_completed: "正常に出庫しました",
      exit_terminated: "強制出庫されました",
      subscription_active: "サブスクリプション有効",
      subscription_past_due: "支払い遅延中",
      subscription_unpaid: "未払い",
      subscription_canceled: "解約済み",
      subscription_payment_failed: "支払い失敗"
    };

    return statusMessages[status] || `ステータス不明: ${status}`;
  };

  render() {
    // Material-ui関連
    const { classes } = this.props;
    const { OrderList, SubscriptionList, HistoryList, FailedOrdersList, tabValue } = this.state;
    
    // 読み込み中はロード画面にする
    const errors = () => {
      switch (this.state.error) {
        case "data-error":
          return <p>データがありません</p>;
        case "not login":
          return <p>ログインが必要です</p>;
        case "no_data":
          return (
            <p>
              <CircularProgress /> <p>データを取得しています</p>
            </p>
          );
        default:
          return this.state.error ? <p>{this.state.error}</p> : "";
      }
    };

    // アクティブな駐車場リストをレンダリング
    const renderActiveOrders = () => {
      if (OrderList.length === 0 && !this.state.getting) {
        return <Typography align="center">現在の駐車場の利用はありません</Typography>;
      }
      
      return (
        <div>
          {OrderList.map((order) => (
            <OrderItem
              key={order.id}
              id={order.id}
              productname={order.productname}
              status={order.status}
              timestamp={order.timestamp}
              arrivalExpectedDate={order.arrivalExpectedDate}
              description={order.description}
              price={order.price}
              quantity={order.quantity}
              sessionId={order.sessionId}
              onCheckout={this.handleForceCheckout}
            />
          ))}
          
          {this.state.getting && <CircularProgress style={{ margin: "20px auto", display: "block" }} />}
          
          {this.state.hasMore && !this.state.getting && (
            <Button 
              variant="contained" 
              color="primary" 
              className={classes.loadMoreButton}
              onClick={this.loadMoreOrders}
            >
              もっと読み込む
            </Button>
          )}
        </div>
      );
    };

    // サブスクリプションリストをレンダリング
    const renderSubscriptions = () => {
      if (SubscriptionList.length === 0 && !this.state.loadingSubscriptions) {
        return <Typography align="center">アクティブなサブスクリプションはありません</Typography>;
      }
      
      return (
        <div>
          {SubscriptionList.map((subscription) => (
            <OrderItem
              key={subscription.id}
              id={subscription.id}
              productname={subscription.productname}
              status={subscription.status}
              timestamp={subscription.timestamp}
              isSubscription={true}
              subscriptionId={subscription.subscriptionId}
              cancelRequested={subscription.cancelRequested}
              currentPeriodEnd={subscription.currentPeriodEnd}
              description={subscription.description}
              price={subscription.price}
              quantity={subscription.quantity || 1}
              sessionId={subscription.sessionId}
              arrivalExpectedDate={subscription.arrivalExpectedDate}
              onCheckout={this.handleForceCheckout}
              onCancelSubscription={this.handleCancelSubscription}
              onReactivateSubscription={this.handleReactivateSubscription}
              onTerminate={this.handleForceTerminateSubscription}
            />
          ))}
          
          {this.state.loadingSubscriptions && <CircularProgress style={{ margin: "20px auto", display: "block" }} />}
          
          {this.state.hasMoreSubscriptions && !this.state.loadingSubscriptions && (
            <Button 
              variant="contained" 
              color="primary" 
              className={classes.loadMoreButton}
              onClick={this.loadMoreSubscriptions}
            >
              もっと読み込む
            </Button>
          )}
        </div>
      );
    };

    // 履歴リストをレンダリング
    const renderHistory = () => {
      if (HistoryList.length === 0 && !this.state.loadingHistory) {
        return <Typography align="center">履歴がありません</Typography>;
      }
      
      return (
        <div>
          {HistoryList.map((history) => (
            <OrderItem
              key={history.id}
              id={history.id}
              productname={history.productname}
              status={history.status}
              timestamp={history.timestamp}
              checkoutTime={history.checkoutTime}
              description={history.description}
              price={history.price}
              quantity={history.quantity}
              isSubscription={history.isSubscription}
              sessionId={history.sessionId}
              arrivalExpectedDate={history.arrivalExpectedDate}
            />
          ))}
          
          {this.state.loadingHistory && <CircularProgress style={{ margin: "20px auto", display: "block" }} />}
          
          {this.state.hasMoreHistory && !this.state.loadingHistory && (
            <Button 
              variant="contained" 
              color="primary" 
              className={classes.loadMoreButton}
              onClick={this.loadMoreHistory}
            >
              もっと読み込む
            </Button>
          )}
        </div>
      );
    };

    // 失敗/中断オーダーのレンダリング
    const renderFailedOrders = () => {
      if (FailedOrdersList.length === 0 && !this.state.loadingFailedOrders) {
        return <Typography align="center">処理中断または失敗したオーダーはありません</Typography>;
      }
      
      return (
        <div>
          {FailedOrdersList.map((order) => (
            <OrderItem
              key={order.id}
              id={order.id}
              productname={order.productname}
              status={order.status}
              timestamp={order.timestamp}
              arrivalExpectedDate={order.arrivalExpectedDate}
              description={order.description}
              price={order.price}
              quantity={order.quantity}
              sessionId={order.sessionId}
              isSubscription={order.isSubscription}
              subscriptionId={order.subscriptionId}
              deletable={true}
              onDelete={this.handleDeleteOrder}
              onCheckout={this.handleForceCheckout}
            />
          ))}
          
          {this.state.loadingFailedOrders && <CircularProgress style={{ margin: "20px auto", display: "block" }} />}
        </div>
      );
    };

    return (
      <div>
        {this.get(this.state, "error") ? (
          <div className="overflow">{errors()}</div>
        ) : (
          ""
        )}

        <div className={classes.tabRoot}>
          <Tabs
            value={tabValue}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="駐車場管理タブ">
            <Tab className={classes.tabLabel} label="現在の駐車状況" {...a11yProps(0)} />
            <Tab className={classes.tabLabel} label="サブスクリプション" {...a11yProps(1)} />
            <Tab className={classes.tabLabel} label="過去の駐車履歴" {...a11yProps(2)} />
            <Tab className={classes.tabLabel} label="失敗/中断" {...a11yProps(3)} />
          </Tabs>

          <TabPanel value={tabValue} index={0}>
            {renderActiveOrders()}
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            {renderSubscriptions()}
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            {renderHistory()}
          </TabPanel>

          <TabPanel value={tabValue} index={3}>
            {renderFailedOrders()}
          </TabPanel>
        </div>

        <div className="overflow"></div>
      </div>
    );
  }
}

// Material-ui関連
OrderList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

// Material-uiのテーマ設定
export default withStyles(styles, { withTheme: true })(OrderList);