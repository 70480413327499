// ModernizedAuthRoute.tsx
import React, { useEffect, useState } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import StripeConnectButton from './components/StripeConnectButton';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Userdata } from './types';

// Firebase v9+ モジュラーインポート
import { 
  getAuth, 
  onAuthStateChanged, 
  User 
} from 'firebase/auth';
import { 
  getFirestore, 
  collection, 
  doc, 
  getDoc, 
  setDoc, 
  serverTimestamp 
} from 'firebase/firestore';

// Firebase アプリインスタンスを取得 (初期化はどこか他の場所で行われていると仮定)
import { getApp } from 'firebase/app';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    margin: 10,
  },
  textLeft: {
    textAlign: 'left',
  },
}));

interface AuthRouteProps extends RouteComponentProps {
  component: React.ComponentType<any>;
  path?: string | string[];
}

const AuthRoute: React.FC<AuthRouteProps> = ({ component: Component, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState<Userdata | null>(null);
  const classes = useStyles();
  const clientId = process.env.REACT_APP_STRIPE_CLIENT_ID ? process.env.REACT_APP_STRIPE_CLIENT_ID : "";
  
  useEffect(() => {
    // Firebase v9 スタイルの認証リスナー
    const auth = getAuth(getApp());
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        getUserData(user).then((data) => {
          setUserData(data);
          setIsAuthenticated(true);
          setLoading(false);
        });
      } else {
        setIsAuthenticated(false);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const getUserData = async (user: User): Promise<Userdata> => {
    // Firebase v9 スタイルの Firestore アクセス
    const db = getFirestore(getApp());
    const userDocRef = doc(db, "users", user.uid);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      return userDoc.data() as Userdata;
    } else {
      const newUserdata: Userdata = {
        uid: user.uid,
        dt: serverTimestamp(),
      };
      await setDoc(userDocRef, newUserdata);
      return newUserdata;
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          return (
            <div className="container">
              <div className={classes.root}>
                <h2>ログイン</h2>
                <div className={classes.textLeft}>
                  <Paper className={classes.root} elevation={1}>
                    <Typography variant="h6" component="h3">
                      Stripe Connectでログインしてください
                    </Typography>
                    <StripeConnectButton />
                  </Paper>
                </div>
              </div>
            </div>
          );
        }

        return <Component {...props} userdata={userData} />;
      }}
    />
  );
};

export default AuthRoute;