import React from "react";
import PropTypes from "prop-types";
// モジュラー形式用にFirebase Authの関数をインポート
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
const auth = getAuth();

import StripeConnectButton from "../components/StripeConnectButton";

import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import Button from "@material-ui/core/Button";
import { Alert } from "@material-ui/lab";

import { FiLogOut } from "react-icons/fi";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

// スタイル
const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: 10,
  },
  twittercolor: {
    "background-color": "#55acee",
  },
  providerbotton: {
    margin: "10px auto",
    "font-weight": "500",
    "font-size": "14px",
    "font-family": "Roboto,Helvetica,Arial,sans-serif",
    height: "auto",
    "line-height": "normal",
    "max-width": "220px",
    "min-height": "40px",
    padding: "8px 16px",
    "text-align": "left",
    border: "none",
    "border-radius": "3",
    "box-shadow":
      "0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)",
    transition:
      "box-shadow .2s cubic-bezier(.4,0,1,1),background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1)",
    "will-change": "box-shadow",
  },
  flex: {
    width: "100%",
    "flex-direction": "row",
    display: "flex",
  },
  providericon: {
    height: "18px",
    width: "18px",
  },
  textLeft: {
    textAlign: "left",
  },
  textCenter: {
    textAlign: "center",
  },
  img70: { 
    width: "70%",
  },
  paragraph: {
    marginTop: 10,
    marginBottom: 10,
  },
});

class SignInOrUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      warning: "",
      info: props.userdata?.displayName
        ? props.userdata.displayName + "でログインしています"
        : "現在ログインしていません",
      success: "",
      urlcopy: false,
    };
  }

  componentDidMount = () => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.setState({
          user: user,
          info: this.props.userdata?.displayName
            ? this.props.userdata.displayName + "でログインしています"
            : "現在ログインしていません",
        });
        this.setState({
          warning: "現在ログインしていません",
        });
        console.log("log in");
      } else {
        // 認証されていない場合
        console.log("not log in");
      }
    });
  };

  copyUrlToClipboard = () => {
    const url = window.location.href;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        this.setState({
          urlcopy: true,
        });
      })
      .catch((error) => {
        console.error("URLのコピーに失敗しました:", error);
      });
  };

  logout = () => {
    signOut(auth)
      .then(() => {
        console.log("User signed out successfully");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className="container">
        <div>
          <div className={classes.root}>
            <Typography variant="headline" component="h2">
              ご利用登録・ログイン
            </Typography>
            <br />
            {this.state.error ? (
              <Alert severity="error">{this.state.message}</Alert>
            ) : (
              ""
            )}
            {this.state.warning ? (
              <Alert severity="warning">{this.state.warning}</Alert>
            ) : (
              ""
            )}
            {this.state.info ? (
              <Alert severity="info">{this.state.info}</Alert>
            ) : (
              ""
            )}
            {this.state.success ? (
              <Alert severity="success">{this.state.success}</Alert>
            ) : (
              ""
            )}

            <Paper className={classes.root} elevation={1}>
              <Typography variant="headline" component="h4">
                QRパーキング駐車場管理画面の利用にはStripe認証が必要です
              </Typography>
              <Typography variant="headline" component="p">
                QRパーキングはStripeのアカウントを使ってオーナー様の識別を行っております
                <br />
                QRパーキングの駐車場登録や料金設定にStripeのアカウントのログインが必要です
              </Typography>
            </Paper>

            <Paper className={classes.root} elevation={1}>
              <Typography variant="headline" component="h3">
                準備が整ったら下記からログインをお願いします
                <br />
              </Typography>
              <Typography variant="headline" component="p">
                <div className={classes.flex}>
                  <br />
                  <StripeConnectButton />
                  <br />
                </div>
              </Typography>
            </Paper>
            <br />
          </div>
        </div>
      </div>
    );
  }
}
// Material-ui関連
SignInOrUp.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

// Material-uiのテーマ設定
export default withStyles(styles, { withTheme: true })(SignInOrUp);
