import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { doc, collection, query, where, orderBy, startAfter, limit, getDoc, getDocs } from 'firebase/firestore';
import { db } from '../Firebase';

import Item from '../components/Item';
import { FaBullseye, FaLessThanEqual } from 'react-icons/fa';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    textAlign: 'center',
    align: 'center',
    width:'90%',
  },
  textcenter: {
    width:'100%',
    align:'center',
    whiteSpace: 'nowrap',  
    overflow: 'hidden',  
    textOverflow: 'ellipsis', 
    WebkitTextOverflow: 'ellipsis',  
    OTextOverflow: 'ellipsis',
    textAlign: 'center',
  },
  accordionSummary: {
    height: '15px',
    minHeight: '15px',
    padding: '0',
    margin: '0',
  },
  accordionDetails: {
    padding: '0',
    margin: '0',
    display: 'flex',
    flexWrap:'wrap',
  },
  expanded: {
    minHeight: '15px',
    padding: '0',
    margin: '0',
  },
  resize:{
    height: '15px',
    fontSize: '12px',
    padding: '0',
    margin: '0',
    width:'20px',
  },
  searchbox: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  categlybox: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  inlinetext: {
    top:"-10px",
    fontSize: '12px',
  },
  searchbottn: {
    top:"-5px",
    lineHeight: '16px',    
  },
  serchtext: {
    width:'50px'
  },
  pricetext: {
    width:'50px'
  },
  selectSortType: {
    fontSize: '10px',
    lineHeight: '0px',
  },
});

class L extends React.Component {
  datalimit = 50;

  constructor(props) {
    super(props);
    this.state = {
      favCategory: [],
      currentCategory: { categoryId:"0", categoryEnName:"root", categoryName:"Top" },
      categoryLst: [],
      itemList: [],
      getting: true,
      page: 0,
      error: '',
      endofdata: false,
    };
  }
  
  // python.get()
  get = (dic, key) => {
    if (dic && key in dic) return dic[key];
    return null;
  }

  getData = async () => {
    if (this.state.endofdata) {
      return;
    }
    let bufitemList = [];
    let userdat;
    let endofdata = false;
    const userDocRef = doc(db, 'users', this.props.match.params.userId);
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      userdat = userDocSnap.data();
    }
    let q;
    if (this.state.lastVisible) {
      q = query(
        collection(db, 'users', userDocSnap.id, 'items'),
        where("status", "==", "available"),
        orderBy("dt", "desc"),
        startAfter(this.state.lastVisible),
        limit(this.datalimit)
      );
    } else if (this.state.page === 0) {
      q = query(
        collection(db, 'users', userDocSnap.id, 'items'),
        where("status", "==", "available"),
        orderBy("dt", "desc"),
        limit(this.datalimit)
      );
    } else {
      return;
    }

    const querySnapshot = await getDocs(q);

    if (querySnapshot.size < this.datalimit) {
      endofdata = true;
    }

    querySnapshot.forEach((docSnap) => {
      const docdat = docSnap.data();
      let price;
      if ('minActivityAmount' in docdat.priceModule) {
        price = docdat.priceModule.minActivityAmount.value;
      } else if ('maxActivityAmount' in docdat.priceModule) {
        price = docdat.priceModule.maxActivityAmount.value;
      } else if ('minAmount' in docdat.priceModule) {
        price = docdat.priceModule.minAmount.value;
      } else if ('maxAmount' in docdat.priceModule) {
        price = docdat.priceModule.maxAmount.value;
      }
      
      const dat = {
        title: { displayTitle: docdat.pageModule.title.split("|")[0] },
        image: { imgUrl: docdat.imageModule.imagePathList[0] ? docdat.imageModule.imagePathList[0].replace('https:', '') : "" },
        prices: { salePrice: { formattedPrice: "￥" + parseInt(price).toLocaleString(), value: parseInt(price) } },
        shipping: (docdat.freightAmount.shipping_price ? parseInt(docdat.freightAmount.shipping_price.replace(/[^0-9]/g, '')) || 0 : 0),
        productId: docdat.sourceItemId,
        dt: docdat.dt,
        itemId: docdat.itemId,
      };
      bufitemList.push(dat);
    });

    bufitemList = this.state.itemList.concat(bufitemList);
    this.setState({
      endofdata: endofdata,
      error: '',
      page: this.state.page + 1,
      lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1],
      itemList: bufitemList,
      userdata: userdat,
      getting: false,
    });
  }

  watchCurrentPosition() {
    const pos = this.topParts.clientHeight + this.topParts.getBoundingClientRect().top;
    window.itemlistrepos = pos;
    if (pos < 10000 && this.state.getting === false) {
      console.log('pos: ', pos);
      this.setState({
        getting: true,
      }, () => {
        this.getData();
      });
    }
  }

  componentDidMount = () => {
    window.addEventListener('scroll', event => this.watchCurrentPosition(), true);
    this.getData();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', event => this.watchCurrentPosition(), true);
  }

  render() {
    const { classes } = this.props;
    const Items = this.state.itemList ? this.state.itemList : [];
    const ItemsLength = Items ? Items.length : 0;
    let itemCards = [];
    for (let index = 0; index < ItemsLength; index++) {
      const title = Items[index].title ? Items[index].title.displayTitle : '';
      const imgUrl = Items[index].image ? "https:" + Items[index].image.imgUrl.split('_220x220')[0] : "";
      const price = Items[index].prices.salePrice ? Items[index].prices.salePrice.value : 0;
      const price2 = parseInt(price * this.state.userdata.MarkupRatio, 10);
      const shipping = Items[index].shipping;
      const shippingprice = shipping.toString().replace(/[^0-9]/g, '');
      const shippingprice2 = shippingprice ? parseInt(shippingprice) : 0;
      const trueprice = '￥ ' + (price2 + shippingprice2).toLocaleString();
      const uid = this.state.userdata.uid;
      const itemid = Items[index].itemId;
      const href = "/i/" + uid + "/" + itemid;
      const deletable = false;
      const status = "";
      if ((price2 + shippingprice2) > 150) {
        itemCards.push(<Item key={itemid} empty={false} title={title} imgUrl={imgUrl} price={trueprice} href={href} uid={uid} itemid={itemid} getMylist={this.getData} deletable={deletable} />);
      }
    }
    const errors = () => {
      switch (this.state.error) {
        case 'data-error':
          return <p>データがありません</p>;
        case 'not login':
          return <p>ログインが必要です</p>;
        case 'no_data':
          return (
            <p>
              <CircularProgress /> <p>データを取得しています</p>
            </p>
          );
        default:
          return this.state.error ? <p>{this.state.error}</p> : "";
      }
    }
    return (
      <>
        {this.get(this.state, "error") ? <div className="overflow">{errors()}</div> : ""}
        <div className={classes.textcenter} ref={(div) => { this.topParts = div }} id="scrolldiv">
          {itemCards}
          {this.state.getting && !this.state.endofdata ? <CircularProgress /> : this.state.endofdata ? <> - END - </> : null}
        </div>
      </>
    );
  }
}

L.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(L);
