import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch } from "react-redux";

// Firebase v9 imports
import { getFunctions, httpsCallable } from "firebase/functions";
import { getApp } from "firebase/app";

// スタイルの定義
const useStyles = makeStyles({
  button: {
    backgroundColor: "#69b076",
    color: "#FFF",
    fontSize: 20,
    height: 26,
    width: "auto",
    padding: "0px 30px",
  },
});

// プロパティの型定義
interface PurchaseButtonProps {
  status: string;
  productid: string;
  mode: string; // 'payment' または 'subscription'
  feeType: string;
  price: number;
  quantity: number;
  name: string;
  image: string;
  description: string;
  ambassadorid: string;
  ambassadordisplayName: string;
  ambassadorEmail: string;
  arrivalExpectedDate: string;
  label: string;
  hourlyRate: number;
  dailyRate: number;
  monthlyRate: number;
  additionalFee: number;
  applicationFeeamountRate: number;
  sessionId?: string;
  planId?: string;
  subscriptionPlanId?: string;
}

const PurchaseButton: React.FC<PurchaseButtonProps> = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handlePayment = async () => {
    try {
      // Get functions instance with region
      const functions = getFunctions(getApp(), 'asia-northeast2');
      
      // Create callable function
      const createSession = httpsCallable(functions, 'createPaymentSession2');
      
      // Load Stripe
      const pk_id = process.env.REACT_APP_STRIPE_CLIENT_ID;
      const stripePromise = pk_id ? loadStripe(pk_id) : null;
      const stripe = await stripePromise;

      if (stripe) {
        // Call Firebase function
        const result = await createSession({
          status: props.status,
          productid: props.productid,
          mode: props.mode, // 'payment' または 'subscription'
          feeType: props.feeType,
          price: props.price,
          quantity: props.quantity,
          name: props.name,
          image: props.image,
          description: props.description,
          ambassadorid: props.ambassadorid,
          ambassadordisplayName: props.ambassadordisplayName,
          ambassadorEmail: props.ambassadorEmail,
          arrivalExpectedDate: props.arrivalExpectedDate,
          label: props.label,
          hourlyRate: props.hourlyRate,
          dailyRate: props.dailyRate,
          monthlyRate: props.monthlyRate,
          additionalFee: props.additionalFee,
          applicationFeeamountRate: props.applicationFeeamountRate || 0.1,
          sessionId: props.sessionId || null,
          planId: props.planId || null,
          subscriptionPlanId: props.subscriptionPlanId || null,
          isSubscription: props.mode === 'subscription'
        });
        
        // Extract session ID from result
        const data = result.data as { id: string };
        console.log("sessionId:" + data.id);
        
        // Redirect to Stripe Checkout
        const redirectResult = await stripe.redirectToCheckout({
          sessionId: data.id,
        });
        
        if (redirectResult.error) {
          console.log(redirectResult.error);
        }
      } else {
        console.log('Stripe.js has not loaded yet.');
      }
    } catch (error) {
      console.error('Payment session creation failed:', error);
    }
  };

  return (
    <Button
      className={classes.button}
      variant="contained"
      onClick={handlePayment}
    >
      {props.label}
    </Button>
  );
};

export default PurchaseButton;