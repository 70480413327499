import React from "react";
import { useStripe } from "@stripe/react-stripe-js";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";

// Firebase v9 imports
import { getFunctions, httpsCallable } from "firebase/functions";
import { getApp } from "firebase/app";

// スタイルの定義
const useStyles = makeStyles({
  button: {
    backgroundColor: "#69b076",
    color: "#FFF",
    fontSize: 12,
    height: "auto",
    width: "auto",
    padding: "0px 30px",
  },
});

interface StripeConnectButtonProps {
  accountId?: string;
}

const StripeConnectButton: React.FC<StripeConnectButtonProps> = ({ accountId }) => {
  const classes = useStyles();

  const handleClick = async () => {
    try {
      // GTMのデータレイヤーにイベントをプッシュ
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'stripeLinkClick',
        accountId: accountId,
      });

      // Firebase Functions のインスタンスを取得
      const functions = getFunctions(getApp(), "asia-northeast2");
      
      // 呼び出し可能な関数を作成
      const createStripeAccount = httpsCallable(functions, "createStripeAccount");

      // accountIdが存在する場合のみオブジェクトに含める
      const response = await createStripeAccount(accountId ? { accountId } : {});

      // レスポンスからURLを取得して、そのURLにリダイレクト
      const data = response.data as { url: string };
      window.location.href = data.url;
    } catch (error) {
      console.error("Error creating Stripe account:", error);
    }
  };

  return (
    <Button
      className={classes.button}
      variant="contained"
      onClick={handleClick}
    >
      Stripe利用登録を開始する
      <br />
      Stripe認証を使って
      <br />
      QRパーキングにログインする
    </Button>
  );
};

export default StripeConnectButton;