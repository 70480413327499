import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
// モジュラー形式用にFirestoreの関数をインポート
import { getDocs, collection, doc, setDoc } from 'firebase/firestore';
import { db } from '../Firebase';

import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
});

class Test extends React.Component {

  setallusersallitems() {
    let bufitemList = [];
    // 変更: collection()を使用して全ユーザーを取得
    getDocs(collection(db, 'users')) // .where("delete", "!=", true)//絞り込み
      .then((usersquerySnapshot) => {
        usersquerySnapshot.forEach((user) => {
          const userdat = user.data();
          console.log("user: " + userdat.ambassadordisplayName);
          console.log("user.MarkupRatio: " + userdat.MarkupRatio);

          // userに対する逐次処理はここ
          // userdat.MarkupRatio = 1;
          // setDoc(doc(db, 'users', user.id), userdat);
          // 変更: 各ユーザーのitemsコレクションを取得
          getDocs(collection(db, 'users', user.id, 'items')) // .where("delete", "!=", true)//絞り込み
            .then((itemsquerySnapshot) => {
              itemsquerySnapshot.forEach((item) => {
                let itemB = item.data();
                // console.log("item:" + itemB.pageModule.title);
                // console.log("Aname:" + itemB.ambassador.ambassadordisplayName);
                // itemB.ambassador.MarkupRatio = 1;
                // 変更: 各itemを上書き保存
                setDoc(doc(db, 'users', user.id, 'items', item.id), itemB);
              });
            });
        });
      });
  }

  // url https://qrparking.jp/xxx__test__xxx
  setitemdata(userid) {
    getDocs(collection(db, 'users', userid, 'items')) // .where("delete", "!=", true)//絞り込み
      .then((itemsquerySnapshot) => {
        itemsquerySnapshot.forEach((item) => {
          let itemB = item.data();
          // console.log("item:" + itemB.pageModule.title);
          // console.log("Aname:" + itemB.ambassador.ambassadordisplayName);
          // itemB.ambassador.MarkupRatio = 2;
          /*
          let tags = itemB.tags;
          if (tags) {
            var result = tags.filter(function( item ) {
              return item != 'sppALL';
            });
            result.push('sppALL');
            result.sort();
            itemB.tags = result;
          */
          if (itemB.delete) {
            itemB.status = "delete";
          }
          else {
            itemB.status = "available";
          }
          setDoc(doc(db, 'users', userid, 'items', item.id), itemB);
        });
      });
  }

  render() {
    this.setitemdata("pjN1W1a1V5ZF09iBPcSa5Zo5xb22");
    return "DONE";
  }
}

// Material-ui関連
Test.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

// Material-uiのテーマ設定
export default withStyles(styles, { withTheme: true })(Test);
