import React from "react";
import PropTypes from "prop-types";
import { withStyles, ThemeProvider } from "@material-ui/core/styles";
import { Input, TextField, Button, Paper } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

// Firebase v9+ imports
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  onSnapshot,
  serverTimestamp,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

// Firebase設定とdbは外部ファイルからインポート
import { firebaseConfig, db } from "../Firebase";

import PurchaseButton from "../components/PurchaseButton2";
import SubscriptionManagement from "../components/SubscriptionManagement";

// Material-UIのスタイル
const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: 10,
  },
  textLeft: {
    textAlign: "left",
  },
  textCenter: {
    textAlign: "center",
  },
  paragraph: {
    marginTop: 10,
    marginBottom: 10,
  },
  img: {
    width: "80%",
    display: "block",
    margin: "0 auto",
  },
});

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.scrollToRef = React.createRef();
    this.reloadInterval = null;
    // Firebase Functions の初期化
    this.functions = getFunctions(
      initializeApp(firebaseConfig),
      "asia-northeast2"
    );
  }

  state = {
    addresslist: [],
    orderdat: { status: "no_data" },
    shippingAddress: "",
    user: {
      zip: "",
      address1: "",
      address2: "",
      address3: "",
      name: "",
      tel: "",
    },
    error: "",
    additionalFee: 0,
    diffTimeString: "",
    bestCombinationString: "", // 追加
  };
  // Stripe セッションと顧客情報の取得（サブスクリプションの場合）
  getSessionAndCustomer = async (sessionId) => {
    try {
      const getStripeSessionAndCustomerBySessionId = httpsCallable(
        this.functions,
        "getStripeSessionAndCustomerBySessionId"
      );
      const result = await getStripeSessionAndCustomerBySessionId(sessionId);

      if (
        result.data &&
        result.data.session &&
        result.data.session.mode === "subscription"
      ) {
        console.log("サブスクリプション情報を処理:", result.data.session);
        const updateOrderDoc = doc(
          db,
          "/order",
          this.props.match.params.orderId
        );
        await setDoc(
          updateOrderDoc,
          {
            status: "subscription_active",
            subscriptionId: result.data.session.subscription,
            isSubscription: true,
          },
          { merge: true }
        );
        console.log("サブスクリプションステータスを更新しました");
      }
      return result.data;
    } catch (error) {
      console.error("セッション情報取得エラー:", error);
      throw error;
    }
  };

  mailsend = async (orderdat) => {
    const generateEmailAndSend = httpsCallable(
      this.functions,
      "generateEmailAndSend"
    );
    generateEmailAndSend(orderdat).then((result) => {
      console.log("mailsend:" + result);
    });
  };

  // 改善後の getoderData メソッド
  getoderData = async (oderId) => {
    const orderdocRef = doc(db, "/order", oderId);
    const orderdoc = await getDoc(orderdocRef);
    const success = this.props.match.params.success === "success";
    console.log("getdat::");

    if (!orderdoc.exists()) {
      console.log("no_data_orderdoc");
      this.setState({
        orderdat: { status: "no_data" },
      });
      return;
    }

    console.log("orderdoc.exists");
    const orderdat = orderdoc.data();

    // 共通処理：顧客データの取得と状態更新
    const getCustomerAndUpdateState = async () => {
      await this.getcustomerData(orderdat);
      this.setState({
        orderdat: orderdat,
        additionalFee: orderdat.additionalFee || 0,
      });
    };

    if (success) {
      switch (orderdat.status) {
        case "prepayment_intent":
          // Stripe決済セッション情報を取得
          await this.getSessionAndCustomer(orderdat.stripesessionid);
          // サブスクリプションの場合は直接ステータス更新してリロード
          if (orderdat.mode === "subscription" || orderdat.isSubscription) {
            await setDoc(
              orderdocRef,
              { status: "subscription_active" },
              { merge: true }
            );
            window.location.reload(true);
            return;
          } else {
            setTimeout(() => window.location.reload(true), 1000);
          }
          break;
        case "prepayment_completed":
          await getCustomerAndUpdateState();
          await this.handleCalculateFee(orderdat);
          break;
        case "additionalFee_intent":
          orderdat.status = "exit_completed";
          try {
            await setDoc(orderdocRef, orderdat, { merge: true });
            await getCustomerAndUpdateState();
          } catch (error) {
            console.log("Error updating document:", error);
            this.setState({ error: "データの更新中にエラーが発生しました。" });
          }
          break;
        case "subscription_active":
        case "subscription_payment_failed": // 新規追加
        case "exit_completed":
        case "exit_terminated":
          await getCustomerAndUpdateState();
          break;
        default:
          await getCustomerAndUpdateState();
          break;
      }
    } else {
      // success=falseの場合
      await getCustomerAndUpdateState();
    }
  };

  getcustomerData = async (orderdat) => {
    console.log("orderdat.customerid", orderdat.customerid);
    const customerRef = doc(db, "/customer", orderdat.customerid);
    const customerdoc = await getDoc(customerRef);
    let customerdat = {};
    if (customerdoc.exists()) {
      customerdat = customerdoc.data();
      console.log("get_customerdata:: ", customerdat);
    } else {
      console.log("no_customerdata");
    }
    this.setState({
      customer: customerdat,
      orderdat: orderdat,
    });
  };

  handleChange = (e) => {
    const params = { ...this.state.user };
    params[e.target.name] = e.target.value;
    this.setState({ user: params });
  };

  sendmail = (data) => {
    const _sendmail = httpsCallable(this.functions, "sendMail");
    _sendmail(data).then((result) => {
      console.log("result:" + result);
    });
  };

  sendSMS = (data) => {
    const _sendSMS = httpsCallable(this.functions, "sendSMS");
    _sendSMS(data).then((result) => {
      console.log("result:" + result);
    });
  };

  submitonClick = () => {
    const orderdocRef = doc(db, "/order", this.props.match.params.orderId);
    console.log("orderId::" + this.props.match.params.orderId);
    setDoc(
      orderdocRef,
      {
        status: "exit_completed",
        checkoutTime: serverTimestamp(),
      },
      { merge: true }
    )
      .then(() => setTimeout(() => window.location.reload(true), 1000))
      .catch((error) => {
        console.log("Error updating document:", error);
      });
    this.sendmail({
      destination: ["info01@qrparking.jp"],
      subject: "出庫が正常終了しました",
      text: JSON.stringify(
        {
          customerdat: this.state.customerdat,
          orderdat: this.state.orderdat,
        },
        null,
        "\t"
      ),
    });
  };

  zenNum2HanNum = (num) => {
    var z = ["０", "１", "２", "３", "４", "５", "６", "７", "８", "９"];
    for (var i = 0; i < 10; i++) num = num.replace(new RegExp(z[i], "g"), i);
    num = num.replace(new RegExp("[^0-9]", "g"), "");
    return num;
  };

  componentDidMount = () => {
    this.getoderData(this.props.match.params.orderId);
    const orderdocRef = doc(db, "/order", this.props.match.params.orderId);
    this.unsubscribe = onSnapshot(
      orderdocRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const orderdat = docSnap.data();
          if (
            orderdat.timestamp &&
            typeof orderdat.timestamp.toDate !== "function"
          ) {
            orderdat.timestamp = new Date(orderdat.timestamp.seconds * 1000);
          }
          this.setState(
            {
              orderdat: orderdat,
              additionalFee: orderdat.additionalFee,
            },
            () => {
              if (orderdat.status === "prepayment_completed") {
                this.handleCalculateFee(orderdat);
              }
              if (orderdat.customerid && !this.state.customer) {
                this.getcustomerData(orderdat);
              }
            }
          );
        }
      },
      (error) => {
        console.error("Error listening to order updates:", error);
        this.setState({
          error:
            "データの監視中にエラーが発生しました。ページを再読み込みしてください。",
        });
      }
    );

    if (window.location.hash === "#reload") {
      this.scrollToElement();
    }
    this.startAutoReload();
  };

  componentWillUnmount() {
    this.stopAutoReload();
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  startAutoReload = () => {
    this.reloadInterval = setInterval(() => {
      this.handleCalculateFee();
    }, 60000);
  };

  stopAutoReload = () => {
    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
    }
  };

  scrollToElement = () => {
    if (this.scrollToRef.current) {
      this.scrollToRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  reloadAndScroll = () => {
    window.location.hash = "reload";
    window.location.reload();
  };

  // 共通コンポーネント
  PaymentInfo = ({ classes, orderdat }) => {
    const { additionalFee, diffTimeString, bestCombinationString } = this.state;
    return (
      <Paper className={classes.root} elevation={1}>
        入庫時間：
        {orderdat.timestamp
          .toDate()
          .toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" })}
        <br />
        {orderdat.description} ￥{orderdat.price.toLocaleString()} X{" "}
        {orderdat.quantity}
        <br />
        前払い金合計：
        {"￥" + (orderdat.price * orderdat.quantity).toLocaleString()}
        <br />
        {orderdat.arrivalExpectedDate}
        <br />
        {diffTimeString && (
          <>
            <span>{diffTimeString}</span>
            <br />
          </>
        )}
        {additionalFee > 0 && bestCombinationString && (
          <>
            <span style={{ fontWeight: "bold" }}>{bestCombinationString}</span>
            <br />
          </>
        )}
        <br />
        延長清算金：
        {additionalFee ? "￥" + additionalFee.toLocaleString() : 0}
        {orderdat.monthlyRate && (
          <>
            <br />
            <span style={{ fontSize: "small" }}>
              月極料金：￥{orderdat.monthlyRate.toLocaleString()}
            </span>
          </>
        )}
        {orderdat.dailyRate && (
          <>
            <br />
            <span style={{ fontSize: "small" }}>
              終日料金：￥{orderdat.dailyRate.toLocaleString()}
            </span>
          </>
        )}
        {orderdat.hourlyRate && (
          <>
            <br />
            <span style={{ fontSize: "small" }}>
              時間料金：￥{orderdat.hourlyRate.toLocaleString()}
            </span>
          </>
        )}
        <br />
        {orderdat.checkoutTime
          ? "出庫時間：" + orderdat.checkoutTime.toDate().toLocaleString()
          : ""}
        {orderdat.isSubscription && (
          <>
            <br />
            <span style={{ fontWeight: "bold" }}>サブスクリプション：有効</span>
          </>
        )}
      </Paper>
    );
  };

  ImagePaper = ({ classes, src, alt }) => (
    <Paper className={classes.root} elevation={1}>
      <img src={src} alt={alt} className={classes.img} />
    </Paper>
  );

  // calculateAdditionalFee メソッドの修正
  // サブスクリプションの状態を考慮した追加料金計算
  handleCalculateFee = async () => {
    // サブスクリプションが有効な場合は追加料金を計算しない
    if (
      this.state.orderdat.isSubscription &&
      (this.state.orderdat.status === "subscription_active" ||
        this.state.orderdat.status === "prepayment_completed")
    ) {
      this.setState({
        additionalFee: 0,
        diffTimeString: "サブスクリプション契約中: 追加料金はかかりません",
        bestCombinationString: "",
      });
      return;
    }

    // サブスクリプションがキャンセルされた場合は残り期間を表示
    if (
      this.state.orderdat.isSubscription &&
      (this.state.orderdat.cancelRequested ||
        this.state.orderdat.status === "subscription_canceled")
    ) {
      const now = new Date();
      const currentPeriodEnd = this.state.orderdat.currentPeriodEnd.toDate();

      if (now < currentPeriodEnd) {
        // 残り時間を計算
        const remainingTime = currentPeriodEnd - now;
        const days = Math.floor(remainingTime / (24 * 60 * 60 * 1000));
        const hours = Math.floor(
          (remainingTime % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
        );

        this.setState({
          additionalFee: 0,
          diffTimeString: `サブスクリプション解約済み: 残り期間は ${days}日 ${hours}時間 です`,
          bestCombinationString: "期間終了後は自動的に出庫処理されます",
        });
        return;
      } else {
        // 期間終了後は自動出庫処理のためのフラグをセット
        if (
          this.state.orderdat.status !== "exit_completed" &&
          this.state.orderdat.status !== "exit_terminated"
        ) {
          this.handleSubscriptionExpiration();
        }
        return;
      }
    }

    // 通常の追加料金計算（既存のロジック）
    const calculateAdditionalFee = httpsCallable(
      this.functions,
      "calculateAdditionalFee"
    );
    try {
      const result = await calculateAdditionalFee({
        price: this.state.orderdat.price,
        quantity: this.state.orderdat.quantity,
        startTime: this.state.orderdat.timestamp,
        hourlyRate: this.state.orderdat.hourlyRate,
        dailyRate: this.state.orderdat.dailyRate,
        monthlyRate: this.state.orderdat.monthlyRate,
        isSubscription: this.state.orderdat.isSubscription,
        feeType: this.state.orderdat.feeType,
      });
      if (
        result.data &&
        result.data.additionalFee !== undefined &&
        result.data.diffTimeString
      ) {
        this.setState({
          additionalFee: result.data.additionalFee,
          diffTimeString: result.data.diffTimeString,
          bestCombinationString: result.data.bestCombinationString || "",
        });
      } else {
        console.error("Received data is not in expected format.");
      }
    } catch (error) {
      console.error("Error calculating parking fee:", error);
    }
  };
  // サブスクリプション期間終了後の自動出庫処理
  handleSubscriptionExpiration = async () => {
    const orderdocRef = doc(db, "/order", this.props.match.params.orderId);
    console.log(
      "Subscription period ended. Processing auto-checkout for:",
      this.props.match.params.orderId
    );

    try {
      await setDoc(
        orderdocRef,
        {
          status: "exit_completed",
          checkoutTime: serverTimestamp(),
          subscriptionExpired: true,
          autoCheckout: true,
        },
        { merge: true }
      );

      // 管理者通知メール送信
      this.sendmail({
        destination: ["info01@qrparking.jp"],
        subject: "サブスクリプション期間終了による自動出庫",
        text: JSON.stringify(
          {
            customerdat: this.state.customerdat,
            orderdat: this.state.orderdat,
            message:
              "サブスクリプション期間終了により自動出庫処理を行いました。",
          },
          null,
          "\t"
        ),
      });

      console.log("Auto-checkout completed for expired subscription");
      setTimeout(() => window.location.reload(true), 1000);
    } catch (error) {
      console.error("Error during auto-checkout:", error);
    }
  };

  // ----------------------
  // レンダリングメソッド（statusComponents マッピングを利用）
  // ----------------------

  renderPaymentIntent() {
    const { classes } = this.props;
    const { orderdat } = this.state;
    return (
      <div>
        <h2>
          ただいまカード会社の決済を待っています
          <br />
          しばらくお待ちください
        </h2>
        <div className={classes.textLeft}>
          <this.ImagePaper
            classes={classes}
            src={orderdat.image}
            alt="picture"
          />
          <this.PaymentInfo classes={classes} orderdat={orderdat} />
        </div>
      </div>
    );
  }

  renderAdditionalFeeIntent() {
    const { classes } = this.props;
    const { orderdat } = this.state;
    return (
      <div>
        <h2>
          ただいまカード会社の決済を待っています
          <br />
          しばらくお待ちください
        </h2>
        <div className={classes.textLeft}>
          <this.ImagePaper
            classes={classes}
            src={orderdat.image}
            alt="picture"
          />
          <this.PaymentInfo classes={classes} orderdat={orderdat} />
        </div>
      </div>
    );
  }

  renderSubscriptionActive() {
    const { classes } = this.props;
    const { orderdat } = this.state;
    return (
      <div>
        <h2>月極駐車場サブスクリプション</h2>
        <div className={classes.textLeft}>
          <this.ImagePaper
            classes={classes}
            src={orderdat.image}
            alt="picture"
          />
          <this.PaymentInfo classes={classes} orderdat={orderdat} />
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
              サブスクリプションは正常に登録されました。
              <br />
              毎月自動的に更新されます。
            </Typography>
          </Paper>
          <SubscriptionManagement
            customerId={orderdat.customerid}
            classes={classes}
          />
        </div>
      </div>
    );
  }

  renderSubscriptionPaymentFailed() {
    const { classes } = this.props;
    const { orderdat } = this.state;
    return (
      <div>
        <h2>サブスクリプションの支払いに失敗しました</h2>
        <div className={classes.textLeft}>
          <this.ImagePaper
            classes={classes}
            src={orderdat.image}
            alt="picture"
          />
          <this.PaymentInfo classes={classes} orderdat={orderdat} />
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
              サブスクリプションの支払いに問題がありました。
              <br />
              支払い方法を更新するか、カード会社にお問い合わせください。
            </Typography>
          </Paper>
          <SubscriptionManagement
            customerId={orderdat.customerid}
            classes={classes}
          />
        </div>
      </div>
    );
  }

  renderPaymentCompleted() {
    const { classes } = this.props;
    const { orderdat, additionalFee, diffTimeString, bestCombinationString } =
      this.state;
    return (
      <div className={classes.root}>
        <h2>前払い決済が成功しました</h2>
        {additionalFee ? (
          <Typography variant="headline" component="h3">
            <span style={{ color: "rgb(181, 49, 49)", fontWeight: "bold" }}>
              超過料金が発生しています。
            </span>
            <br />
            出庫時に超過料金を精算してください。
          </Typography>
        ) : null}
        <div className={classes.textLeft}>
          <this.ImagePaper
            classes={classes}
            src={orderdat.image}
            alt="picture"
          />
          <this.PaymentInfo classes={classes} orderdat={orderdat} />
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
              <span style={{ fontWeight: "bold" }}>{diffTimeString}</span>
            </Typography>
            {additionalFee > 0 && bestCombinationString && (
              <Typography variant="headline" component="h3">
                <span style={{ fontWeight: "bold" }}>
                  {bestCombinationString}
                </span>
              </Typography>
            )}
            <Typography variant="headline" component="h3">
              {additionalFee ? (
                <>
                  超過料金は
                  <span
                    style={{ color: "rgb(181, 49, 49)", fontWeight: "bold" }}
                  >
                    {additionalFee.toLocaleString()}円
                  </span>
                  です。
                  <br />
                  追加のお支払いは自動的にカード決済されません。
                  <br />
                  下の「精算して出庫する」ボタンから精算して出庫してください。
                </>
              ) : (
                <>現在精算金はありません。</>
              )}
            </Typography>
            <Typography component="p">
              終日は２４時間、月極は翌月の同日までの料金です。
              <br />
              出庫時に必ず出庫手続きをお願いします。
              <br />
              出庫処理をしていない場合、駐車料金は加算され続けますのでご注意ください。
              <br />
              <a
                href="https://qrparking.jp/specifiedcommercialtransactionact#OverchargeSettlementPolicy"
                target="_blank"
                rel="noopener noreferrer"
              >
                後払い精算に関するポリシー
              </a>
              をご確認ください。
            </Typography>
            <Typography variant="headline" component="h3">
              未精算には開示請求により所有者に請求させていただきます。
              <br />
              未精算のまま駐車場を離れてしまった場合も下のボタンから出庫してください。
            </Typography>
          </Paper>
          <form className={classes.root} noValidate autoComplete="off">
            <Typography className={classes.textCenter} component="p">
              {additionalFee ? (
                <PurchaseButton
                  status="additionalFee_intent"
                  productid={this.props.match.params.itemId}
                  mode="payment"
                  feeType={orderdat.feeType}
                  price={orderdat.price}
                  applicationFeeamountRate={orderdat.applicationFeeamountRate}
                  additionalFee={additionalFee}
                  quantity={1}
                  name={orderdat.name}
                  image={orderdat.image}
                  description={orderdat.description}
                  ambassadorid={orderdat.ambassadorid}
                  ambassadordisplayName={orderdat.ambassadordisplayName}
                  ambassadorEmail={orderdat.ambassadorEmail}
                  arrivalExpectedDate={orderdat.arrivalExpectedDate}
                  label="精算して出庫する"
                  sessionId={orderdat.sessionId}
                  hourlyRate={orderdat.hourlyRate}
                  dailyRate={orderdat.dailyRate}
                  monthlyRate={orderdat.monthlyRate}
                  subscriptionPlanId={orderdat.subscriptionPlanId}
                  planId={orderdat.planId}
                />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.submitonClick}
                >
                  出庫する
                </Button>
              )}
            </Typography>
            <br />
            <Typography className={classes.textCenter} component="p">
              <a href="#" onClick={this.reloadAndScroll} ref={this.scrollToRef}>
                <Button variant="contained" color="primary">
                  画面更新して料金を確認する
                </Button>
              </a>
            </Typography>
          </form>
        </div>
      </div>
    );
  }

  renderExitCompleted() {
    const { classes } = this.props;
    const { orderdat } = this.state;
    return (
      <div>
        <h2>ありがとうございました</h2>
        駐車料金を下記の通り承り、出庫手続きが完了しました
        <h3>またのご利用を心よりお待ちしております</h3>
        <div className={classes.textLeft}>
          <this.ImagePaper
            classes={classes}
            src={orderdat.image}
            alt="picture"
          />
          <this.PaymentInfo classes={classes} orderdat={orderdat} />
          <a href={`/i3/${orderdat.ambassadorid}/${orderdat.productid}`}>
            <Paper className={classes.textCenter} elevation={1}>
              <Typography variant="headline" component="h3">
                <Button variant="contained" color="primary">
                  新規で再度駐車登録する
                </Button>
              </Typography>
            </Paper>
          </a>
        </div>
      </div>
    );
  }

  renderExitTerminated() {
    const { classes } = this.props;
    const { orderdat } = this.state;
    return (
      <div>
        <h2>ありがとうございました</h2>
        駐車料金を下記の通り承り、管理者が出庫手続きを完了しました
        <h3>出庫時には出庫処理をお願いいたします</h3>
        <h3>またのご利用を心よりお待ちしております</h3>
        <p>領収書は前払い、後払い精算それぞれ別のメールで届いております</p>
        <p>ご確認ください</p>
        <div className={classes.textLeft}>
          <this.ImagePaper
            classes={classes}
            src={orderdat.image}
            alt="picture"
          />
          <this.PaymentInfo classes={classes} orderdat={orderdat} />
          <a href={`/i3/${orderdat.ambassadorid}/${orderdat.productid}`}>
            <Paper className={classes.textCenter} elevation={1}>
              <Typography variant="headline" component="h3">
                <Button variant="contained" color="primary">
                  新規で再度駐車登録する
                </Button>
              </Typography>
            </Paper>
          </a>
        </div>
      </div>
    );
  }

  // サブスクリプション解約済みの表示用コンポーネント
  renderSubscriptionCanceled() {
    const { classes } = this.props;
    const { orderdat } = this.state;
    const now = new Date();
    const currentPeriodEnd = orderdat.currentPeriodEnd.toDate();
    const isExpired = now > currentPeriodEnd;

    return (
      <div>
        <h2>サブスクリプションは解約済みです</h2>
        <div className={classes.textLeft}>
          <this.ImagePaper
            classes={classes}
            src={orderdat.image}
            alt="picture"
          />
          <this.PaymentInfo classes={classes} orderdat={orderdat} />
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
              {isExpired ? (
                <span>サブスクリプション期間が終了しました。</span>
              ) : (
                <span>
                  サブスクリプションは解約申請済みですが、
                  {currentPeriodEnd.toLocaleDateString()}まで有効です。
                </span>
              )}
            </Typography>

            {!isExpired && (
              <Typography component="p">
                解約申請後も次回更新日まで通常通りご利用いただけます。
                更新日以降は自動的に出庫処理されます。
              </Typography>
            )}

            {isExpired ? (
              <a href={`/i3/${orderdat.ambassadorid}/${orderdat.productid}`}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.marginTop}
                >
                  新規で再度契約する
                </Button>
              </a>
            ) : (
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.marginTop}
                  onClick={this.handleReactivateSubscription}
                >
                  解約をキャンセルする
                </Button>
              </div>
            )}
          </Paper>
        </div>
      </div>
    );
  }

  // サブスクリプション解約キャンセル処理
  handleReactivateSubscription = async () => {
    if (!this.state.orderdat || !this.state.orderdat.subscriptionId) {
      console.error("No subscription ID found");
      return;
    }

    try {
      const reactivateSubscription = httpsCallable(
        this.functions,
        "reactivateSubscription"
      );

      const result = await reactivateSubscription({
        subscriptionId: this.state.orderdat.subscriptionId,
        orderId: this.props.match.params.orderId,
      });

      if (result.data && result.data.success) {
        window.location.reload(true);
      } else {
        console.error("Subscription reactivation failed:", result.data);
      }
    } catch (error) {
      console.error("Error reactivating subscription:", error);
    }
  };

  // キャンセル状態のレンダリング
  renderCancelled() {
    const { classes } = this.props;
    const { orderdat, additionalFee } = this.state;
    switch (orderdat.status) {
      case "prepayment_intent":
        return (
          <div>
            <h2>前払い料金のカード決済がキャンセルされました</h2>
            <div className={classes.textLeft}>
              <this.ImagePaper
                classes={classes}
                src={orderdat.image}
                alt="picture"
              />
              <this.PaymentInfo classes={classes} orderdat={orderdat} />
              <Paper className={classes.root} elevation={1}>
                決済総額：
                {"￥" + (orderdat.price * orderdat.quantity).toLocaleString()}
                <br />
                カード会社にお問い合わせください
              </Paper>
            </div>
            <PurchaseButton
              status="additionalFee_intent"
              productid={this.props.match.params.itemId}
              mode={orderdat.isSubscription ? "subscription" : "payment"}
              feeType={orderdat.feeType}
              price={orderdat.price}
              additionalFee={additionalFee}
              quantity={orderdat.quantity}
              name={orderdat.name}
              image={orderdat.image}
              description={orderdat.description}
              ambassadorid={orderdat.ambassadorid}
              ambassadordisplayName={orderdat.ambassadordisplayName}
              ambassadorEmail={orderdat.ambassadorEmail}
              arrivalExpectedDate={orderdat.arrivalExpectedDate}
              label="再度決済を試みる"
              sessionId={orderdat.sessionId}
              hourlyRate={orderdat.hourlyRate}
              dailyRate={orderdat.dailyRate}
              monthlyRate={orderdat.monthlyRate}
              subscriptionPlanId={orderdat.subscriptionPlanId}
              planId={orderdat.planId}
              isSubscription={orderdat.isSubscription}
            />
          </div>
        );
      case "additionalFee_intent":
        return (
          <div>
            <h2>延長料金のカード決済がキャンセルされました</h2>
            <div className={classes.textLeft}>
              <this.ImagePaper
                classes={classes}
                src={orderdat.image}
                alt="picture"
              />
              <this.PaymentInfo classes={classes} orderdat={orderdat} />
              <Paper className={classes.root} elevation={1}>
                カード決済額：{"￥" + additionalFee.toLocaleString()}
                <br />
                カード会社にお問い合わせください
              </Paper>
            </div>
            <PurchaseButton
              status="additionalFee_intent"
              productid={this.props.match.params.itemId}
              mode="payment"
              feeType={orderdat.feeType}
              price={orderdat.price}
              additionalFee={additionalFee}
              quantity={1}
              name={orderdat.name}
              image={orderdat.image}
              description={orderdat.description}
              ambassadorid={orderdat.ambassadorid}
              ambassadordisplayName={orderdat.ambassadordisplayName}
              ambassadorEmail={orderdat.ambassadorEmail}
              arrivalExpectedDate={orderdat.arrivalExpectedDate}
              label="再度精算して出庫"
              sessionId={orderdat.sessionId}
              hourlyRate={orderdat.hourlyRate}
              dailyRate={orderdat.dailyRate}
              monthlyRate={orderdat.monthlyRate}
              subscriptionPlanId={orderdat.subscriptionPlanId}
              planId={orderdat.planId}
            />
          </div>
        );
      case "subscription_active":
      case "subscription_payment_failed":
        return this.renderSubscriptionActive();
      default:
        return (
          <div>
            <h2>キャンセルされました</h2>
            <div className={classes.textLeft}>
              <Paper className={classes.root} elevation={1}>
                <Typography variant="headline" component="h3">
                  ブラウザの戻るボタンか
                  <br />
                  QRコードを読み取って
                  <br />
                  受付画面に戻ってください
                </Typography>
              </Paper>
            </div>
          </div>
        );
    }
  }

  render() {
    console.log("render");
    const { classes } = this.props;
    const { orderdat, error, additionalFee, diffTimeString } = this.state;
    const success = this.props.match.params.success === "success";
  
    if (error) {
      return <Alert severity="error">{error}</Alert>;
    }
  
    // ステータスごとのコンポーネントマッピング
    const statusComponents = {
      "no_data": () => (
        <div>
          <div className="overflow">
            <Paper className={classes.root} elevation={1}>
              <CircularProgress />
              <Typography component="p">データの取得中です</Typography>
            </Paper>
          </div>
        </div>
      ),
      "prepayment_intent": () => this.renderPaymentIntent(),
      "additionalFee_intent": () => this.renderAdditionalFeeIntent(),
      "subscription_active": () => this.renderSubscriptionActive(),
      "subscription_payment_failed": () => this.renderSubscriptionPaymentFailed(),
      "subscription_canceled": () => this.renderSubscriptionCanceled(), // 新規追加
      "prepayment_completed": () => this.renderPaymentCompleted(),
      "exit_completed": () => this.renderExitCompleted(),
      "exit_terminated": () => this.renderExitTerminated(),
      "default": () => (
        <Alert severity="error">
          予期しない状態です。管理者にお問い合わせください。
        </Alert>
      ),
    };
  
    // オーダーのキャンセル状態の確認と、解約申請済みのサブスクリプションの特別処理
    if (orderdat && orderdat.status !== "no_data") {
      // サブスクリプション解約申請済みの場合
      if (orderdat.isSubscription && orderdat.cancelRequested && 
         (orderdat.status === "subscription_active" || orderdat.status === "prepayment_completed")) {
        return this.renderSubscriptionCanceled();
      }
      
      // success=false の場合はキャンセル状態のレンダリング
      if (!success) {
        return this.renderCancelled();
      }
    }
  
    const StatusComponent = statusComponents[orderdat.status] || statusComponents.default;
    return <StatusComponent />;
  }
}

Checkout.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Checkout);
