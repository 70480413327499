import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import translate from 'deepl';
const DEEPL_API_KEY = '9a93324d-bbfc-5608-01b3-b166abbf9350:fx';
//import { useParams } from 'react-router-dom';

// Firebase v9 imports
import { 
  getAuth, 
  onAuthStateChanged 
} from 'firebase/auth';
import { 
  collection,
  doc, 
  getDoc, 
  getDocs, 
  setDoc, 
  serverTimestamp, 
  where, 
  query, 
  collectionGroup,
  arrayUnion 
} from 'firebase/firestore';
import { db } from '../Firebase'; // Assuming Firebase.js is already updated

// react-slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../slick-item.css"
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddBoxIcon from '@mui/icons-material/AddBox';

import Button from '@material-ui/core/Button'
import EditIcon from '@mui/icons-material/Edit';
import Item from '../components/Item';


// コンポーネントの準備
import FileUpload from '../components/FileUpload.tsx';
import CommonDialog from '../components/CommonDialog2';
import AddOptionDialog from '../components/AddOptionDialog'
import MakeThumbnail from '../components/MakeThumbnail';
import { ExposureNeg1Rounded, PhoneEnabled } from '@material-ui/icons';
import { inflate } from '../components/rawinflate';
import { s2h, h2s } from '../components/Str2Hex';
import { XML } from '../components/ObjTree';
import { idText } from 'typescript';
import { ImageDialog } from '../components/ImageDialog.tsx';

//テスト用URL
//https://qrparking.jp/itemedit/1005002957994634
//https://qrparking.jp/itemedit/?itemid=FDRd20Ik1SucAZwexNz9
//https://qrparking.jp/itemedit/1005002957994634?uid=oO6c95kthlauPLvV9tG4xq3KfEl1
//https://qrparking.jp/itemedit/?itemid=FDRd20Ik1SucAZwexNz9&uid=oO6c95kthlauPLvV9tG4xq3KfEl1

// スタイル
const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: 10,
  },
  textLeft: {
    textAlign: 'left',
  },
  paragraph: {
    marginTop: 10,
    marginBottom: 10,
  },
  marginreftten:{
    marginLeft: 10
  },
  flexbox: {
    display: 'flex',
    'flex-wrap':'wrap',
  },
  optbox: {
    display: 'flex',
    margin: 'auto',    
  },
  bottonstyle: {
    display:'inline-block',
    margin: 10,
    border: 'none',
    '-webkit-filter': 'grayscale(0)',
    '-moz-filter': 'grayscale(0)',
    '-ms-filter': 'grayscale(0)',
    '-o-filter': 'grayscale(0)',
    filter: 'grayscale(0)',
    opacity:1,
    'text-transform': 'none',
  },
  bottonstyleselected: {
    margin: 10,
    display: 'inline-block',
    'border-style': 'solid',
    'border-width': '2px',
    'border-color': 'red',
    '-webkit-filter': 'grayscale(0)',
    '-moz-filter': 'grayscale(0)',
    '-ms-filter': 'grayscale(0)',
    '-o-filter': 'grayscale(0)',
    filter: 'grayscale(0)',
    opacity:1,
  },
  bottonstyledisable: {
    margin: 10,
    border: 'none',
    '-webkit-filter': 'grayscale(100)',
    '-moz-filter': 'grayscale(100)',
    '-ms-filter': 'grayscale(100)',
    '-o-filter': 'grayscale(100)',
    filter: 'grayscale(100)',
    opacity:0.5,
  },
  displaynone: {
    display: 'none'
  },
  FileUpload: {
    margin: 10,
    border: 'none',
    width:'100%',
  },
  tagsbox: {
    width: '100%',
    display: 'flex',
    'flex-wrap': 'wrap',
    'flex-direction': 'row',
    'justify-content': 'space-evenly',
    fontSize: '12px',
  },
  removebottn: {
    'color': 'rgba(200,0,0,1)',
    'width': '14px',
    'font-size':'20px',
    'min-width': '0px',
    'padding': '0px 8px 1px 8px',
    'top': '-10px',
    'left': '0px',
    'position': 'relative',
    'display': 'inline-block',
    'z-index': '1',
  },
  editbottn: {
    'color': 'rgba(200,0,0,1)',
    'width': '14px',
    'font-size':'20px',
    'min-width': '0px',
    'padding': '0px 8px 1px 8px',
    'top': '16px',
    'left': '-20px',
    'position': 'relative',
    'display': 'inline-block',
    'z-index': '1',
  },
  itemaddbottn: {
    'color': 'rgba(0,0,0,0.5)',
    'font-size':'40px',
    'padding': '10px 10px 10px 10px',
    'top': '0px',
    'left': '0px',
    'position': 'relative',
  },
  lineremovebottn: {
    'color': 'rgba(200,0,0,1)',
    'width': '14px',
    'font-size':'20px',
    'min-width': '0px',
    'padding': '0px 8px 1px 8px',
    'top': '16px',
    'left': '0px',
    'position': 'relative',
    'display': 'inline-block',
  },
  lineaddbottn: {
    'color': 'rgba(0,0,0,0.5)',
//    'width': '14px',
    'font-size':'40px',
//    'min-width': '0px',
    'padding': '10px 10px 10px 10px',
    'top': '0px',
    'left': '0px',
    'position': 'relative',
  },
  });

// react-slick
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  centerMode: false,
  centerPadding: '20px',
  fade: false,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

class itemEdit extends React.Component {

  constructor(props) {
    //console.log("constructor::::" + JSON.stringify(props) );
    super(props);
    //this.shareDialogToggle = this.shareDialogToggle.bind(this);
    if (this.props.match) {
      const itemId = this.props.match.params.itemId;
      if (itemId == 'itemid') {
        console.log('this.props.location.search :', this.props.location.search)
        const id = this.props.location.search.split("itemid=")[1].split("&")[0] //"itemid?itemid=xxxxxxxxx&"
        
        // Firebase v9 collection group query
        const itemsQuery = query(collectionGroup(db, 'items'), where("itemId", "==", id));
        
        getDocs(itemsQuery).then((querySnapshot) => {
          if (querySnapshot.docs.length) {
            const dat = querySnapshot.docs[0].data();
            window.location.href = 'https://qrparking.jp/itemedit/' + dat.sourceItemId + this.props.location.search + (dat.domain ? this.props.location.search ? "&domain=" + dat.domain : "?domain=" + dat.domain : "");
            return
          }
          else {
            window.location.href = 'https://qrparking.jp/itemedit/errorid';
            return
          }
        });
      }
    }
    this.state = {
      item: {
        itemId: 'initializeing',
        ambassador: { ambassadordisplayName: '', MarkupRatio: 2 },
        imageModule: { imagePathList: ["/images/Preloader_8.gif"] }, //Preloader_8.gif
        pageModule: { title: "loading", description: "loading" },
        priceModule: { minAmount: { value: 0 }, maxAmount: { value: 0 }, minActivityAmount: { value: 0 }, maxActivityAmount: { value: 0 } },
        skuModule: { productSKUPropertyList: [{ skuPropertyName: "loading", order: 1, skuPropertyId: "", skuPropertyValues: [{ skuPropertyImagePath: "", skuPropertyImageSummPath: "", propertyValueName: "", skuPropertyValueShowOrder: 0 }] }], skuPriceList: [{ skuPropIds: "", skuVal: { skuActivityAmount: { value: 0 } } }] },
        //skuModule: { productSKUPropertyList: [{ skuPropertyName: "loading", skuPropertyValues: [{ propertyValueName: "" }, {selected : false}] }] },
        freightAmount: { shipping_price: "", shipping_info: "", shipping_delivery: "" },
        tags: [],
        status: "edit",
        files: [],
      },
      mainimages: ["/images/Preloader_8.gif"],
      skuAmountvalue: { min: 0, max: 0, match: false, inventory: -1 },
      selectedpropertyValueId: [],
      selectedpropertyValueLst: [],
      editOptDlgIndex: [],
      opt: {},
      taglst: [],
      commDlg: false,
      addOptDlg: false,
      addOptDlgIndex: 0,
      addLinDlg: false,
      status: "edit",
      error: "no_data",
    }
  }

  shareDialogToggle = () => this.props.shareDialogToggle;

  setData = (user, dat) => {
    if ('imageModule' in dat && !dat.error) {
      var mainimages = [];
      var skuAmountvalue = { min: 0, max: 0, match: false, inventory: -1 };
      var item = dat;
      delete item.dt;
      window.__item_source_URL = item.url;
      const taglst = user.tags ? user.tags : [];
      if (!'ambassador' in item) {
        item.ambassador = user;
      }
      if (!dat.imageModule.imagePathListoligin) {
        const list2 = dat.imageModule.imagePathList;
        dat.imageModule.imagePathListoligin = list2;
      }
      dat.imageModule.imagePathList.map(_lst => {
        mainimages.push({ img: _lst, selectstatus: dat.imageModule.pendingimagePathList ? "selected" : "enable" });
      });
      if (dat.imageModule.pendingimagePathList) {
        dat.imageModule.pendingimagePathList.map(_lst => {
          mainimages.push({ img: _lst, selectstatus: "enable" });
        });
      }
      if ('minActivityAmount' in dat.priceModule) {
        skuAmountvalue.min = dat.priceModule.minActivityAmount.value;
      }
      else if ('minAmount' in dat.priceModule) {
        skuAmountvalue.min = dat.priceModule.minAmount.value;
      }
      if ('maxActivityAmount' in dat.priceModule) {
        skuAmountvalue.max = dat.priceModule.maxActivityAmount.value;
      }
      else if ('maxAmount' in dat.priceModule) {
        skuAmountvalue.max = dat.priceModule.maxAmount.value;
      }
      if (skuAmountvalue.min == 0 && skuAmountvalue.max != 0) {
        skuAmountvalue.min = skuAmountvalue.max;
        skuAmountvalue.match = true;
      }
      else if (skuAmountvalue.max == 0 && skuAmountvalue.min != 0) {
        skuAmountvalue.max = skuAmountvalue.min;
        skuAmountvalue.match = true;
      }
      if (!('files' in item)) {
        item.files = [];
      }
      const tags = item.tags;
      const tagstr = tags ? tags.length ? tags.join(' ') : "" : "";
      const savemainimages = JSON.parse(JSON.stringify(mainimages));

      this.setState({
        error: "",
        item: item,
        ambassador: user,
        tagtoUpperCasestr: item.tags ? item.tags.join(' ') : "",
        mainimages: mainimages,
        savemainimages: savemainimages,
        skuAmountvalue: skuAmountvalue,
        taglst: taglst,
        tagstr: tagstr,
        status: item.status ? item.status : "edit",
      });
      window.scrollTo(0, 0);
    } else {
      console.log("setData error data")
      this.setState({
        error: "error-data",
      });
    }
  }
  //データ取得
  //uidで指定したメンバーの値を取得
  getData = async (user, itemId) => {
    let id = '';
    let domain = ';'
    let snapshot = {};
    let dat = {};
    const userDocRef = doc(db, "users", user.uid);
    
    if (itemId == 'new') {
      let newItemdata = this.state.item;
      const newDocRef = doc(collection(userDocRef, "items"));
      id = newDocRef.id;
      newItemdata.itemId = id;
      newItemdata.sourceItemId = id;
      newItemdata.dt = serverTimestamp();
      newItemdata.ambassador = user;
      newItemdata.imageModule = { imagePathList: [] };
      newItemdata.pageModule = { title: "", description: "" };
      newItemdata.skuModule = { productSKUPropertyList: [], skuPriceList: [] }
      newItemdata.domain = "qrparking.jp"
      
      await setDoc(newDocRef, newItemdata)
        .catch(error => {
          console.error('There has been a problem with your adding new data operation:', error);
        });
      
      console.log("getnewdata::id::" + id)
      const url = 'https://qrparking.jp/itemedit/' + id + (this.props.location.search ? this.props.location.search + "&" : "?") + "itemid=" + id + "&domain=qrparking.jp";
      setTimeout("window.location.href = '" + url + "'", 200);
    }
    else if (itemId == 'getdata') {
      if (this.props.location.search && this.props.location.search.split("data=")[1]) {
        const chunkdat = this.props.location.search.split("data=")[1].split("&")[0];
        const index = Number(this.props.location.search.split("index=")[1].split("&")[0]);
        const length = Number(this.props.location.search.split("length=")[1].split("&")[0]);
        const sourceItemId = this.props.location.search.split("sourceItemId=")[1].split("&")[0];
        domain = this.props.location.search.split("domain=")[1].split("&")[0];
        id = s2h(domain + sourceItemId);
        const itemDocRef = doc(collection(userDocRef, "items"), id);
        dat.itemId = id;
        dat.sourceItemId = sourceItemId;
        dat.ambassador = user;
        dat.dt = serverTimestamp();
        
        await setDoc(itemDocRef, {
          ...dat,
          chunkdat: arrayUnion({ dat: chunkdat, index: index }),
          cunklength: length,
          sourceItemId: sourceItemId,
          domain: domain,
        }, { merge: true });
        
        if (index + 1 == length) {
          const _sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
          await _sleep(2000);
          const itemDocRef2 = doc(collection(userDocRef, "items"), id);
          const docSnap = await getDoc(itemDocRef2);
          const dat2 = docSnap.data();
          let chunkdat2 = dat2.chunkdat;
          if (dat2.cunklength <= chunkdat2.length) {
            if (dat2.priceModule && dat2.status && dat2.status != "delete") {
              alert("すでに保存されたデータがあります\n上書きするにはアイテムリストから削除してください")
              window.location.href = 'https://qrparking.jp/itemedit/' + id + "?itemId=" + itemId + "&sourceItemId=" + sourceItemId + "&domain=" + domain;
              return
            }
            let strdat = '';
            let arraydat = [];
            
            chunkdat2.forEach((v) => {
              arraydat[v.index] = v.dat
            });
            
            for (let i = 0; i < arraydat.length; i++) {
              if (arraydat[i]) {
                let tempstr = arraydat[i];
                tempstr = atob(tempstr); // base64デコード
                tempstr = inflate(tempstr); // 復号
                tempstr = decodeURIComponent(tempstr); // UTF8 → UTF16
                strdat += tempstr
              }
              else {
                //console.log("else")
                dat = { error: "missing_chunk" };
              }
            }
            delete dat2.chunkdat;
            delete dat2.cunklength;
            dat = { ...dat2, ...JSON.parse(strdat) };
            if (dat.domain == "taobao.com") {
              let tdat = {};
              tdat.t = dat.pageModule.title.replace(/\s+/g, '');
              tdat.d = dat.pageModule.description.replace(/\s+/g, '');
              tdat.pl = dat.skuModule.productSKUPropertyList.map((e) => {
                return {
                  sn: e.skuPropertyName.replace(/\s+/g, ''),
                  sv: e.skuPropertyValues.map(e2 => e2.propertyValueDisplayName.replace(/\s+/g, '')),
                }
              });
              tdat = { data: tdat }
              //console.log(JSON.stringify(tdat));
              let objTree = new XML.ObjTree();
              const xmldat = objTree.writeXML(tdat);
              //console.log(xmldat);
              //console.log(JSON.stringify(objTree.parseXML(xmldat)));
              const result = await translate({
                free_api: true,
                text: xmldat,
                target_lang: 'JA',
                source_lang: 'ZH',
                auth_key: DEEPL_API_KEY,
                tag_handling: 'xml',
                split_sentences: '1',
                preserve_formatting: '1',
                // All optional parameters available in the official documentation can be defined here as well.
              })
                .catch(error => {
                  console.error(error)
                });
              //console.log(result.data.translations[0].text);
              const tdat2 = objTree.parseXML(result.data.translations[0].text);
              //const tdat2 = JSON.parse(result.data.translations[0].text);
              //console.log(JSON.stringify(tdat2));
              dat.pageModule.title = tdat2.data.t;
              dat.pageModule.description = tdat2.data.d;
              let tmparray = Array.isArray(tdat2.data.pl) ? tdat2.data.pl : [tdat2.data.pl];
              dat.skuModule.productSKUPropertyList = tmparray.map((e, i) => {
                let tmparray = Array.isArray(e.sv) ? e.sv : [e.sv];
                return {
                  isShowTypeColor: dat.skuModule.productSKUPropertyList[i].isShowTypeColor,
                  order: dat.skuModule.productSKUPropertyList[i].order,
                  showType: dat.skuModule.productSKUPropertyList[i].showType,
                  showTypeColor: dat.skuModule.productSKUPropertyList[i].showTypeColor,
                  skuPropertyId: dat.skuModule.productSKUPropertyList[i].skuPropertyId,
                  skuPropertyName: e.sn,
                  skuPropertyValues: tmparray.map((e1, i1) => {
                    return {
                      propertyValueDisplayName: e1,
                      propertyValueId: dat.skuModule.productSKUPropertyList[i].skuPropertyValues[i1].propertyValueId,
                      propertyValueIdLong: dat.skuModule.productSKUPropertyList[i].skuPropertyValues[i1].propertyValueIdLong,
                      propertyValueName: e1,
                      selectstatus: dat.skuModule.productSKUPropertyList[i].skuPropertyValues[i1].selectstatus,
                      skuColorValue: dat.skuModule.productSKUPropertyList[i].skuPropertyValues[i1].skuColorValue,
                      skuPropertyImagePath: dat.skuModule.productSKUPropertyList[i].skuPropertyValues[i1].skuPropertyImagePath,
                      skuPropertyImageSummPath: dat.skuModule.productSKUPropertyList[i].skuPropertyValues[i1].skuPropertyImageSummPath,
                      skuPropertyTips: e1,
                      skuPropertyValueShowOrder: dat.skuModule.productSKUPropertyList[i].skuPropertyValues[i1].skuPropertyValueShowOrder,
                      skuPropertyValueTips: e1,
                    }
                  })
                }
              });
            }
            dat.ambassador = user;
          }
          else {
            //console.log("else")
            alert("データの構成に失敗しました\n再読み込みしてください")
            dat = { error: "missing_chunk" };
          }
        }
        else {
          //console.log("else")
          window.close();
          return
        }
      }
      else {
        dat = { error: "no_data" };
      }
    }
    else {
      //URLパラメーターで"itemid="があればそれを優先
      const itemid2 = this.props.location.search.split("itemid=")[1] ? this.props.location.search.split("itemid=")[1].split("&")[0] : "";
      if (itemid2) {
        const itemDocRef = doc(collection(userDocRef, "items"), itemid2);
        const docSnap = await getDoc(itemDocRef);
        if (docSnap.exists()) {
          dat = docSnap.data();
        }
        else {
          dat = { error: "itemid Does not exist" }
        }
      }
      else {
        //URLパラメーターで"domain="があればそれを優先
        domain = this.props.location.search.split("domain=")[1] ? this.props.location.search.split("domain=")[1].split("&")[0] : "";
        if (!domain) {
          let itemid2 = h2s(itemId);
          if (itemid2.indexOf("taobao.com") != -1) {
            domain = "taobao.com";
          }
          else if (itemid2.indexOf("aliexpress.com") != -1) {
            domain = "aliexpress.com";
          }
          else if (itemid2.indexOf("qrparking.jp") != -1) {
            domain = "qrparking.jp";
          }
        }
        
        const itemsCollectionRef = collection(userDocRef, "items");
        const itemsQuery = query(itemsCollectionRef, where("sourceItemId", "==", itemId));
        const querySnapshot = await getDocs(itemsQuery);
        
        //ドキュメントの存在確認
        if (querySnapshot.docs.length) {
          for (let i = 0; i < querySnapshot.docs.length; i++) {
            dat = querySnapshot.docs[i].data();
            if (dat.domain && dat.domain == domain) {
              dat.itemId = querySnapshot.docs[i].id;
              break
            }
            if ((!dat.domain && !domain) || (!dat.domain || querySnapshot.docs.length == 1)) {
              if (dat.itemId == dat.sourceItemId) {
                dat.domain = "qrparking.jp";
              }
              else {
                dat.domain = "aliexpress.com";
              }
              dat.itemId = querySnapshot.docs[i].id;
              break
            }
            if (i == querySnapshot.docs.length) {
              dat = { error: "no_item_in_database" };
            }
          }
        }
        else {
          dat = { error: "no_itemId_in_database" };
        }
        if (!Object.keys(dat).length) {
          dat = { error: "no_data" }
        }
      }
    }
    this.setData(user, dat)
  }
  //値を取得
  // componentDidMount メソッド内部のコード更新
  componentDidMount = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (this.props.match) {
        if (this.props.location.search && this.props.location.search.split("uid=")[1]) {
          const uid = this.props.location.search.split("uid=")[1].split("&")[0];
        
          if (uid) {
            const userDocRef = doc(db, "users", uid);
            getDoc(userDocRef).then((docSnap) => {
              if (docSnap.exists()) {
                const ambassador = docSnap.data();
                if (ambassador.uid == this.props.userdata.uid || this.props.userdata.status == "super") {
                  this.getData(ambassador, this.props.match.params.itemId);
                }
              }
            });
          }
          else {
            this.getData(this.props.userdata, this.props.match.params.itemId);
          }
        }
        else {
          this.getData(this.props.userdata, this.props.match.params.itemId);
        }
      }
      else {
        console.log("not props.computedMatch::", this.props);
      }
    });
  }

  // setTagList メソッドの更新
  setTagList = () => {
    const user = this.state.item.ambassador;
    const userDocRef = doc(db, "users", user.uid);
    const taglst = this.state.taglst;
    const removals = this.state.tagstr.split(' ');
    const newtags = taglst.filter(function (v) {
      return !removals.includes(v);
    });
  
    setDoc(userDocRef, { tags: newtags }, { merge: true })
      .then(() => this.setState({ commDlg: false }))
      .then(() => { setTimeout("window.location.reload(true)", 200) });
  }

  // submitonClick メソッドの更新
  submitonClick = () => {
    const user = this.state.ambassador;
    const itemId = this.state.item.itemId;
    const userDocRef = doc(db, "users", user.uid);
    const itemDocRef = doc(collection(userDocRef, "items"), itemId);
    let dat = this.state.item;
    let tags = [];
    let list = [];
    let list2 = [];
    const skuAmountvalue = this.chkskuPriceList([], dat);

    if (this.state.tagstr) {
      tags = this.state.tagstr.split(' ');
    }
  
    let taglst = tags.concat(this.state.taglst);
    let set = new Set(taglst);
    taglst = [...set];
    taglst = taglst.filter(Boolean).sort();
    console.log("tags: ", taglst);
  
    // ユーザーのタグを更新
    setDoc(userDocRef, { tags: taglst }, { merge: true });

    this.state.savemainimages.map(e => {
      if (e.selectstatus == "selected") {
        list.push(e.img);
      }
      else {
        list2.push(e.img);
      }
    });

    if (dat.skuModule.productSKUPropertyList) {
      dat.skuModule.productSKUPropertyList.map(_lst => {
        _lst.skuPropertyValues.map(_lst2 => {
          _lst2.selectstatus = 'enable';
        });
      });
    }
  
    dat.imageModule.imagePathList = list;
    dat.imageModule.pendingimagePathList = list2;
    dat.ambassador = user;
    dat.ambassador.ambassadordisplayName = document.getElementsByName('ambassadordisplayName')[0].value;
    dat.pageModule.title = document.getElementsByName('title')[0].value;
    dat.pageModule.description = document.getElementsByName('description')[0].value;
  
    if (skuAmountvalue.min) {
      dat.priceModule.minActivityAmount = {
        currency: "JPY",
        formatedAmount: "￥" + skuAmountvalue.min.toLocaleString(),
        value: skuAmountvalue.min
      };
    }
  
    if (skuAmountvalue.max) {
      dat.priceModule.maxActivityAmount = {
        currency: "JPY",
        formatedAmount: "￥" + skuAmountvalue.max.toLocaleString(),
        value: skuAmountvalue.max
      };
    }
  
    dat.memo = document.getElementsByName('memo')[0].value;
    tags = tags.filter(Boolean).sort();
    dat.tags = tags;
    dat.status = this.state.status; //delete edit available
    dat.editby = this.props.userdata;
    dat.dt = serverTimestamp();
  
    // データを保存
    setDoc(itemDocRef, dat)
      .then(() => {
        const href = '"https://qrparking.jp/itemedit/' + dat.sourceItemId + this.props.location.search + ((dat.domain && !this.props.location.search.indexOf('domain=', 0)) ? this.props.location.search ? '&domain=' + dat.domain : '?domain=' + dat.domain : '') + '"';
        setTimeout('window.location.href=' + href, 200);
      });
  }
}

// Material-ui関連
itemEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};


// Material-uiのテーマ設定
export default withStyles(styles, { withTheme: true })(itemEdit);