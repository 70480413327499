import React, { Component } from 'react';

// コンテナ読み込み

import ResponsiveDrawer from './containers/ResponsiveDrawer';
import RouteRelatedBottomNavigation from './containers/RouteRelatedBottomNavigation';
import Notification from './containers/Notification';
import Info from './containers/Info';
import Settings from './containers/Settings';
import PrivacyPolicy from './containers/PrivacyPolicy';
import SpecifiedCommercialTransactionAct from './containers/SpecifiedCommercialTransactionAct';
import QRCodeSignOrder from './containers/QRCodeSignOrder';
import ItemList from './containers/ItemList';
import OrderList from './containers/OrderList';
import ItemEdit from './containers/ItemEdit2';
import ItemEdit3 from './containers/ItemEdit3';
//import ItemEdit2 from './containers/ItemEdit2';
import I from './containers/I';
import I3 from './containers/I3';
//import I2 from './containers/I2';
import L from './containers/L';
import Checkout from './containers/Checkout';
import Myfav from './containers/Myfav';
import SignInOrUp from './containers/SignInOrUp';
import SignUp from './containers/SignUp';
import ProductCatalog from './containers/ProductCatalog'
import MailSignUp from './containers/MailSignUp';
import Home from './containers/Home';
import Home2 from './containers/Home2';
import TermsOfService from './containers/TermsOfService';
import RegistrationCompletion from './containers/RegistrationCompletion';
import DataInfo from './containers/DataInfo';
import AgencyService from './containers/AgencyService'
import PricingSettings from './containers/PricingSettings'
import CheckList from './containers/CheckList'
import Troubleshooting from './containers/Troubleshooting'
import ToDoList from './containers/ToDoList'
import MailForm from './containers/MailForm'
import LogoutPage from './containers/LogoutPage'
import AuthRoute from './AuthRoute';
import ParkingTermsOfUse from './containers/ParkingTermsOfUse'

import Test from './containers/Test';

// コンポーネント読み込み
import WrapMainContent from './components/WrapMainContent'

// 共通スタイル読み込み
import './App.css';

// Route関連
import { Route, Switch, useLocation} from 'react-router-dom';

// 不明なRouteは全てNotFound
const NotFound = () => {
  return(
    <h2>ページが見つかりません</h2>
  )
}


class App extends Component {
// フッターを一時削除した  </ResponsiveDrawer>直下　<RouteRelatedBottomNavigation/>
  render() {
    //const location = useLocation();
    //            {uId ? uId[1]:''}
    //const uId = location.pathname.match(/i\/(.*)\//);
    //const Path = location.pathname;
    //const uId = Path.match(/i\/(.*)\//);

    return (
      <div className="App">
        <Notification />
        <ResponsiveDrawer className="ResponsiveDrawer">
          <Switch>
            <Route exact path="/" component={WrapMainContent(Home)} />
            <Route exact path="/Home" component={WrapMainContent(Home)} />
            <Route exact path="/Home2" component={WrapMainContent(Home2)} />
            <Route exact path="/TermsOfService" component={WrapMainContent(TermsOfService)} />
            <Route exact path="/QRCodeSignOrder" component={WrapMainContent(QRCodeSignOrder)} />
            <Route exact path="/info" component={WrapMainContent(Info)} />
            <Route exact path="/settings" component={WrapMainContent(Settings)} />
            <Route exact path="/privacypolicy" component={WrapMainContent(PrivacyPolicy)} />
            <Route exact path="/specifiedcommercialtransactionact" component={WrapMainContent(SpecifiedCommercialTransactionAct)} />
            <Route exact path="/myfav" component={WrapMainContent(Myfav)} />
            <Route exact path="/signup" component={WrapMainContent(SignUp)} />
            <Route exact path="/signinorup" component={WrapMainContent(SignInOrUp)} />
            <Route exact path="/LogoutPage" component={WrapMainContent(LogoutPage)} />
            <Route exact path="/registrationcompletion/:Status/:UserId" component={WrapMainContent(RegistrationCompletion)} />
            <Route exact path="/datainfo" component={WrapMainContent(DataInfo)} />
            <Route exact path="/AgencyService" component={WrapMainContent(AgencyService)} />
            <Route exact path="/PricingSettings" component={WrapMainContent(PricingSettings)} />
            <Route exact path="/CheckList" component={WrapMainContent(CheckList)} />
            <Route exact path="/Troubleshooting" component={WrapMainContent(Troubleshooting)} />
            <Route exact path="/ToDoList" component={WrapMainContent(ToDoList)} />
            <Route exact path="/MailForm/:Style" component={WrapMainContent(MailForm)} />
            <Route exact path="/ProductCatalog" component={WrapMainContent(ProductCatalog)} />
            <Route exact path="/ParkingTermsOfUse" component={WrapMainContent(ParkingTermsOfUse)} />
            <Route exact path="/xxx__test__xxx" component={WrapMainContent(Test)} />
            <Route path="/mailsignup/:ref" component={WrapMainContent(MailSignUp)} />
            <Route path="/i/:userId/:itemId" component={WrapMainContent(I)} />
            <Route path="/i3/:userId/:itemId" component={WrapMainContent(I3)} />
            <Route path="/ParkingTermsOfUse/:userId/:itemId" component={WrapMainContent(ParkingTermsOfUse)} />
            <Route path="/l/:userId" component={WrapMainContent(L)} />
            <Route path="/checkout/:success/:orderId" component={WrapMainContent(Checkout)} />
            <AuthRoute path="/itemlist" component={WrapMainContent(ItemList)} />
            <AuthRoute path="/orderlist" component={WrapMainContent(OrderList)} />
            <AuthRoute path="/itemedit/:itemId" component={WrapMainContent(ItemEdit)} />
            <AuthRoute path="/itemedit3/:itemId" component={WrapMainContent(ItemEdit3)} />
            <Route component={WrapMainContent(NotFound)} />
          </Switch>
        </ResponsiveDrawer >
      </div>
    );
  }
}

// React-Router情報取得
export default App;
