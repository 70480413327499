import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import Divider from '@material-ui/core/Divider';
import CommonDialog from './CommonDialog';

// Firebase v9 imports
import { getFirestore, doc, setDoc } from 'firebase/firestore';

const styles = {
  // Cards
  card: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
    marginBottom: '10px',
    overflow: 'hidden',
    textAlign: 'left',
  },
  empty: {
    height: 0,
    margin: 0,
    padding: 0,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  contentRow: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: 10,
  },
  row: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  detailText: {
    fontSize: 14,
  },
  dateText: {
    fontSize: 14,
    color: '#555',
  },
  priceText: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  statusText: {
    fontSize: 14,
    color: '#1976d2',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
  },
  infoButton: {
    backgroundColor: '#1976d2',
    color: 'white',
  },
  deleteButton: {
    backgroundColor: '#d32f2f',
    color: 'white',
  },
  divider: {
    margin: '10px 0',
  }
};

function OrderItem(props) {
  const {
    empty,
    id,
    productname,
    timestamp,
    checkoutTime,
    description,
    price,
    quantity,
    status,
    isSubscription,
    arrivalExpectedDate,
    cancelRequested,
    currentPeriodEnd,
    classes,
    onCheckout,
    onCancelSubscription,
    onReactivateSubscription,
    onTerminate,
    sessionId
  } = props;

  const [commDlg, setCommDlg] = useState(false);

  // ステータス表示用のテキストマッピング
  const getStatusDisplay = (status) => {
    const statusMap = {
      'prepayment_completed': '入庫中',
      'exit_completed': '出庫完了',
      'exit_terminated': '強制出庫完了',
      'subscription_active': 'サブスクリプション有効',
      'subscription_past_due': '支払い遅延中',
      'subscription_payment_failed': '支払い失敗',
      'subscription_canceled': '解約済み'
    };
    return statusMap[status] || status;
  };

  // 日時のフォーマット
  const formatDate = (firestoreTimestamp) => {
    if (!firestoreTimestamp) return '不明';
    const date = firestoreTimestamp.toDate ? firestoreTimestamp.toDate() : new Date(firestoreTimestamp);
    return date.toLocaleString('ja-JP');
  };

  // 出庫予定日時から日時部分だけを抽出
  const extractExpectedDate = (arrivalExpectedDate) => {
    if (!arrivalExpectedDate) return '不明';
    const match = arrivalExpectedDate.match(/出庫予定時間：\s*(.+)$/);
    return match ? match[1] : arrivalExpectedDate;
  };

  // 強制出庫ハンドラー
  const handleForceCheckout = async () => {
    if (onCheckout) {
      const success = await onCheckout(id);
      if (success) {
        setCommDlg(false);
      }
    } else {
      // 古い方法のフォールバック
      try {
        const db = getFirestore();
        const orderRef = doc(db, 'order', id);
        await setDoc(orderRef, { status: "exit_terminated" }, { merge: true });
        setCommDlg(false);
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    }
  };

  // 詳細ページへのリンク
  const getDetailUrl = () => {
    return `https://qrparking.jp/checkout/success/${sessionId || id}`;
  };

  // 空のカードの場合
  if (empty === "true") {
    return <Card className={classes.empty}></Card>;
  }

  // 日時の計算
  const timestampFormatted = timestamp ? formatDate(timestamp) : '不明';
  const checkoutTimeFormatted = checkoutTime ? formatDate(checkoutTime) : null;
  const expectedDateFormatted = arrivalExpectedDate ? extractExpectedDate(arrivalExpectedDate) : '不明';
  
  // サブスクリプション情報
  const isActiveSubscription = isSubscription && status.includes('subscription');
  const subscriptionStatus = cancelRequested ? '解約予定' : getStatusDisplay(status);
  const periodEndFormatted = currentPeriodEnd ? formatDate(currentPeriodEnd) : '不明';

  return (
    <Card className={classes.card}>
      <CommonDialog
        msg={"強制出庫しますか？"}
        isOpen={commDlg}
        doYes={handleForceCheckout}
        doNo={() => {setCommDlg(false)}}
      />
      
      <CardContent>
        {/* 駐車場名 */}
        <Typography className={classes.title} variant="h6">
          {productname || '駐車場名不明'}
        </Typography>
        
        <div className={classes.contentRow}>
          {/* 入庫日時とプラン情報 */}
          <div className={classes.row}>
            <Typography className={classes.dateText}>
              入庫: {timestampFormatted}
            </Typography>
            <Typography className={classes.priceText}>
              {isSubscription ? 'サブスクリプション' : `${description || ''} ¥${price?.toLocaleString() || 0}`}
              {!isSubscription && quantity > 1 ? ` × ${quantity}` : ''}
            </Typography>
          </div>
          
          {/* 出庫予定日時とステータス */}
          <div className={classes.row}>
            <Typography className={classes.dateText}>
              {checkoutTimeFormatted ? `出庫: ${checkoutTimeFormatted}` : `出庫予定: ${expectedDateFormatted}`}
            </Typography>
            <Typography className={classes.statusText}>
              {isActiveSubscription ? subscriptionStatus : getStatusDisplay(status)}
              {isActiveSubscription && cancelRequested ? `（${periodEndFormatted}まで）` : ''}
            </Typography>
          </div>
        </div>
      </CardContent>
      
      <Divider className={classes.divider} />
      
      <CardActions>
        <div className={classes.buttonContainer}>
          {/* アクティブな駐車やサブスクリプションの場合だけ強制出庫ボタンを表示 */}
          {(status === 'prepayment_completed' || 
            (isSubscription && (status === 'subscription_active' || status === 'prepayment_completed'))) && (
            <Button 
              variant="contained" 
              size="small" 
              className={classes.deleteButton}
              startIcon={<DeleteIcon />}
              onClick={() => setCommDlg(true)}
            >
              強制出庫
            </Button>
          )}
          
          {/* 詳細ボタン */}
          <Button 
            variant="contained" 
            size="small" 
            className={classes.infoButton}
            startIcon={<InfoIcon />}
            href={getDetailUrl()}
            target="_blank"
          >
            詳細
          </Button>
        </div>
      </CardActions>
    </Card>
  );
}

OrderItem.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  productname: PropTypes.string,
  timestamp: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  checkoutTime: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  description: PropTypes.string,
  price: PropTypes.number,
  quantity: PropTypes.number,
  status: PropTypes.string,
  isSubscription: PropTypes.bool,
  arrivalExpectedDate: PropTypes.string,
  cancelRequested: PropTypes.bool,
  currentPeriodEnd: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  onCheckout: PropTypes.func,
  onCancelSubscription: PropTypes.func,
  onReactivateSubscription: PropTypes.func,
  onTerminate: PropTypes.func,
  sessionId: PropTypes.string,
  empty: PropTypes.string
};

export default withStyles(styles)(OrderItem);