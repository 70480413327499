// components/SubscriptionManagement.js の改善 - Cloud Functionsに一本化

import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseConfig } from "../Firebase";
import {
  Button,
  Paper,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const SubscriptionManagement = ({ customerId, classes, onUpdate }) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [processingSubscription, setProcessingSubscription] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [reactivateDialogOpen, setReactivateDialogOpen] = useState(false);

  const functions = getFunctions(initializeApp(firebaseConfig), "asia-northeast2");

  // サブスクリプション情報の取得 - Cloud Functionsから取得
  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        setLoading(true);
        
        const getSubscriptions = httpsCallable(functions, "getSubscriptions");
        const result = await getSubscriptions({ customerId });
        
        if (result.data) {
          setSubscriptions(result.data);
        } else {
          setSubscriptions([]);
        }
        
        setLoading(false);
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
        setError("サブスクリプション情報の取得に失敗しました");
        setLoading(false);
      }
    };

    if (customerId) {
      fetchSubscriptions();
    }
  }, [customerId, functions]);

  // サブスクリプションキャンセル処理
  const handleCancelSubscription = async () => {
    if (!selectedSubscription) return;
    
    setProcessingSubscription(selectedSubscription.id);
    setCancelDialogOpen(false);
    
    try {
      const cancelSubscription = httpsCallable(functions, "cancelSubscription");
      const result = await cancelSubscription({
        subscriptionId: selectedSubscription.subscriptionId,
        orderId: selectedSubscription.id
      });
      
      if (result.data && result.data.success) {
        // 成功メッセージ表示
        setConfirmDialogOpen(true);
        
        // 最新の情報を取得して更新
        const getSubscriptions = httpsCallable(functions, "getSubscriptions");
        const updatedResult = await getSubscriptions({ customerId });
        
        if (updatedResult.data) {
          setSubscriptions(updatedResult.data);
        }
        
        // 親コンポーネントに変更を通知
        if (typeof onUpdate === 'function') {
          onUpdate();
        }
      } else {
        setError("サブスクリプションのキャンセルに失敗しました");
      }
    } catch (error) {
      console.error("Error canceling subscription:", error);
      setError(`サブスクリプションのキャンセルに失敗しました: ${error.message}`);
    } finally {
      setProcessingSubscription(null);
    }
  };

  // サブスクリプション再有効化処理
  const handleReactivateSubscription = async () => {
    if (!selectedSubscription) return;
    
    setProcessingSubscription(selectedSubscription.id);
    setReactivateDialogOpen(false);
    
    try {
      const reactivateSubscription = httpsCallable(functions, "reactivateSubscription");
      const result = await reactivateSubscription({
        subscriptionId: selectedSubscription.subscriptionId,
        orderId: selectedSubscription.id
      });
      
      if (result.data && result.data.success) {
        // 成功メッセージ表示
        setConfirmDialogOpen(true);
        
        // 最新の情報を取得して更新
        const getSubscriptions = httpsCallable(functions, "getSubscriptions");
        const updatedResult = await getSubscriptions({ customerId });
        
        if (updatedResult.data) {
          setSubscriptions(updatedResult.data);
        }
        
        // 親コンポーネントに変更を通知
        if (typeof onUpdate === 'function') {
          onUpdate();
        }
      } else {
        setError("サブスクリプションの再有効化に失敗しました");
      }
    } catch (error) {
      console.error("Error reactivating subscription:", error);
      setError(`サブスクリプションの再有効化に失敗しました: ${error.message}`);
    } finally {
      setProcessingSubscription(null);
    }
  };

  // 日付を安全にフォーマットする関数
  // formatDate関数の改善
  const formatDate = (dateField) => {
    if (!dateField) return "不明";
    
    try {
      // Firestoreのタイムスタンプオブジェクトの場合
      if (dateField && typeof dateField.toDate === 'function') {
        return dateField.toDate().toLocaleDateString("ja-JP");
      }
      
      // タイムスタンプ値（秒またはミリ秒）の場合
      if (typeof dateField === 'number') {
        // ミリ秒単位でない場合は1000倍する（Firestoreは秒単位）
        const timestamp = dateField > 10000000000 ? dateField : dateField * 1000;
        return new Date(timestamp).toLocaleDateString("ja-JP");
      }
      
      // ISO文字列の場合
      if (typeof dateField === 'string') {
        return new Date(dateField).toLocaleDateString("ja-JP");
      }
      
      // オブジェクトの場合（seconds/nanoseconds形式など）
      if (typeof dateField === 'object' && dateField.seconds) {
        return new Date(dateField.seconds * 1000).toLocaleDateString("ja-JP");
      }
      
      // 最終的にオブジェクトをJSONに変換して調査ログを残す
      console.log("Unknown date format:", JSON.stringify(dateField));
      return "不明";
    } catch (error) {
      console.error("Error formatting date:", error, dateField);
      return "不明";
    }
  };

  // サブスクリプションの状態表示
  const getSubscriptionStatusText = (subscription) => {
    if (!subscription) return "";
    
    if (subscription.cancelRequested) {
      return `解約予定: ${formatDate(subscription.currentPeriodEnd)}まで有効`;
    }
    
    switch (subscription.status) {
      case "subscription_active":
        return "有効";
      case "subscription_payment_failed":
        return "支払い失敗";
      case "subscription_canceled":
        return "解約済み";
      case "subscription_unpaid":
        return "初回未完了";
      case "subscription_canceled":
        return "初回支払期限切れキャンセル";
      default:
        return subscription.status || "不明";
    }
  };

  // ダイアログを開く
  const openCancelDialog = (subscription) => {
    setSelectedSubscription(subscription);
    setCancelDialogOpen(true);
  };
  
  // 再有効化ダイアログを開く
  const openReactivateDialog = (subscription) => {
    setSelectedSubscription(subscription);
    setReactivateDialogOpen(true);
  };

  if (loading) {
    return (
      <Paper className={classes.root} elevation={1}>
        <Typography variant="headline" component="h3">
          サブスクリプション管理
        </Typography>
        <div style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
          <CircularProgress />
        </div>
      </Paper>
    );
  }

  if (error) {
    return (
      <Paper className={classes.root} elevation={1}>
        <Typography variant="headline" component="h3">
          サブスクリプション管理
        </Typography>
        <Alert severity="error">{error}</Alert>
      </Paper>
    );
  }

  if (subscriptions.length === 0) {
    return (
      <Paper className={classes.root} elevation={1}>
        <Typography variant="headline" component="h3">
          サブスクリプション管理
        </Typography>
        <Typography component="p">
          アクティブなサブスクリプションはありません。
        </Typography>
      </Paper>
    );
  }

  return (
    <Paper className={classes.root} elevation={1}>
      <Typography variant="headline" component="h3">
        サブスクリプション管理
      </Typography>
      
      {subscriptions.map((subscription) => (
        <Paper key={subscription.id} style={{ padding: "15px", margin: "10px 0" }} elevation={2}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            {subscription.productname || "駐車場サブスクリプション"}
          </Typography>
          
          <Typography component="p">
            ステータス: {getSubscriptionStatusText(subscription)}
          </Typography>
          
          {subscription.currentPeriodStart && subscription.currentPeriodEnd && (
            <Typography component="p">
              契約期間: {formatDate(subscription.currentPeriodStart)} 
              ～ {formatDate(subscription.currentPeriodEnd)}
            </Typography>
          )}
          
          <Typography component="p">
            月額: ￥{subscription.price ? subscription.price.toLocaleString() : "不明"}
          </Typography>
          
          {processingSubscription === subscription.id ? (
            <div style={{ display: "flex", justifyContent: "center", padding: "10px" }}>
              <CircularProgress size={24} />
            </div>
          ) : (
            <div style={{ marginTop: "10px" }}>
              {!subscription.cancelRequested && 
                subscription.status !== "subscription_canceled" && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => openCancelDialog(subscription)}
                >
                  解約する
                </Button>
              )}
              
              {subscription.cancelRequested && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => openReactivateDialog(subscription)}
                  style={{ marginLeft: "10px" }}
                >
                  解約をキャンセルする
                </Button>
              )}
            </div>
          )}
        </Paper>
      ))}
      
      {/* 解約確認ダイアログ */}
      <Dialog
        open={cancelDialogOpen}
        onClose={() => setCancelDialogOpen(false)}
      >
        <DialogTitle>サブスクリプション解約の確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            サブスクリプションを解約しますか？<br />
            解約した場合でも、現在の契約期間（
            {selectedSubscription 
              ? formatDate(selectedSubscription.currentPeriodEnd)
              : ""}
            まで）はご利用いただけます。<br /><br />
            <strong>期間終了後は自動的に出庫処理され、駐車することができなくなります。</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCancelDialogOpen(false)} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleCancelSubscription} color="secondary" autoFocus>
            解約する
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* 再有効化確認ダイアログ */}
      <Dialog
        open={reactivateDialogOpen}
        onClose={() => setReactivateDialogOpen(false)}
      >
        <DialogTitle>解約キャンセルの確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            サブスクリプションの解約をキャンセルしますか？<br />
            キャンセルすると、期間終了後も自動的に更新され続けます。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setReactivateDialogOpen(false)} color="secondary">
            閉じる
          </Button>
          <Button onClick={handleReactivateSubscription} color="primary" autoFocus>
            解約をキャンセルする
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* 完了確認ダイアログ */}
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogTitle>処理完了</DialogTitle>
        <DialogContent>
          <DialogContentText>
            処理が完了しました。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)} color="primary" autoFocus>
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default SubscriptionManagement;