import React from "react";
import PropTypes from "prop-types";

// Firebase v9モジュラー形式でのインポート
import { getApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

import StripeConnectButton from "../components/StripeConnectButton";

import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import Button from "@material-ui/core/Button";
import { Alert } from "@material-ui/lab";

import { FiLogOut } from "react-icons/fi";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: 10,
  },
  twittercolor: {
    "background-color": "#55acee",
  },
  providerbotton: {
    margin: "10px auto",
    "font-weight": "500",
    "font-size": "14px",
    "font-family": "Roboto,Helvetica,Arial,sans-serif",
    height: "auto",
    "line-height": "normal",
    "max-width": "220px",
    "min-height": "40px",
    padding: "8px 16px",
    "text-align": "left",
    border: "none",
    "border-radius": "3",
    "box-shadow":
      "0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)",
    transition:
      "box-shadow .2s cubic-bezier(.4,0,1,1),background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1)",
    "will-change": "box-shadow",
  },
  flex: {
    width: "100%",
    "flex-direction": "row",
    display: "flex",
  },
  providericon: {
    height: "18px",
    width: "18px",
  },
  textLeft: {
    textAlign: "left",
  },
  textCenter: {
    textAlign: "center",
  },
  img90: {
    width: "90%",
  },
  img70: {
    width: '70%',
  },
  img60: {
    width: "60%",
  },
  img100: {
    width: "100%",
  },
  paragraph: {
    marginTop: 10,
    marginBottom: 10,
  },
});

class SignInOrUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      warning: "現在ログインしていません",
      info: "",
      success: "", //未使用
      urlcopy: false,
      user: null,
    };
  }

  componentDidMount = () => {
    const app = getApp();
    const auth = getAuth(app);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.setState({
          user: user,
          info: user.displayName ? user.displayName:user.uid
            + "でログインしています",
          warning: "",
        });
        console.log("log in");
      } else {
        console.log("not log in");
      }
    });
  };

  copyUrlToClipboard = () => {
    const url = window.location.href;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        this.setState({
          urlcopy: true,
        });
      })
      .catch((error) => {
        console.error("URLのコピーに失敗しました:", error);
      });
  };

  logout = () => {
    const app = getApp();
    const auth = getAuth(app);
    signOut(auth)
      .then(() => {
        console.log("User signed out successfully");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className="container">
        <div>
          <div className={classes.root}>
            <Typography variant="headline" component="h2">
              無料アカウント作成
            </Typography>
            <br />
            {this.state.error ? (
              <Alert severity="error">{this.state.error}</Alert>
            ) : (
              ""
            )}
            {this.state.warning ? (
              <Alert severity="warning">{this.state.warning}</Alert>
            ) : (
              ""
            )}
            {this.state.info ? (
              <Alert severity="info">{this.state.info}</Alert>
            ) : (
              ""
            )}
            {this.state.success ? (
              <Alert severity="success">{this.state.success}</Alert>
            ) : (
              ""
            )}

            <Paper className={classes.root} elevation={1}>
              <Typography variant="headline" component="h4">
                QRパーキングの利用開始にはStripeの無料アカウントを作成していただく必要があります
              </Typography>
              <br />
              <Typography variant="headline" component="p">
                Stripeは世界の企業が利用するカード決済会社で、QRパーキングのもとでカードの決済を行い
                <br />
                決済金のお客様への振込、税務情報の取得など金銭に関わる業務をQRパーキングの委託で行います
                <br />
                QRパーキングの駐車場登録や料金設定にもStripeのアカウントを使用します
                <br />
              </Typography>
              <br />
              <Typography variant="headline" component="h4">
                <font color="red">Stripeに登録する費用はもちろん無料です</font>
              </Typography>
              <br />
              <img className={classes.img60}
                src="/images/siteimage/flowchart.jpg"
              />
              <br />
              <Typography variant="headline" component="p">
                Stripeがお客様の金銭の管理を代行するのでお客様の身分証明書や口座情報はStripeだけが保管しQRパーキングは保持しません
                <br />
                QRパーキングが保持するのはお客様のお名前、電話番号、メールアドレス、駐車場料金、利用履歴などです
                <br />
                <a href={"/datainfo"} target={"_blank"}>
                  詳しくはこちらを御覧ください
                </a>
              </Typography>
            </Paper>

            <Paper className={classes.root} elevation={1}>
              <Typography variant="headline" component="h3">
                Stripeとは？
              </Typography>
              <br />
              <Typography variant="headline" component="h4">
                amazon,google,zoom,
                <br />
                KDDI,WOWOW,NIKKEIなど
                <br />
                大手IT企業が料金決済に利用する世界最大級の決済プラットフォームです
                <br />
                カードの決済、決済金の入金、税務書類の作成など金銭に関わる業務をQRパーキングの委託で行います
                <br />
                カード決済金は一週間で指定口座に振り込まれます
                <br />
                <a href="https://stripe.com/jp" target="_blank">Stripeについてもっと知りたい場合はこちら＞＞</a>
              </Typography>
            </Paper>

            <Paper className={classes.root} elevation={1}>
              <Typography variant="headline" component="h3">
                スマートフォンからの
                <br />
                登録をおすすめします
              </Typography>
              <br />
              <Typography variant="headline" component="p">
                登録には携帯電話による認証や身分証明書（マイナンバーカード・免許証）の画像提供などが必要ですのでスマートフォンからの登録が便利です
                <br />
              </Typography>

              <Typography variant="headline" component="h3">
                スマートフォンでこのページをご覧の場合はそのまま下をご覧ください
              </Typography>
              <Typography variant="headline" component="p">
                <br />
                <img
                  src="/images/siteimage/signinQRcode.png"
                  className={classes.img70}
                />
                <br />
                メールやLINEでこのページのURLをスマートフォンに送るには
                <br />
                <button onClick={this.copyUrlToClipboard}>
                  クリックしてURLをコピー
                </button>
                <br />
              </Typography>
            </Paper>

            <Paper className={classes.root} elevation={1}>
              <Typography variant="headline" component="h3">
                登録の前に準備をしておくとスムースです
              </Typography>
              <Typography variant="headline" component="p">
                <br />
                アカウントの登録には
                <br />
                ・メールアドレス
                <br />
                ・携帯電話番号
                <br />
                ・身分証明書
                <br />
                ・銀行の口座情報
                <br />
                が必要です。
                <br />
                途中で中断してもこのページからいつでも続きを再スタートできます
              </Typography>
            </Paper>

            <Paper className={classes.root} elevation={1}>
              <Typography variant="headline" component="h3">
                登録の途中Google認証アプリを使って使用するように求められますが
              </Typography>
              <br />
              <Paper className={classes.root} elevation={1}>
                <img
                  src="/images/siteimage/ninsyo.jpg"
                  className={classes.img70}
                />
              </Paper>
              <br />
              <Typography variant="headline" component="h3">
                やり方がわからない場合は迷わず「携帯電話」をタップしてください
              </Typography>
            </Paper>

            <Paper className={classes.root} elevation={1}>
              <Typography variant="headline" component="h3">
                準備が整ったら下記から登録をお願いします
                <br />
              </Typography>
              <Typography variant="headline" component="p">
                <div className={classes.root}>
                  <br />
                  <StripeConnectButton />
                  <br />
                </div>
              </Typography>
            </Paper>
            <br />
          </div>
        </div>
      </div>
    );
  }
}

SignInOrUp.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SignInOrUp);
