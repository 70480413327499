import React from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
const auth = getAuth();

import StripeConnectButton from "../components/StripeConnectButton";

import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import Button from "@material-ui/core/Button";
import { Alert } from "@material-ui/lab";

import { FiLogOut } from "react-icons/fi";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      margin: 10,
    },
    textLeft: {
      textAlign: "left",
      flex: 1,
    },
    textCenter: {
      textAlign: "center",
      flex: 1,
    },
    img100: {
      width: "100%",
    },
    paragraph: {
      marginTop: 10,
      marginBottom: 10,
    },
    clearBoth: {
      clear: "both",
    },
  });

interface AgencyServiceProps extends WithStyles<typeof styles> {}

interface AgencyServiceState {
  error: string;
  warning: string;
  info: string;
  success: string;
  urlcopy: boolean;
  user?: any | null;
}

class AgencyService extends React.Component<AgencyServiceProps, AgencyServiceState> {
  constructor(props: AgencyServiceProps) {
    super(props);
    this.state = {
      error: "",
      warning: "",
      info: "",
      success: "",
      urlcopy: false,
    };
  }

  componentDidMount = () => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.setState({
          user: user,
        });
        console.log("log in");
      } else {
        console.log("not log in");
      }
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={`${classes.root} ${classes.textLeft}`}>
        <Typography variant="h5" component="h2">
          代行サービスについて
        </Typography>
        <br />
        {this.state.error && (
          <Alert severity="error">{this.state.error}</Alert>
        )}
        {this.state.warning && (
          <Alert severity="warning">{this.state.warning}</Alert>
        )}
        {this.state.info && <Alert severity="info">{this.state.info}</Alert>}
        {this.state.success && (
          <Alert severity="success">{this.state.success}</Alert>
        )}

        <Paper className={`${classes.root} ${classes.textLeft}`} elevation={1}>
          <Typography variant="h5">24時間電話応対サービス:</Typography>
          <Typography
            className={`${classes.root} ${classes.textLeft}`}
            variant="body1"
            component="p"
          >
                    月額３０００円で電話による対応をオーナーに代わってQRパーキングがお受けしたします。
                    <br />
                    <br />
                    ・毎月の支払いはサブスクリプションとなりカード会社より自動送金されます。
                    <br />
                    ・現地対応が必要な場合はオーナー様にご連絡させていただきます。
                    <br />
                    ・必要な場合警察に立会を依頼する連絡を行います。
            </Typography>
        </Paper>
        <br />
        <Paper className={`${classes.root} ${classes.textLeft}`} elevation={1}>
          <Typography variant="h5">常習的な違法駐車車両に警告:</Typography>
          <Typography
            className={`${classes.root} ${classes.textLeft}`}
            variant="body1"
            component="p"
          >
                    常習的な違法駐車車両に警告を行うサービスをQRパーキングの名前で行います。
                    <br />
                    <br />
                    ・手数料５，０００円を送金いただきます。
                    <br />
                    ・オーナー様に違法駐車車両（ナンバーが見えるもの）と駐車場全体（違法車両の位置がわかるもの）の写真２枚、違法駐車日時の履歴情報、をご提供いただきます。
                    <br />
                    ・QRパーキングが当事者として陸運局の地域支部にナンバー照会を行います。
                    <br />
                    ・QRパーキング名で自動車所有者に警告と駐車場利用料の請求を内容証明郵便で送付します。
                    <br />
                    ・振込があった場合、９２％をオーナー様に送金いたします。
            </Typography>
        </Paper>
        <br />
        <Paper className={`${classes.root} ${classes.textLeft}`} elevation={1}>
          <Typography variant="h5">悪質な違法駐車車両を民事訴訟および刑事告訴:</Typography>
          <Typography
            className={`${classes.root} ${classes.textLeft}`}
            variant="body1"
            component="p"
                >                    
                    常習的な違法駐車車両に民事訴訟および刑事告訴を弊社依頼の弁護士が行います。
                    <br />                    
                    <br />
                    ・オーナー様に違法駐車日時の履歴情報を違法駐車車両（ナンバーが見えるもの）と駐車場全体（違法車両の位置がわかるもの）の写真付きでご提供いただきます。
                    <br />
                    ・弊社が依頼する弁護士に損害賠償請求訴訟および威力業務妨害の告発が可能か確認いたします。
                    <br />
                    ・弁護士の請求する着手金を送金いただきます。（通常２０万円ほど）
                    <br />
                    ・QRパーキングとオーナー様が共同当事者として弁護士に依頼します。
                    <br />
                    ・示談金などの振込があった場合、９２％をオーナー様に送金いたします。
            </Typography>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AgencyService);
