import React from "react";
import { Link } from "react-router-dom";
import {
  Theme,
  createStyles,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  useMediaQuery,
} from "@material-ui/core";

// スタイルを定義
const styles = (theme: Theme) =>
  createStyles({
    titleImage: {
      width: "100%",
      maxWidth: 700,
    },
    root: {
      width: "80%",
      marginLeft: "auto",
      marginRight: "auto",
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      margin: theme.spacing(1),
      overflow: "hidden",
      listStyle: "none",
    },
    listItem: {
      padding: "8px 16px",
      backgroundColor: "#f0f0f0",
      margin: "4px 0",
      borderRadius: "4px",
    },
    // スマホ用コンテナ（非レスポンシブ）
    mobileContainer: {
      display: "block",
    },
    // スマホ用テキスト（非レスポンシブ）
    mobileTextContent: {
      textAlign: "left",
      flex: 1,
      display: "inline-block",
      width: "90%",
      verticalAlign: "top",
    },
    // レスポンシブコンテナ（使用方法は簡単セクション用）
    contentContainer: {
      display: "flex",
      flexDirection: "column", // モバイルではデフォルトで縦並び
      [theme.breakpoints.up("md")]: {
        flexDirection: "row", // タブレット以上では横並び
        alignItems: "center",
      },
    },
    // レスポンシブテキスト（使用方法は簡単セクション用）
    textContent: {
      textAlign: "left",
      width: "100%", // モバイルでは全幅
      [theme.breakpoints.up("md")]: {
        width: "60%", // タブレット以上では幅を調整
      },
    },
    textLeft: {
      textAlign: "left",
      flex: 1,
    },
    // スマホ用の画像スタイル（非レスポンシブ）
    imgCenter: {
      width: "100%",
      margin: "15px 0",
    },
    // 基本的な画像スタイル（ボーダーなし）
    imgResponsive: {
      borderRadius: "8px", // 角を少し丸くする
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 軽い影をつける
      width: "100%", // モバイルでは全幅
      height: "auto",
      marginBottom: "15px",
      [theme.breakpoints.up("md")]: {
        width: "40%", // タブレット以上では幅を調整
        margin: "10px",
      },
    },
    // 使用方法は簡単セクションの画像用スタイル（テーマカラーで縁取りあり）
    imgWithBorder: {
      border: `4px solid ${theme.palette.primary.main}`, // テーマのプライマリカラーで縁取り
      borderRadius: "8px", // 角を少し丸くする
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // 軽い影をつける
      width: "100%", // モバイルでは全幅
      height: "auto",
      marginBottom: "15px",
      [theme.breakpoints.up("md")]: {
        width: "40%", // タブレット以上では幅を調整
        margin: "10px",
      },
    },
    clearBoth: {
      clear: "both",
    },
    customTypography: {
      fontSize: "1.25rem",
      fontWeight: "bold",
    },
    paragraph: {
      marginTop: 10,
      marginBottom: 10,
    },
    customButton: {
      backgroundColor: "#4CAF50",
      color: "white",
      "&:hover": {
        backgroundColor: "#45a049",
      },
    },
  });

// Propsの型を定義
interface Props extends WithStyles<typeof styles> {}

class Home extends React.Component<Props> {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <img
          src="/images/QRparkingLOGOIMG.jpg"
          alt="title"
          className={classes.titleImage}
        />
        <div className={classes.clearBoth}></div>
        <Paper id="hikaku" className={classes.root} elevation={1}>
          QRパーキングとは
          <br />
          <h3>QRコードを敷地に掲示するだけで簡単に始められる有料駐車場です</h3>
          <br />
          時間・終日・月極の有料駐車場の
          <br />
          契約・請求・精算・領収書発行・督促
          <br />
          が自動で行えます
          <br />
          <br />
          <h3>初期投資０円、92%の高収益還元率を実現してください</h3>
          <br />
          QRパーキングで空き地を手軽に収益化しましょう！！
          <br />
          <h4>今なら初期費用0円、月額固定料永久に0円！！</h4>
        </Paper>
        <br />
        <Paper className={classes.root} elevation={1}>
          <Typography
            variant="h2"
            className={`${classes.textContent} ${classes.customTypography}`}
          >
            使用方法は簡単
          </Typography>
          <br />
          <br />

          <Paper className={classes.root} elevation={1}>
            <div className={classes.contentContainer}>
              <img
                src="/images/siteimage/scanning_qr_code.jpg"
                className={classes.imgWithBorder}
              />
              <Typography component="div" className={classes.textContent}>
                駐車場に設置されたQRコードを読み取ります
              </Typography>
            </div>
          </Paper>

          <Paper className={classes.root} elevation={1}>
            <div className={classes.contentContainer}>
              <Typography component="div" className={classes.textContent}>
                アプリを使ってナンバー入りの車の写真を撮り、前払い料金を支払います
              </Typography>
              <img
                src="/images/siteimage/appshot1-1.jpg"
                className={classes.imgWithBorder}
              />
            </div>
          </Paper>

          <Paper className={classes.root} elevation={1}>
            <div className={classes.contentContainer}>
              <img
                src="/images/siteimage/IMG_3604.PNG"
                className={classes.imgWithBorder}
              />
              <Typography component="div" className={classes.textContent}>
                出庫時に超過料金を精算して出庫
                <br />
                予定時間前と超過料金加算時にメールでお知らせ
                <br />
                領収書もメールで自動発行
              </Typography>
            </div>
          </Paper>
        </Paper>
        <br />
        <div>
          <Paper className={classes.root} elevation={1}>
            <Typography
              variant="h3"
              className={`${classes.textContent} ${classes.customTypography}`}
            >
              重要なお知らせ
            </Typography>
            <br />
            <br />
            <Paper className={classes.root} elevation={1}>
              <div className={classes.mobileContainer}>
                <Typography variant="h5" className={classes.mobileTextContent}>
                  期間限定！！
                  <br />
                  初回登録料３万円が今だけ０円！！
                </Typography>
                <br />
                <Typography
                  component="div"
                  className={classes.mobileTextContent}
                >
                  今なら初回登録料３万円のところキャンペーン期間中のみ無料でご登録いただけます
                  <br />
                  キャンペーンの期間中にぜひ、登録だけでもお済ませください
                  <br />
                  登録後に固定費等は一切かかりません
                  <br />
                  このチャンスをぜひ！
                  <br />
                </Typography>
              </div>
            </Paper>
            <br />
            <br />
          </Paper>
          <br />
          <br />

          <Paper id="hikaku" className={classes.root} elevation={1}>
            <Typography
              variant="h3"
              className={`${classes.mobileTextContent} ${classes.customTypography}`}
            >
              QRパーキングと他の駐車場経営方法との比較
            </Typography>
            <br />
            <br />
            <div className={classes.mobileContainer}>
              <br />
              <Typography component="div" className={classes.mobileTextContent}>
                機械式コインパーキングオーナーの収益の60～80%は施設費用に消え、その分の区画が稼働していないのと同じです。
                <br />
                機械式コインパーキングの横で違法駐車のない月極駐車場が問題なく経営できているのはよく見る光景です。
                <br />
                <br />
              </Typography>
              <Typography variant="h5" className={classes.mobileTextContent}>
                機械式コインパーキング借地型
              </Typography>
              <Typography component="div" className={classes.mobileTextContent}>
                オーナーが土地をコインパーキング運営者に賃貸します
                <br />
                利点: 運営管理不要
                <br />
                欠点: 駅近くなどの高収益土地でないと契約不可、低収益還元率
              </Typography>
              <br />
              <br />
              <Typography variant="h5" className={classes.mobileTextContent}>
                機械式コインパーキング委託型
              </Typography>
              <Typography component="div" className={classes.mobileTextContent}>
                オーナーが土地とコインパーキング設備を用意し、運営者に運営委託します
                <br />
                利点: 収益還元率が高い（約30％ 設置コストの償還後）
                <br />
                欠点:
                駅近くなどの高収益土地でないと契約不可、高額な設置コスト（1台40万～80万円）
                <br />
                ローンなどのリスクはオーナーが負担する契約が多い
              </Typography>
              <br />
              <br />
              <Typography variant="h5" className={classes.mobileTextContent}>
                QRパーキング
              </Typography>
              <Typography component="div" className={classes.mobileTextContent}>
                決済用QRコードを土地に掲示してオーナーが自ら駐車場を運営します
                <br />
                利点:
                初期費用0円、92％の高収益還元率、自由な運営、どんな場所でも運営可能
                <br />
                欠点: 運営管理はオーナーが担当
              </Typography>
              <img
                src="/images/siteimage/Graph1.jpg"
                className={classes.imgCenter}
              />
            </div>
          </Paper>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              className={classes.customButton}
              onClick={() => window.open("/signinorup", "_blank")}
            >
              無料登録の説明にすすむ
            </Button>
          </div>
          {/* 
          <Paper id="tokucho" className={classes.root} elevation={1}>
            <Typography
              variant="h3"
              className={`${classes.mobileTextContent} ${classes.customTypography}`}
            >
              QRパーキングの利便性と特徴
            </Typography>
            <br />
            <br />
            <div className={classes.contentContainer}>
              <div className={classes.textContent}>
                <Typography variant="h5" className={classes.mobileTextContent}>
                  QRコード決済の手軽さ
                </Typography>
                <Typography
                  component="div"
                  className={classes.mobileTextContent}
                >
                  ・QRコードをスマホで読み取るだけで決済完了！
                  <br />
                  ・初期費用と設備費が一切不要！
                </Typography>
                <br />
                <br />
                <Typography variant="h5" className={classes.mobileTextContent}>
                  QRパーキングの特徴
                </Typography>
                <Typography
                  component="div"
                  className={classes.mobileTextContent}
                >
                  ・簡単登録：すぐに利用開始
                  <br />
                  ・低コスト：無料でスタート、維持費も無料！
                  <br />
                  ・高収益：92％の収益還元率
                </Typography>
              </div>
              <img
                src="/images/siteimage/44ca0d1c-8f2f-452d-b703-d81a5924f3de.webp"
                className={classes.imgResponsive}
              />
            </div>
          </Paper>
*/}
          <Paper id="start" className={classes.root} elevation={1}>
            <Typography
              variant="h5"
              className={`${classes.textContent} ${classes.customTypography}`}
            >
              簡単な始め方　 QRパーキング導入の手順
            </Typography>
            <br />
            <br />
            <div className={classes.contentContainer}>
              <div className={classes.textContent}>
                <Typography variant="h5" className={classes.textContent}>
                  1.利用開始登録（登録料０円）
                </Typography>
                <Typography component="div" className={classes.textContent}>
                  たった数分で完了　今すぐ登録→
                  <Link to="/signinorup">詳しく見る</Link>
                </Typography>
                <br />
                <br />
                <Typography variant="h5" className={classes.textContent}>
                  2.料金設定（利用料金０円）
                </Typography>
                <Typography component="div" className={classes.textContent}>
                  専用ページで簡単設定（時間、終日、月極）→
                  <Link to="/PricingSettings">詳しく見る</Link>
                </Typography>
                <br />
                <br />
                <Typography variant="h5" className={classes.textContent}>
                  3.看板準備
                </Typography>
                <Typography component="div" className={classes.textContent}>
                  看板用QRコードデータは無料でダウンロード可能
                  <br />
                  プリントして看板に貼り付ければ準備完了！
                  <br />
                  QRコード付きプリントステッカー・看板をオンラインで注文可能→
                  <Link to="/ProductCatalog">購入</Link>
                </Typography>
                <br />
                <br />
                <Typography variant="h5" className={classes.textContent}>
                  4.看板設置
                </Typography>
                <Typography component="div" className={classes.textContent}>
                  自立型で簡単に設置できる看板もございます。→
                  <Link to="/ProductCatalog">購入</Link>
                </Typography>
                <br />
                <br />
                <br />
                <Typography variant="h5" className={classes.textContent}>
                  設置後は入金を待つだけ！
                </Typography>
              </div>
              <img
                src="/images/siteimage/QRparkig看板.jpg"
                className={classes.imgResponsive}
              />
            </div>
            <div className={classes.clearBoth}></div>
          </Paper>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              className={classes.customButton}
              onClick={() => window.open("/signinorup", "_blank")}
            >
              無料登録の説明にすすむ
            </Button>
          </div>

          <Paper
            id="AdvantagesDisadvantages"
            className={classes.root}
            elevation={1}
          >
            <Typography
              variant="h3"
              className={`${classes.mobileTextContent} ${classes.customTypography}`}
            >
              QRパーキングのメリット・デメリットまとめ
            </Typography>
            <br />
            <br />
            <div className={classes.mobileContainer}>
              <Typography
                variant="h5"
                className={`${classes.mobileTextContent} ${classes.customTypography}`}
              >
                メリット
              </Typography>
              <Typography component="div" className={classes.mobileTextContent}>
                <ul className={classes.root}>
                  <li className={classes.listItem}>
                    コストゼロ：利用登録料0円！月々利用料0円！
                  </li>
                  <li className={classes.listItem}>
                    スピード：最短3日で営業開始 一週間で入金
                  </li>
                  <li className={classes.listItem}>
                    簡単：頭金・審査・ローン不要・「契約、請求、精算、領収書発行、督促」が全自動
                  </li>
                  <li className={classes.listItem}>
                    柔軟：解約金なし！短期間（釣り、海水浴、花火、祭り期間のみ）でもOK！休止再開もスマホから！
                  </li>
                </ul>
              </Typography>
              <Typography
                variant="h5"
                className={`${classes.mobileTextContent} ${classes.customTypography}`}
              >
                デメリット
              </Typography>
              <Typography component="div" className={classes.mobileTextContent}>
                <ul className={classes.root}>
                  <li className={classes.listItem}>
                    スマートフォンによるクレジットカード決済・apple pay・google
                    payのみ対応
                  </li>
                  <li className={classes.listItem}>
                    運営管理：除草や掃除はオーナーが担当（代行サービス有）
                  </li>
                  <li className={classes.listItem}>
                    トラブル対応：未精算や違法駐車の対応はオーナーが担当（代行サービス有）
                  </li>
                  代行サービスについて→
                  <Link to="/AgencyService">詳しく見る</Link>
                </ul>
              </Typography>
            </div>
          </Paper>

          <Paper id="faq" className={classes.root} elevation={1}>
            <Typography
              variant="h3"
              className={`${classes.mobileTextContent} ${classes.customTypography}`}
            >
              よくある質問
            </Typography>
            <br />
            <br />
            <div className={classes.contentContainer}>
              <div className={classes.textContent}>
                <Typography component="h5">
                  登録に保証金や預り金などは必要ですか？
                </Typography>
                <Typography component="div">
                  いいえ。無料登録を完了したらすぐに運営開始できます
                </Typography>
                <br />
                <Typography component="h5">登録に審査はありますか？</Typography>
                <Typography component="div">
                  Stripe社のアカウント登録には身分証明書の真偽や個人情報の真偽に審査がありますが、基本的に嘘がなければ通ります
                </Typography>
                <br />
                <Typography component="h5">
                  看板等は購入しなければなりませんか？
                </Typography>
                <Typography component="div">
                  いいえ。もしすでに看板があるなど看板不要な場合はQRコードをダウンロードしてプリンター（一部のコンビニプリントも可）で防水シートに印刷するだけで運営開始できます
                </Typography>
                <br />
                <Typography component="h5">
                  QRパーキングで区画月極駐車場を運営できますか？
                </Typography>
                <Typography component="div">
                  QRパーキングは駐車場に名前をつけて１台から複数管理できます　区画ごとにQRコードを掲示すれば区画管理可能です
                  <br />
                  また区画せずに駐車台数だけで月極駐車場を管理することもできます。この場合ユーザーは空いているところに自由に停め、台数制限内で時間貸し日貸しも運営できます
                </Typography>
                <br />
                <Typography component="h5">
                  人の土地や月極駐車場の又貸しでQRパーキングを運営できますか？
                </Typography>
                <Typography component="div">
                  土地所有者（管理者）の許可を得ていれば問題ありません
                </Typography>
                <br />
                <Typography component="h5">
                  paypayや現金決済はできますか？
                </Typography>
                <Typography component="div">
                  いいえ。支払いに対応しているのはすべてのスマホに必ずついているスマホ決済（google
                  pay/apple pay）とクレジットカードのweb決済のみです
                </Typography>
                <br />
                <Typography component="h5">
                  確定申告やインボイスなどの税務手続きは？
                </Typography>
                <Typography component="div">
                  税務申告に必要な書類はすべて自動的に作成されダウンロードできます。またTコード取得済みの場合は利用者にTコード付きの領収書も自動で発行されます。
                </Typography>
                <br />
                <Typography component="h5">
                  開業までの具体的な検討事項は？
                </Typography>
                <Typography component="div">
                  チェックリストをご覧ください→
                  <Link to="/CheckList">詳しく見る</Link>
                </Typography>
                <br />

                <Typography component="h5">
                  不法駐車や未払いの対策は？
                </Typography>
                <Typography component="div">
                  料金トラブルについて→
                  <Link to="/Troubleshooting">詳しく見る</Link>
                </Typography>
                <br />

                <Typography component="h5">自主管理はどんな作業？</Typography>
                <Typography component="div">
                  自主管理について→<Link to="/ToDoList">詳しく見る</Link>
                </Typography>
              </div>
              <img
                src="/images/siteimage/7c3425b0-f346-45ad-979e-23017c2084a7.webp"
                className={classes.imgResponsive}
              />
            </div>
            <div className={classes.clearBoth}></div>
          </Paper>

          <Paper id="Userreviews" className={classes.root} elevation={1}>
            <Typography
              variant="h3"
              className={`${classes.textContent} ${classes.customTypography}`}
            >
              利用者様の声
            </Typography>
            <br />
            <br />
            <div className={classes.contentContainer}>
              <div className={classes.textContent}>
                <Typography component="h5">
                  以下のようなありがたいコメントを多数頂いております
                </Typography>
                <br />
                <br />
                <Typography component="div">
                  「機械式コインパーキングの更新時期に来たので多額の設備投資とローンを検討していましたが、QRパーキングはリスクも少なくいつでも撤退可能なので試してみました。今のところローンも組まず収益率が上がった分丸々増益で満足しています。」
                  - 東京都：内山さん
                  <br />
                  <br />
                  「月極駐車場の自主運営をしていましたが契約や集金の手間が省けてとても助かります！」
                  - 香川県：富川さん
                  <br />
                  <br />
                  「QRパーキングのおかげで、空き地が月々の収益源になりました！」
                  - 千葉県：田中さん
                  <br />
                  <br />
                  「コインパーキングやレンタル倉庫は断られてしまいましたがQRパーキングは始められました！」
                  - 山口県：福光さん
                  <br />
                  <br />
                  「田舎でまったく期待していませんでしたが初期費用もかからず低価格に設定しても赤字にならないので思ったより収益がでてました！」
                  - 山口県：幸田さん
                  <br />
                  <br />
                  「１台しか停められない狭小地でも、初期費用がかからないのでリスクなく始められました。」
                  - 新潟県：佐藤さん
                  <br />
                  <br />
                  「釣りのシーズンの違法駐車がなくなり、その上予想以上の収益が入ってきました！」
                  - 三重県：徳山さん
                  <br />
                  <br />
                  「一年に一度の花火のときだけ人を雇っていましたが無人で高収益を得られました！」
                  - 熊本県：杉本さん
                </Typography>
              </div>
              <img
                src="/images/siteimage/44ca0d1c-8f2f-452d-b703-d81a5924f3de.webp"
                className={classes.imgResponsive}
              />
            </div>
            <div className={classes.clearBoth}></div>
          </Paper>

          <Paper id="getstartednow" className={classes.root} elevation={1}>
            <Typography variant="h4" className={`${classes.mobileTextContent}`}>
              いますぐ
              <br />
              始めましょう！
            </Typography>
            <br />
            <br />
            <br />

            <div className={classes.mobileTextContent}>
              <Typography component="h5">
                詳しいパンフレットを請求する
              </Typography>
              <Typography component="div">
                →<Link to="/MailForm/brochurereques">資料請求</Link>
              </Typography>
              <br />
              <br />
              <Typography component="h5">メールで担当者に相談する</Typography>
              <Typography component="div">
                →<Link to="/MailForm/mailform">メール相談</Link>
              </Typography>
              <br />
              <br />
              <Typography component="h5">利用登録を開始してみる</Typography>
              <Typography component="div">
                →{" "}
                <Button
                  variant="contained"
                  className={classes.customButton}
                  onClick={() => window.open("/signinorup", "_blank")}
                >
                  無料登録の説明にすすむ
                </Button>
              </Typography>
            </div>
            <br />
          </Paper>
          <br />
          <Paper className={classes.root} elevation={1}>
            <div className={classes.mobileContainer}>
              <Typography variant="h5" className={classes.mobileTextContent}>
                月額料金のサブスクリプション登録がいよいよ始動！
              </Typography>
              <br />
              <Typography component="div" className={classes.mobileTextContent}>
                オーナーの皆様からご要望が多かった月額駐車料金に
                <br />
                サブスクリプション（定額自動支払）方式が追加され、前払い方式との選択制になりました
              </Typography>
            </div>
          </Paper>

          <div className={classes.clearBoth}></div>
        </div>
        <Typography variant="subtitle2">
          <Link to="/specifiedcommercialtransactionact">
            特定商取引法に基づく表記
          </Link>
        </Typography>
      </div>
    );
  }
}

// withStyles HOCを使用してスタイルを適用
export default withStyles(styles)(Home);
