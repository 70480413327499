import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch } from "react-redux";

// Firebase v9 imports
import { getFunctions, httpsCallable } from "firebase/functions";
import { getApp } from "firebase/app";

// スタイルの定義
const useStyles = makeStyles({
  button: {
    backgroundColor: "#69b076",
    color: "#FFF",
    fontSize: 20,
    height: 26,
    width: "auto",
    padding: "0px 30px",
  },
});

// プロパティの型定義
interface PurchaseButtonProps {
  status: string;
  productid: string;
  mode: string;
  feeType: string;
  price: number;
  quantity: number;
  name: string;
  image: string;
  description: string;
  ambassadorid: string;
  ambassadordisplayName: string;
  ambassadorEmail: string;
  arrivalExpectedDate: string;
  label: string;
  hourlyRate: number;
  dailyRate: number;
  monthlyRate: number;
  additionalFee: number;
  applicationFeeamountRate: number;
  sessionId?: string;
  subscriptionPlanId?: string; // オプショナルプロパティ
  planId?: string; // オプショナルプロパティ
}

const PurchaseButton: React.FC<PurchaseButtonProps> = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handlePayment = async (
    status: string,
    productid: string,
    mode: string,
    feeType: string,
    price: number,
    quantity: number,
    name: string,
    image: string,
    description: string,
    ambassadorid: string,
    ambassadordisplayName: string,
    ambassadorEmail: string,
    arrivalExpectedDate: string,
    label: string,
    hourlyRate: number,
    dailyRate: number,
    monthlyRate: number,
    additionalFee: number,
    applicationFeeamountRate: number,
    sessionId?: string,
    planId?: string,
    subscriptionPlanId?: string,
  ) => {
    try {
      // Get functions instance with region
      const functions = getFunctions(getApp(), 'asia-northeast2');
      
      // Create callable function
      const createSession = httpsCallable(functions, 'createPaymentSession');
      
      // Load Stripe
      const pk_id = process.env.REACT_APP_STRIPE_CLIENT_ID;
      const stripePromise = pk_id ? loadStripe(pk_id) : null;
      const stripe = await stripePromise;

      if (stripe) {
        // Call Firebase function
        const result = await createSession({
          status,
          productid,
          mode,
          feeType,
          price,
          quantity,
          name,
          image,
          description,
          ambassadorid,
          ambassadordisplayName,
          ambassadorEmail,
          arrivalExpectedDate,
          label,
          hourlyRate,
          dailyRate,
          monthlyRate,
          additionalFee,
          applicationFeeamountRate,
          sessionId: sessionId || null,
          planId: planId || null,
          subscriptionPlanId: subscriptionPlanId || null,
        });
        
        // Extract session ID from result
        const data = result.data as { id: string };
        console.log("sessionId:" + data.id);
        
        // Redirect to Stripe Checkout
        const redirectResult = await stripe.redirectToCheckout({
          sessionId: data.id,
        });
        
        if (redirectResult.error) {
          console.log(redirectResult.error);
        }
      } else {
        console.log('Stripe.js has not loaded yet.');
      }
    } catch (error) {
      console.error('Payment session creation failed:', error);
    }
  };

  return (
    <Button
      className={classes.button}
      variant="contained"
      onClick={() =>
        handlePayment(
          props.status,
          props.productid,
          props.mode ? props.mode : 'payment',
          props.feeType,
          props.price,
          props.quantity,
          props.name,
          props.image,
          props.description,
          props.ambassadorid,
          props.ambassadordisplayName,
          props.ambassadorEmail,
          props.arrivalExpectedDate,
          props.label,
          props.hourlyRate,
          props.dailyRate,
          props.monthlyRate,
          props.additionalFee,
          props.applicationFeeamountRate ? props.applicationFeeamountRate : 0.1,
          props.sessionId,
          props.planId,
          props.subscriptionPlanId,
        )
      }
    >
      {props.label}
    </Button>
  );
};

export default PurchaseButton;