import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Firebase v9 モジュラー形式に対応（旧形式の firebase/app, firebase/functions の代わり）
import { getApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { TextField } from "@material-ui/core";
import FormControlLabel from "@mui/material/FormControlLabel";

import Button from "@material-ui/core/Button";

// スタイル
const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: 10,
  },
  textLeft: {
    textAlign: "left",
  },
  paragraph: {
    marginTop: 10,
    marginBottom: 10,
  },
  marginreftten: {
    marginLeft: 10,
  },
  bottonstyle: {
    margin: 10,
    border: "none",
    filter: "grayscale(0)",
    opacity: 1,
  },
  bottonstyleselected: {
    margin: 6,
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: "red",
    filter: "grayscale(0)",
    opacity: 1,
  },
  bottonstyledisable: {
    margin: 10,
    border: "none",
    filter: "grayscale(100)",
    opacity: 0.5,
  },
  customTypography: {
    fontSize: "1.25rem",
    fontWeight: "bold",
  },
  displaynone: {
    display: "none",
  },
});

function MeilForm({ classes, match }) {
  const { Style } = match.params;
  const [formData, setFormData] = useState({
    email: "",
    message: "",
    name: "",
    zipcode: "",
    address1: "",
    type: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {

    let isValid = false;
    // Styleに基づいてtypeを設定
    let typeValue = "";
    if (Style === "brochurereques") {
      typeValue = "資料請求";
      isValid = formData.email && formData.name  && formData.zipcode && formData.address1
    } else if (Style === "mailform") {
      typeValue = "メールフォーム";
      isValid = formData.email && formData.message
    } else if (Style === "24tel") {
      typeValue = "２４時間電話応対オプションのお申し込み";
      isValid = formData.email && formData.message
    }
    setIsFormValid(isValid);
    
    setFormData(prevData => ({
      ...prevData,
      type: typeValue
    }));
  }, [Style, formData.email, formData.message]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleFormSubmit(formData);
  };

  const handleFormSubmit = async (formData) => {

    // GTMのデータレイヤーにイベントをプッシュ
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'mailformSendClick',
      status: Style,
    });


    // Firebase Functions 呼び出し (Firebase v9 モジュラー形式に書き換え)
    const app = getApp();
    const functions = getFunctions(app, "asia-northeast2");
    const executeMailSending = httpsCallable(functions, "sendMail");

    const response = await executeMailSending({
      destination: "info01@qrparking.jp",
      subject: "Send From MailForm",
      text: JSON.stringify(formData, null, 2),
      html: "",
    });
    console.log(response.data);
    setSubmitted(true);
  };

  if (submitted) {
    return (
      <Typography
        variant="h4"
        className={`${classes.textContent} ${classes.customTypography}`}
      >
        送信しました
      </Typography>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      {Style === "brochurereques" && (
        <Typography
          variant="h4"
          className={`${classes.textContent} ${classes.customTypography}`}
        >
          資料の請求
        </Typography>
      )}
      {Style === "mailform" && (
        <Typography
          variant="h4"
          className={`${classes.textContent} ${classes.customTypography}`}
        >
          メールによるご相談
        </Typography>
      )}
      {Style === "24tel" && (
        <>
          <Typography
            variant="h4"
            className={`${classes.textContent} ${classes.customTypography}`}
          >
            ２４時間電話応対オプションのお申し込み
          </Typography>
          <Typography variant="body1" className={`${classes.textContent}`}>
            このフォームより月額３０００円の２４時間の電話応対オプションをご希望いただくと
            <br />
            サブスクリプションの申し込みリンクが送付されます
            <br />
            サブスクリプションの受付完了を確認後、２４時間応対用の電話番号をお知らせします
            <br />
            それを駐車場の料金設定画面で緊急連絡先にご指定ください
            <br />
            サブスクリプションの解約は月単位でいつでも可能です
          </Typography>
        </>
      )}

      <TextField
        type="hidden"
        name="type"
        value={formData.type}
        style={{ display: 'none' }}
      />

      <Paper className={classes.root} elevation={1}>
        <TextField
          id="name"
          name="name"
          label="お名前"
          style={{ margin: 8 }}
          helperText="お名前を入力してください"
          fullWidth
          margin="normal"
          variant="outlined"
          autoComplete="off"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <br />
        <TextField
          id="email"
          name="email"
          label="メールアドレス"
          style={{ margin: 8 }}
          helperText="メールアドレスを入力してください"
          fullWidth
          margin="normal"
          variant="outlined"
          autoComplete="off"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </Paper>

      {Style === "brochurereques" && (
        <Paper className={classes.root} elevation={1}>
          <TextField
            id="zipcode"
            name="zipcode"
            label="郵便番号"
            style={{ margin: 8 }}
            helperText="郵便番号を入力してください"
            fullWidth
            margin="normal"
            variant="outlined"
            autoComplete="off"
            value={formData.zipcode}
            onChange={handleChange}
            required
          />
          <br />
          <TextField
            id="address1"
            name="address1"
            label="住所"
            style={{ margin: 8 }}
            helperText="ご住所を入力してください"
            fullWidth
            margin="normal"
            variant="outlined"
            autoComplete="off"
            value={formData.address1}
            onChange={handleChange}
            required
            multiline
          />
        </Paper>
      )}

      <br />
      <Paper className={classes.root} elevation={1}>
        <TextField
          id="message"
          name="message"
          label="お問い合わせ"
          style={{ margin: 8 }}
          helperText="お問い合わせ内容を入力してください"
          fullWidth
          margin="normal"
          variant="outlined"
          autoComplete="off"
          value={formData.message}
          onChange={handleChange}
          multiline
        />
      </Paper>
      <Button
        type="submit"
        className={
          isFormValid ? classes.bottonstyleselected : classes.bottonstyledisable
        }
        disabled={!isFormValid}
      >
        送信する
      </Button>
    </form>
  );
}

// Material-ui関連
MeilForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

// Material-uiのテーマ設定
export default withStyles(styles, { withTheme: true })(MeilForm);
