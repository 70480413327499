import React from "react";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
const auth = getAuth();

import StripeConnectButton from "../components/StripeConnectButton";

import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import Button from "@material-ui/core/Button";
import { Alert } from "@material-ui/lab";

import { FiLogOut } from "react-icons/fi";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      margin: 10,
    },
    textLeft: {
      textAlign: "left",
      flex: 1,
    },
    textCenter: {
      textAlign: "center",
      flex: 1,
    },
    img100: {
      width: "100%",
    },
    paragraph: {
      marginTop: 10,
      marginBottom: 10,
    },
    clearBoth: {
      clear: "both",
    },
  });

interface DataInfoProps extends WithStyles<typeof styles> {}

interface DataInfoState {
  error: string;
  warning: string;
  info: string;
  success: string;
  urlcopy: boolean;
  user?: User | null;
}

class DataInfo extends React.Component<DataInfoProps, DataInfoState> {
  constructor(props: DataInfoProps) {
    super(props);
    this.state = {
      error: "",
      warning: "",
      info: "",
      success: "",
      urlcopy: false,
    };
  }

  componentDidMount = () => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.setState({
          user: user,
        });
        console.log("log in");
      } else {
        /*
        this.setState({
            warning: "現在ログインしていません",
          });
        */
          console.log("not log in");
      }
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={`${classes.root} ${classes.textLeft}`}>
        <Typography variant="h5" component="h2">
          QRパーキングとStripeがそれぞれ管理する情報について
        </Typography>
        <br />
        {this.state.error && (
          <Alert severity="error">{this.state.error}</Alert>
        )}
        {this.state.warning && (
          <Alert severity="warning">{this.state.warning}</Alert>
        )}
        {this.state.info && <Alert severity="info">{this.state.info}</Alert>}
        {this.state.success && (
          <Alert severity="success">{this.state.success}</Alert>
        )}

        <Paper className={`${classes.root} ${classes.textLeft}`} elevation={1}>
          <Typography variant="h5">QRパーキングが保持する情報:</Typography>

          <List component="ul">
            <ListItem>
              <ListItemText primary="駐車場利用者のクルマの写真" />
            </ListItem>
            <ListItem>
              <ListItemText primary="駐車場の入出庫時間" />
            </ListItem>
            <ListItem>
              <ListItemText primary="オーナーが設定した駐車場料金" />
            </ListItem>
            <ListItem>
              <ListItemText primary="駐車場利用者とオーナーの連絡先情報" />
            </ListItem>
          </List>
          <Typography
            className={`${classes.root} ${classes.textLeft}`}
            variant="body1"
            component="p"
          >
            QRパーキングは、駐車場利用者のクルマの写真や入出庫時間、オーナーが設定した駐車場料金などの有料駐車場運営に必要な情報を管理します。
            <br />
            これらの情報を元に、適切な料金の計算と請求が行われます。
          </Typography>
        </Paper>
        <br />
        <Paper className={`${classes.root} ${classes.textLeft}`} elevation={1}>
          <Typography variant="h5">Stripeが保持する情報:</Typography>
          <List component="ul">
            <ListItem>
              <ListItemText primary="オーナーの身分証明書の写し" />
            </ListItem>
            <ListItem>
              <ListItemText primary="オーナーの銀行口座情報" />
            </ListItem>
            <ListItem>
              <ListItemText primary="駐車場利用者のクレジットカード情報" />
            </ListItem>
            <ListItem>
              <ListItemText primary="決済履歴（日時、金額、決済状況、など）" />
            </ListItem>
          </List>
          <Typography
            className={`${classes.root} ${classes.textLeft}`}
            variant="body1"
            component="p"
          >
            Stripeは、オーナーの銀行口座情報とユーザーのクレジットカード情報を安全に管理し、決済処理を行います。
            <br />
            これにより、オーナーと駐車場利用者の金銭のやり取りがスムーズかつセキュアに行われます。
          </Typography>
        </Paper>
        <br />

        <Paper className={classes.textCenter} elevation={1}>
          <img src="/images/siteimage/flowchart.jpg" className={classes.img100} />
        </Paper>
        <br />

        <div className={classes.clearBoth}></div>
        <Paper className={classes.root} elevation={1}>
          <Typography variant="h5" component="h4">
            QRパーキングとStripeは、それぞれの役割に応じて必要にして最小限な情報を保持・管理することで、駐車場精算サービスを円滑にセキュアに運営しています。
            <br />
            両者ともに個人情報の取り扱いには細心の注意を払い、関連法規やガイドラインに従って適切に管理しております。
          </Typography>
        </Paper>
        <br />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DataInfo);
